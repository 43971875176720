import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import FullCalenderComponent from "../../common/FullCalenderComponent";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { getRunnerProgramList } from "../../../apis/eventService";
import ApplicationUtil from "../../../utils/ApplicationUtil";
import { Popover, Typography } from "@material-ui/core";
import SubmitCancelBtn from "../../common/SubmitCancelBtn";
import AppToolBar from "../../common/AppToolBar";
import moment from "moment";
import httpService from "../../../apis/httpService";
import eventImg from "../../../images/Run.png";
import ApplicationConstants from "../../../constants/ApplicationConstants";
import RunnerSideBreadcrumbs from "../../common/RunnerSideBreadcrumbs";
import restImage from "../../../images/comfort-zone.png";
import { useDispatch, useSelector } from "react-redux";

import { StairStepperIcon } from "../../common/SvgIcon";

import {
  updateCurrentRunningProgramChallenge,
  updateSelectedQueBlock,
} from "../../../redux/user";

function CalenderHomeView(props) {
  const { classes, history } = props;
  const [calenderEvents, setCalenderEvents] = useState();
  const [selectedEventDetails, setSelectedEventDetails] = useState({});

  const [programDetails, setProgramDetails] = useState({});
  const currentRunningProgramChallenge = useSelector(
    (state) => state.user.currentRunningProgramChallenge
  );
  const userRefusedToAttempt = useSelector(
    (state) => state.user.queNotAttempted
  );
  const calendarRef = useRef();
  const API_URL_FILE = httpService.API_URL();
  const dispatch = useDispatch();

  const mobileNavigateSubmitResponse = (dateTo, id) => {
    let programDetail = ApplicationUtil.getRunnersProgramDetails();
    // let challengeLastDate =
    //   info.event.extendedProps?.challengeEventDetails?.dateTo;

    let parsedChallengeLastDate = moment(dateTo, "DD-MM-YYYY");

    let isExpireChallenge = parsedChallengeLastDate.isBefore(moment());

    if (isExpireChallenge) {
      let viewDate = moment(parsedChallengeLastDate).format("YYYY-MM-DD");
      navigateToRecordResponseWithDistKey(id, programDetail?.distKey, viewDate);
    } else {
      navigateToRecordResponseWithDistKey(id, programDetail?.distKey);
    }
  };

  const navigateToProgramDetails = ({ challengeId, eventId, sessionId }) => {
    let url = "/program?distKey=" + programDetails?.distKey;
    if (challengeId) {
      url = url + "&challengeId=" + challengeId;
    }

    if (eventId) {
      url = url + "&eventId=" + eventId;
    }
    if (sessionId) {
      url = url + "&sessionId=" + sessionId;
    }
    history.push(url);
  };
  const handleEventClick = (info) => {
    if (window.innerWidth < 600) {
      setSelectedEventDetails(info);
    } else if (info?.view?.type !== "weekProgram") {
      setSelectedEventDetails(info);
    }
    const childElement = info?.el?.querySelector("#addResponse");
    if (childElement !== null) {
      dispatch(updateSelectedQueBlock(info.event.extendedProps));
      history.push("/record-response?queBlock=true");
    }

    if (info?.jsEvent?.target?.id === "submitResponse") {
      // dispatch(updateSelectedQueBlock(info.event.extendedProps));
      // history.push("/record-response?queBlock=true");
      let programDetail = ApplicationUtil.getRunnersProgramDetails();
      let challengeLastDate =
        info.event.extendedProps?.challengeEventDetails?.dateTo;

      let parsedChallengeLastDate = moment(challengeLastDate, "DD-MM-YYYY");

      let isExpireChallenge = parsedChallengeLastDate.isBefore(moment());

      if (isExpireChallenge) {
        let viewDate = moment(parsedChallengeLastDate).format("YYYY-MM-DD");
        navigateToRecordResponseWithDistKey(
          info.event.extendedProps?.challengeEventDetails?.challengeDetails?.id,
          programDetail?.distKey,
          viewDate
        );
      } else {
        navigateToRecordResponseWithDistKey(
          info.event.extendedProps?.challengeEventDetails?.challengeDetails?.id,
          programDetail?.distKey
        );
      }
    }

    if (info?.jsEvent?.target?.id === "viewDetails") {
      setSelectedEventDetails(info);
      navigateToProgramDetails({
        challengeId:
          info.event.extendedProps?.challengeEventDetails?.challengeDetails?.id,
      });
    }
    if (info?.jsEvent?.target?.id === "viewDetails_event") {
      setSelectedEventDetails(info);
      navigateToProgramDetails({
        eventId: info.event.extendedProps?.programDetails?.details?.id,
      });
    }
    if (info?.jsEvent?.target?.id === "viewDetails_session") {
      setSelectedEventDetails(info);
      navigateToProgramDetails({
        sessionId: info.event.extendedProps?.sessionDetails?.id,
      });
    }
  };

  const handleEvents = (info) => {};

  // Function to navigate to the record response page
  const navigateToRecordResponseWithDistKey = (
    id,
    distKey,
    viewDate = moment().subtract(1, "day").format("YYYY-MM-DD")
  ) => {
    history.push(
      `/record-response?distkey=${distKey}&id=${id}&viewDate=${viewDate}`
    );
  };

  const handleResponseScreenNavigation = ({
    currentRunningChallenge,
    challengeEndedYesterday,
    programData,
  }) => {
    // Determine which questionnaires to use based on whether the challenge ended yesterday
    let questionnaires =
      challengeEndedYesterday?.questionnaires ||
      currentRunningChallenge?.questionnaires;

    // Get yesterday's questionnaire
    let yesterdaysQue = ApplicationUtil.getYesterdaysQue({
      questionnaires,
    })?.[0];

    // Check if yesterday's challenge was not performed
    if (yesterdaysQue?.isChallengePerformed === false) {
      // If the user hasn't refused to attempt
      if (
        !userRefusedToAttempt &&
        !Object.keys(userRefusedToAttempt || {}).length
      ) {
        if (challengeEndedYesterday) {
          // Navigate to record response with challenge details
          navigateToRecordResponseWithDistKey(
            challengeEndedYesterday?.id,
            programData?.distKey
          );
        } else {
          // Navigate to record response without specific challenge details
          history.push("/record-response");
        }
      }

      // If the user has refused to attempt
      if (userRefusedToAttempt) {
        // Check if the refusal was for yesterday's challenge
        const userRefusedToAttemptYesterdaysQue =
          moment().subtract(1, "day").format("DD-MM-YYYY") ===
          userRefusedToAttempt?.date;

        if (userRefusedToAttemptYesterdaysQue) {
          const challengeId = userRefusedToAttempt?.challenge?.id;
          const isProgramChallengeYesterday =
            yesterdaysQue?.programChallengeQuestionDto?.programChallengeId;

          // Check if the refusal was for a different challenge than the one that ended yesterday
          if (
            challengeEndedYesterday &&
            challengeEndedYesterday?.id !== challengeId
          ) {
            // If yesterday's questionnaire belongs to the challenge that ended yesterday, navigate to record response
            if (isProgramChallengeYesterday === challengeEndedYesterday?.id) {
              navigateToRecordResponseWithDistKey(
                challengeEndedYesterday.id,
                programData?.distKey
              );
            }
          }

          // Check if the refusal was for a different challenge than the current running challenge
          if (currentRunningChallenge?.id !== challengeId) {
            // If yesterday's questionnaire belongs to the current running challenge, navigate to record response
            if (isProgramChallengeYesterday === currentRunningChallenge?.id) {
              history.push("/record-response");
            }
          }
        }
      }
    }
  };
  const handleCalenderViewDate = (info) => {
    localStorage.removeItem("navigateToCalender");
    if (!ApplicationUtil.isuserLoggedIn("runner")) {
      return;
    }
    let programDetails = ApplicationUtil.getRunnersProgramDetails();

    let params = {
      programId: programDetails?.id,
    };

    getRunnerProgramList(params).then(({ data }) => {
      setProgramDetails(data);
      // Get Current Running challenge
      const currentRunningChallenge =
        ApplicationUtil.getCurrentRunningProgramChallenge(
          data?.programChallenges || []
        );

      const challengeEndedYesterday =
        ApplicationUtil.getChallengeEndedYesterday(
          data?.programChallenges || []
        );

      dispatch(updateCurrentRunningProgramChallenge(currentRunningChallenge));
      handleResponseScreenNavigation({
        currentRunningChallenge,
        challengeEndedYesterday,
        programData: data,
      });
      let eventDTO = data?.eventDTO;
      let sessions = data?.programSessions;
      let challenges = data?.programChallenges;
      let activityLists = [];
      let calenderData = [];
      for (let i = 0; i < eventDTO?.length; i++) {
        let event = eventDTO[i];
        let activityLists = event?.activityList;
        let targetTimeList = event?.eventTargetTimeList;

        if (event) {
          let object = {};
          object.id = event?.id;
          object.title = event?.name;
          object.challengeType = event?.challengeType;
          object.start = event?.localStartDate;
          object.end = moment(event?.localEndDate)
            .add(1, "day")
            .format("YYYY-MM-DD");
          object.fontSize = "20px";
          object.editable = false;
          object.extendedProps = {
            programDetails: {
              name: event?.name,
              eventId: event?.id,
              dateFrom: ApplicationUtil.changeDateFormat(event?.localStartDate),
              dateTo: ApplicationUtil.changeDateFormat(event?.localEndDate),
              category: `${event?.eventRunCategories[0]?.categoryName} - ${
                event?.targetedSteps || event?.eventRunCategories[0]?.category
              }`,
              details: event,
              organizers: data?.organizers,
              image: data?.imageDto,
              eventTargetTimeList: targetTimeList ? targetTimeList : [],
            },
          };
          calenderData.push(object);
        }
        // =========================================================
        //                      Target Time events
        // =========================================================
        if (targetTimeList?.length > 0) {
          for (let j = 0; j < targetTimeList?.length; j++) {
            let activity = targetTimeList[j];
            let object = {};

            object.id = activity?.id;
            object.title = "title";
            object.start = activity?.dayOfActivity;
            object.end = activity?.dayOfActivity;
            object.display = "block";
            object.fontSize = "20px";
            object.editable = false;
            object.backgroundColor = "#3788d8";
            object.borderColor = "#3788d8";
            let displayColumns = activity?.displayColumns?.includes("steps")
              ? "targetTime,activeTime"
              : // : activity?.cooperTestRemark
                // ? activity?.displayColumns.concat(",remark")
                "targetTime,activeTime";

            object.extendedProps = {
              activity: {
                displayColumns,
                details: activity,
                category: "timeShow",
                targetedSteps: 0,
              },
              eventDetails: event,
            };
            calenderData.push(object);
          }
        }
        if (activityLists?.length > 0) {
          for (let j = 0; j < activityLists?.length; j++) {
            let activity = activityLists[j];
            let object = {};
            object.id = activity?.id;
            object.title = activity?.name || activity?.type;
            object.start = ApplicationUtil.changeDateFormatDate(
              activity?.startDateLocal
            );
            object.display = "block";
            object.fontSize = "20px";
            object.editable = false;
            object.backgroundColor = activity?.valid ? "#ffa600" : "#D04848";
            object.borderColor = activity?.valid ? "#ffa600" : "#D04848";
            let displayColumns = activity?.displayColumns?.includes("steps")
              ? "target,achieved"
              : // : activity?.cooperTestRemark
                // ? activity?.displayColumns.concat(",remark")
                activity?.displayColumns;
            if (
              event.challengeType ===
              ApplicationConstants.challengeTypeList[6].value
            ) {
              displayColumns = "steps,completionTime";
            }
            object.extendedProps = {
              activity: {
                displayColumns,
                details: activity,
                target: activity?.displayColumns?.includes("steps")
                  ? event?.targetedSteps
                    ? event?.targetedSteps
                    : null
                  : "",
                //Ankit: if target = 0 it was taking category value which should be not taken.

                // target: activity?.displayColumns?.includes("steps")
                // ? event?.targetedSteps
                //   ? event?.targetedSteps
                //   : event?.eventRunCategories[0]?.category
                // : ""

                targetedSteps: event?.targetedSteps,
              },
              eventDetails: event,
            };
            calenderData.push(object);
          }
        }
      }

      if (challenges) {
        for (let i = 0; i < challenges.length; i++) {
          let challenge = challenges[i];
          let questionnaires = challenge?.questionnaires;

          if (challenge) {
            let object = {};
            object.id = challenge?.id;
            object.title = challenge?.name;
            object.programChallengeType = challenge?.programChallengeType;
            object.start = moment(
              challenge?.challengeStartDate,
              "DD-MM-YYYY HH:mm:ss"
            ).format("YYYY-MM-DD");
            object.end = moment(
              challenge?.challengeEndDate,
              "DD-MM-YYYY HH:mm:ss"
            )
              .add(1, "day")
              .format("YYYY-MM-DD");
            object.fontSize = "20px";
            object.editable = false;
            object.extendedProps = {
              challengeEventDetails: {
                id: challenge?.id,
                name: challenge?.name,
                dateFrom: moment(
                  challenge?.challengeStartDate,
                  "DD-MM-YYYY HH:mm:ss"
                ).format("DD-MM-YYYY"),
                dateTo: moment(
                  challenge?.challengeEndDate,
                  "DD-MM-YYYY HH:mm:ss"
                ).format("DD-MM-YYYY"),
                questionnaires: challenge?.questionnaires,
                challengeDetails: challenge,
                organizers: data?.organizers,
              },
            };
            calenderData.push(object);
          }
          // =========================================================
          //                Response Status of the Day
          // =========================================================
          if (questionnaires?.length > 0) {
            let dayNumbers = {};
            let currentDate = moment().startOf("day");
            let keys = Object.keys(questionnaires);

            for (let j = 0; j < keys.length; j++) {
              let statusBlock = questionnaires[j];
              let eventStartDate = moment(
                challenge?.challengeStartDate,
                "DD-MM-YYYY HH:mm:ss"
              ).startOf("day");
              let viewDate = moment(
                statusBlock?.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              ).startOf("day");
              let dayNumber = viewDate.diff(eventStartDate, "days") + 1;
              let isCurrentDay = moment(
                statusBlock?.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              )
                .startOf("day")
                .isSame(moment(currentDate).startOf("day"), "day");

              let isFutureDay = moment(
                statusBlock?.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              )
                .startOf("day")
                .isAfter(moment(currentDate).startOf("day"), "day");
              let isPreviousDay = moment(
                statusBlock?.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              ).isBefore(moment(currentDate).startOf("day"), "day");

              let challengeStartDate =
                challenge?.challengeStartDate?.split(" ")[0];
              const today = moment().format("DD-MM-YYYY");
              const isViewDayToday = moment(today, "DD-MM-YYYY").isSame(
                moment(viewDate),
                true
              );
              let isStartDay;
              if (isViewDayToday) {
                isStartDay = moment(challengeStartDate, "DD-MM-YYYY").isSame(
                  moment(viewDate),
                  true
                );
              }

              if (!dayNumbers[dayNumber]) {
                // if (isPreviousDay || isStartDay) {
                if (isPreviousDay) {
                  let object = {};
                  object.id =
                    statusBlock?.programChallengeQuestionDto?.viewDate;
                  object.title = "Status";
                  object.start = moment(
                    statusBlock?.programChallengeQuestionDto?.viewDate,
                    "DD-MM-YYYY HH:mm:ss"
                  ).format("YYYY-MM-DD");
                  object.end = moment(
                    statusBlock?.programChallengeQuestionDto?.viewDate,
                    "DD-MM-YYYY HH:mm:ss"
                  ).format("YYYY-MM-DD");
                  object.display = "block";
                  object.fontSize = "20px";
                  object.editable = false;
                  if (isFutureDay) {
                    object.backgroundColor = "#3788d8 ";
                    object.borderColor = "#3788d8";
                  } else if (isCurrentDay) {
                    object.backgroundColor = "#afcfef";
                    object.borderColor = "#afcfef";
                  } else {
                    object.backgroundColor = "#afcfef";
                    object.borderColor = "#afcfef";
                  }

                  object.extendedProps = {
                    responseStatusOfTheDay: {
                      programChallengeType: statusBlock?.programChallengeType,
                      isChallengePerformed: statusBlock?.isChallengePerformed,
                      question: statusBlock?.question,
                      questionResponseType: statusBlock?.questionResponseType,
                      responseStatus: statusBlock?.responseStatus,
                      details: statusBlock,
                      isPreviousDay: isPreviousDay,
                      isCurrentDay: isCurrentDay,
                      dayNumber: dayNumber,
                      isStartDay: isStartDay,
                    },
                    challengeDetails: challenge,
                  };
                  // calenderData.push(object);
                  dayNumbers[dayNumber] = true;
                }
              }
            }
          }
        }
      }

      if (sessions) {
        for (let i = 0; i < sessions?.length; i++) {
          let session = sessions[i];
          if (session && session?.name) {
            let object = {};
            object.id = session?.id;
            object.title = session?.name;
            object.start = ApplicationUtil.changeDateFormatDate(
              session?.sessionStartDate
            );
            object.end = ApplicationUtil.changeDateFormatDate(
              session?.sessionStartDate
            );
            object.fontSize = "20px";
            object.editable = false;
            object.extendedProps = {
              sessionDetails: {
                id: session?.id,
                day: moment(
                  ApplicationUtil.changeDateFormatDate(
                    session?.sessionStartDate
                  )
                ).format("dddd"),
                time: `${moment(
                  `${ApplicationUtil.changeDateFormatDate(
                    session?.sessionStartDate
                  )} ${session?.sessionStartDate?.split(" ")[1]}`
                ).format("LT")} - ${moment(
                  `${ApplicationUtil.changeDateFormatDate(
                    session?.sessionEndDate
                  )} ${session?.sessionEndDate?.split(" ")[1]}`
                ).format("LT")}`,
                endDate: `${moment(
                  `${ApplicationUtil.changeDateFormatDate(
                    session?.sessionEndDate
                  )} ${session?.sessionEndDate?.split(" ")[1]}`
                ).format("L LT")}`,
                meetingLink: session?.meetingLink,
                faculty: session?.facultyName || "",
                organizers: data?.organizers,
              },
            };
            calenderData.push(object);
          }
        }
      }
      setCalenderEvents(calenderData);
    });
  };

  const handleEventMount = (info) => {
    info.el.setAttribute("id", `event-${info.event.id}`);
  };
  const weekSessionView = (info) => {
    let { sessionDetails } = info?.event?.extendedProps;
    let programTitle = info.event?.title;
    let endDate = sessionDetails?.endDate;
    let currentDate = moment().format("L");
    let isSessionOver = moment(endDate).isAfter(currentDate);

    /**
     * old hard coded logo of session
     * <img src='https://www.shutterstock.com/image-vector/yoga-logo-vector-emblem-260nw-571076140.jpg' alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-laptopContent'/>
     * <img src='https://www.shutterstock.com/image-vector/yoga-logo-vector-emblem-260nw-571076140.jpg' alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
     */

    /**
     * For Desktopn use : class='fc-laptopContent' and html line starts from line 545 to line 568
     * For Mobile use : class='fc-onlyMobile' and html line starts from line 571 to line 594
     */
    let html = `<div class='fc-htmlEventContent'>
    <div style='display:flex; align-items:flex-start'>

    <img src=${
      sessionDetails?.organizers && sessionDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${sessionDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' class='fc-laptopContent' height=30 width=30 style='border-radius:50%; object-fit:cover' />
<div style='margin-left:5px; margin-bottom:5px' class='fc-laptopContent'>
   <p class='fc-htmlEventContent-span'>Time: <span>${
     sessionDetails?.time
   }</span> 
   </p>
   <p class='fc-htmlEventContent-span'>Day: <span>${sessionDetails?.day}</span>
   </p>
</div>
</div>
<p class='fc-htmlEventContent-span-title fc-laptopContent'>${programTitle}</p>
<p class='fc-htmlEventContent-span fc-laptopContent'>Faculty: <span>${
      sessionDetails?.faculty
    }</span>
</p>
${
  sessionDetails?.meetingLink && isSessionOver
    ? `<a class='join-link fc-laptopContent' target='_blank' href=${sessionDetails?.meetingLink}>Join Here</a>`
    : ""
}

  
<img src=${
      sessionDetails?.organizers && sessionDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${sessionDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
<p class='fc-onlyMobile fc-mobileSessionLabel-span'>Time: <span>${
      sessionDetails?.time
    }</span>
</p>
<p class='fc-onlyMobile fc-mobileSessionLabel-span'>Day: <span>${
      sessionDetails?.day
    }</span>
</p>
<p class='fc-onlyMobile fc-mobileSessionLabel-span-title'>${programTitle}</p>
<p class='fc-onlyMobile fc-mobileSessionLabel-span'>Faculty: <span>${
      sessionDetails?.faculty
    }</span>
</p>
${
  sessionDetails?.meetingLink && isSessionOver
    ? `<a class='join-link fc-onlyMobile' target='_blank' href=${sessionDetails?.meetingLink}>Join Here</a>`
    : ""
}


    <button class='fc-viewDetailBtn' id='viewDetails_session'>View Details</button>

   
    </div>`;
    return html;
  };
  const weekProgramDetailsView = (info) => {
    let { programDetails } = info?.event?.extendedProps;

    let challengeType = info?.event?.extendedProps?.challengeType;

    /**
     * For Desktopn  :use class='fc-laptopContent' and html line starts from line 617 to line 643
     * For Mobile  :use class='fc-onlyMobile' and html line starts from line 647 to line 667
     */

    let html = `
    <div class='fc-htmlEventContent'>


   <div style='display:flex; align-items:center'>
      <img src=${
        programDetails?.organizers && programDetails?.organizers?.length > 0
          ? `${API_URL_FILE}${programDetails?.organizers[0]?.organizerLogo}`
          : eventImg
      } alt='yoga' class='fc-laptopContent' height=30 width=30 style='border-radius:50%; object-fit:cover' />
      <div style='margin-left:10px;' >
         <p class='fc-htmlEventContent-span fc-laptopContent'>From: <span>${
           programDetails?.dateFrom
         }</span>
         </p>
         <p class='fc-htmlEventContent-span fc-laptopContent'>To: <span>${
           programDetails?.dateTo
         }</span>
         </p>
      </div>
   </div>
   <p class='fc-htmlEventContent-span-title fc-laptopContent'>${
     info.event.title
   }
   </p>
   <p class='fc-htmlEventContent-span fc-laptopContent'>${
     programDetails?.category && challengeType !== "STAIR_STEPPER"
       ? `${programDetails?.category}`
       : ""
   }
   </p>



   <img src=${
     programDetails?.organizers && programDetails?.organizers?.length > 0
       ? `${API_URL_FILE}${programDetails?.organizers[0]?.organizerLogo}`
       : eventImg
   } alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-onlyMobile'/>
   <p class='fc-onlyMobile fc-mobileSessionLabel-span'>From: <span>${
     programDetails?.dateFrom
   }</span>
   </p>
   <p class='fc-onlyMobile fc-mobileSessionLabel-span'>To: <span>${
     programDetails?.dateTo
   }</span>
   </p>
   <p class='fc-onlyMobile fc-mobileSessionLabel-span-title'>${info.event.title}
   </p>
   <p class='fc-onlyMobile fc-mobileSessionLabel-span'>${
     programDetails?.category && challengeType !== "STAIR_STEPPER"
       ? `${programDetails?.category}`
       : ""
   }
   </p>


   <button class='fc-viewDetailBtn' id='viewDetails_event'>View Details</button>
</div>
    `;

    return html;
  };

  const weekActivityView = (info) => {
    let { activity, programDetails, eventDetails } = info?.event?.extendedProps;
    const targetedSteps = info?.event?.extendedProps?.activity?.targetedSteps;
    let title = info.event.title;
    let isValid = activity?.details?.valid;
    let validationReason = activity?.details?.validationReason;
    let challengeType = eventDetails?.challengeType;
    let html;
    if (activity.category === "timeShow") {
      if (!activity?.details?.targetTime) {
        html = `<div class='fc-htmlEventContent restDay' style='display:";'>
        <img
       src=${restImage}
       alt=""
       style='width:30px; height: 25px;'
     />
        <p  class='fc-htmlEventContent-span' style="margin:0px " >
       Rest Day </p> </div>`;
      } else {
        html = `
<div class='fc-htmlEventContent'>

   <p  class='fc-htmlEventContent-span' style="fontSize:14px">
   <div  class='fc-htmlEventContent-div-flex'>
      <p  style="display: flex;">
         <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Pocket_watch.svg/640px-Pocket_watch.svg.png'  
            alt='not'
            style='width:15px; height: 17px;'/>
         &nbsp
         <span  class="textLabel">Target Time:</span>&nbsp;
      </P>
      <p>
         ${activity?.details?.targetTime + `<span>&nbsp;Min.</span>` || "-"}
      </P>
   </div>
   </p>


   <p  class='fc-htmlEventContent-span' style="fontSize:14px">
   <div  class='fc-htmlEventContent-div-flex' >
      <p style="display: flex;">
         <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Arzwiki-medal-2019.png/640px-Arzwiki-medal-2019.png'  
            alt='not'
            style='width:13px; height: 13px;'
            />
         &nbsp
         <span class="textLabel">Achieved Time:</span>&nbsp;
      </P>
      <p>
         ${activity?.details?.activeTime + `<span>&nbsp;Min.</span>` || "-"}
      </P>
   </div>
   </p>

   
</div>
        `;
      }
    } else {
      html = `<div class='fc-htmlEventContent'>
   <p class='fc-htmlEventContent-span-title-responsiveFontSize'>${title}</p>
   ${
     //COOPER_TEST foor hide
     activity &&
     activity.displayColumns
       ?.split(",")
       .filter((x) => x)
       .map(
         (act) =>
           `
   <p key=${act} class='fc-htmlEventContent-span'>

   <div  class= ${
     challengeType === "COOPER_TEST" &&
     (act === "distance" || act === "completionTime")
       ? "fc-htmlEventContent-div-flex_HideChild-BasedOnCond"
       : act == "target" && activity?.target === null
       ? ""
       : " fc-htmlEventContent-div-flex "
   }>

      <p style="display: flex; gap: 5px;">
         ${
           act && act === "target" && activity.target > 0
             ? `<img
            src="https://upload.wikimedia.org/wikipedia/commons/7/75/Twemoji13_1f3af.svg"
            alt="not"
            style="width:15px; height: 17px;"
            />`
             : act && act === "achieved"
             ? `<img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Arzwiki-medal-2019.png/640px-Arzwiki-medal-2019.png"
            alt="not"
            style="width:13px; height: 13px;"
            />`
             : challengeType === "STAIR_STEPPER" && act === "steps"
             ? `<svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="13.000000pt"
    height="13.000000pt"
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <metadata>
      Stair stepper Icon
    </metadata>
    <g
      transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)"
      fill="#333333"
      stroke="none"
    >
      <path d="M1363 5537 c-93 -27 -248 -188 -279 -289 -155 -523 539 -842 825 -379 222 358 -130 788 -546 668z" />
      <path d="M1085 4492 c-486 -233 -616 -351 -813 -736 -151 -298 -161 -363 -65 -454 145 -135 264 -66 427 250 110 213 228 372 256 345 5 -5 -17 -135 -50 -288 -99 -461 -59 -616 185 -718 42 -18 70 -42 65 -56 -4 -14 -45 -196 -90 -405 -45 -209 -108 -500 -141 -647 -32 -146 -59 -279 -59 -295 0 -23 46 -28 236 -28 l237 0 138 642 c164 756 129 678 289 654 160 -24 135 12 210 -301 l63 -265 244 -6 c133 -3 243 0 243 6 0 61 -191 777 -220 825 -62 103 -172 161 -371 197 -227 42 -215 18 -156 298 62 289 49 273 142 177 109 -111 180 -139 484 -184 307 -46 373 -34 430 74 95 184 -6 282 -340 330 -285 42 -249 22 -407 233 -266 355 -297 376 -606 391 -213 11 -232 9 -331 -39z" />
      <path d="M4851 4029 c-25 -26 -31 -85 -31 -322 0 -403 50 -367 -508 -367 -571 0 -520 35 -529 -368 -3 -170 -15 -313 -27 -327 -16 -19 -132 -25 -464 -25 -576 0 -532 30 -532 -361 0 -410 44 -379 -539 -379 -550 0 -501 36 -501 -367 0 -385 47 -353 -517 -353 -327 0 -462 -7 -479 -24 -46 -46 -32 -650 15 -675 71 -38 5002 -28 5057 10 l44 31 0 1755 c0 1338 -6 1761 -24 1779 -40 40 -924 33 -965 -7z" />
    </g>
  </svg>`
             : challengeType === "STAIR_STEPPER" && act === "completionTime"
             ? `<img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Pocket_watch.svg/640px-Pocket_watch.svg.png"
            alt="not"
            style="width:15px; height: 16px;"
            />`
             : ""
         }
            
         ${
           labels[act] === "Target" && activity?.target == null
             ? `<span class="textLabel" style = "display : none"; > "": ""  </span>`
             : `<span class="textLabel">  ${labels[act]}: </span>`
         }
      </p>

      <p>
         <span style="font-weight: normal;"> ${
           act === "distance"
             ? isValid //if activity is valid
               ? activity?.details["cooperTestDistance"]
                 ? parseFloat(
                     activity?.details["cooperTestDistance"] / 1000
                   ).toFixed(2) + " km"
                 : `0 km`
               : //if activity is not valid
               activity?.details["distance"]
               ? parseFloat(activity?.details["distance"] / 1000).toFixed(2) +
                 " km"
               : `0 km`
             : //  : act === "remark"
             //  ? activity?.details["cooperTestRemark"]
             act === "target"
             ? activity?.target > 0
               ? activity?.target
               : ""
             : act === "achieved"
             ? activity?.details["steps"]
             : activity?.details[act]
             ? activity?.details[act]
             : `00:00:00`
           //ankit  Defect_id_485 : if completion time is not send by api set to zero
         }
         </span>
      </p>

   </div>

   </p>
   `
       )
       .join("")
   }
   <p class='fc-htmlEventContent-span-title-responsiveFontSize'><span>${
     !isValid ? "Invalid activity" : ""
   }</span>
   </p>
</div>`;
    }

    return html;
  };

  const weekChallengeDetailsResponseView = (info) => {
    let { challengeEventDetails } = info?.event?.extendedProps;

    // checked the which challenge available
    let isZeroSugar =
      challengeEventDetails?.challengeDetails?.programChallengeType ===
      "ZERO_SUGAR";
    let isHydration =
      challengeEventDetails?.challengeDetails?.programChallengeType ===
      "HYDRATION";
    // new change will add sleep challenge ===>

    let html;
    // fc-laptopContent class for only desktop view
    html = `<div class='fc-htmlEventContent ${
      isZeroSugar
        ? "zero-sugar-challenge"
        : isHydration
        ? "HydrationChallenge"
        : ""
    }'>

    <div style='display:flex; align-items:center'>

    <img src=${
      challengeEventDetails?.organizers &&
      challengeEventDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${challengeEventDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' class='fc-laptopContent' height=30 width=30 style='border-radius:50%; object-fit:cover' />
    <img src=${
      challengeEventDetails?.organizers &&
      challengeEventDetails?.organizers?.length > 0
        ? `${API_URL_FILE}${challengeEventDetails?.organizers[0]?.organizerLogo}`
        : eventImg
    } alt='yoga' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>

    <div style='margin-left:10px;' >
    <p class='fc-htmlEventContent-span fc-laptopContent'>From: <span>${
      challengeEventDetails?.dateFrom
    }</span></p>
 
    <p class='fc-htmlEventContent-span fc-laptopContent'>To: <span>${
      challengeEventDetails?.dateTo
    }</span></p>
    </div>
    </div>

     <p class='fc-onlyMobile fc-mobileSessionLabel-span'>From: <span>${
       challengeEventDetails?.dateFrom
     }</span></p>
 
    <p class='fc-onlyMobile fc-mobileSessionLabel-span'>To: <span>${
      challengeEventDetails?.dateTo
    }</span></p>

    <p class='fc-htmlEventContent-span-title '>${info.event.title}</p>
    <div class='fc-viewDetailBtn-wrapper' style='display:flex; gap:10px'>
    <button class='fc-viewDetailBtn' id='viewDetails'>View Details</button>
${
  !moment(challengeEventDetails?.dateFrom, "DD-MM-YYYY")
    .startOf()
    .isAfter(moment().subtract(1, "day").startOf())
    ? `<button class='fc-viewDetailBtn' id='submitResponse'>Submit Response</button>`
    : ""
}
     
    </div>
    </div>
    `;
    return html;
  };

  // ${moment(20-08-2024 , "DD-MM-YYYY").isAfter(moment())}
  const weekChallengeStatusResponse = (info) => {
    let { responseStatusOfTheDay } = info?.event?.extendedProps;
    let html;

    // if (responseStatusOfTheDay?.isStartDay) {
    //   html = `<div class='fc-htmlEventContent' style='display: flex; flex-direction: row; justify-content: space-between; align-items: center;'>
    //             <p class='fc-laptopContent' style='color: black;font-weight: bold; font-size: 12px;'>
    //             The Data can be updated by end of day.
    //             </p>

    //       <img class='fc-showOnMobileOnly' src='https://upload.wikimedia.org/wikipedia/commons/2/25/Hourglass_2.svg' alt='positive' height=20 width=20 style=' object-fit: contain;' class='fc-mobileLogo'/>
    //       </div>`;
    // }
    if (responseStatusOfTheDay?.isPreviousDay) {
      if (
        responseStatusOfTheDay?.isChallengePerformed === true &&
        responseStatusOfTheDay?.responseStatus == "Positive"
      ) {
        html = `<div class='fc-htmlEventContent'>


        <div  style='display:flex;  flex-direction: column; justify-content:center; align-items: center'>
           <div  style ='align-self: center;'>
              <p>
                 <img src='https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg'  alt='not' height=20 width=20 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
              </p>
           </div>
           <div>
              <p class='fc-showOnMobileOnly' style = 'color:black;font-weight: bold; font-size:9.5px; text-wrap: nowrap;'>Day - ${responseStatusOfTheDay?.dayNumber} </p>
           </div>
        </div>

        
        <div 
           style = 'display: flex;
           justify-content: space-between;
           align-items: center;' >
           <p class='fc-laptopContent fc-htmlEventContent-span' style = 'height: 20px' id='addResponse' > 
           <p class='fc-laptopContent' style = 'color:black;font-weight: bold; font-size: 16px; text-wrap: nowrap;'>Day - ${responseStatusOfTheDay?.dayNumber} &nbsp;</p>
           <img src='https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg' alt='not' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-laptopContent'/>
           </p>
        </div>

        
     </div>`;
        // html = `<div class='fc-htmlEventContent' style='display: flex; flex-direction: row; justify-content: space-between; align-items: center;'>
        //   <p class='fc-htmlEventContent-span' style='height: 20px;' id='addResponse'>
        //     <p class='fc-laptopContent' style='color: black;font-weight: bold; font-size: 16px;'>Day - ${responseStatusOfTheDay?.dayNumber} &nbsp;</p>
        //   <img src='https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg' alt='positive' height=30 width=30 style='border-radius: 50%; object-fit: cover;' class='fc-laptopContent'/>
        //   <img src='https://upload.wikimedia.org/wikipedia/commons/8/8b/Eo_circle_green_white_checkmark.svg' alt='positive' height=20 width=20 style='border-radius: 50%; object-fit: cover;' class='fc-mobileLogo'/>
        //   </p>
        //   </div>`;
      } else {
        html = `<div class='fc-htmlEventContent'>
        <div  style='display:flex;  flex-direction: column; justify-content:center; align-items: center'>
           <div  style ='align-self: center;'>
              <p>
                 <img src= ${
                   responseStatusOfTheDay?.isChallengePerformed === true &&
                   responseStatusOfTheDay?.responseStatus === "Negative"
                     ? "https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg"
                     : "https://upload.wikimedia.org/wikipedia/commons/c/c1/Icon-x-grey.png"
                 }  alt='not' height=20 width=20 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
              </p>
           </div>
           <div>
              <p class='fc-showOnMobileOnly' style = 'color:black;font-weight: bold; font-size: 9.5px; text-wrap: nowrap;'>Day - ${
                responseStatusOfTheDay?.dayNumber
              } </p>
           </div>
        </div>


        <div 
           style = 'display: flex;
           justify-content: space-between;
           align-items: center;' >
           <p class='fc-laptopContent fc-htmlEventContent-span' style = 'height: 20px' id='addResponse' > 
           <p class='fc-laptopContent' style = 'color:black;font-weight: bold; font-size: 16px; text-wrap: nowrap;'>Day - ${
             responseStatusOfTheDay?.dayNumber
           } &nbsp;</p>
           <img src= ${
             responseStatusOfTheDay?.isChallengePerformed === true &&
             responseStatusOfTheDay?.responseStatus === "Negative"
               ? "https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg"
               : "https://upload.wikimedia.org/wikipedia/commons/c/c1/Icon-x-grey.png"
           } alt='not' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-laptopContent'/>
           </p>
        </div>
     </div>`;
        //   html = `<div class='fc-htmlEventContent' style = 'display: flex; flex-direction: row;
        //   justify-content: space-between;
        //   align-items: center;' >
        //   <p class='fc-htmlEventContent-span' style = 'height: 20px' id='addResponse' >
        //   <p class='fc-laptopContent' style = 'color:black;font-weight: bold; font-size: 16px;'>Day - ${responseStatusOfTheDay?.dayNumber} &nbsp;</p>
        //   <img src='https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg' alt='not' height=30 width=30 style='border-radius:50%; object-fit:cover' class='fc-laptopContent'/>
        //   <img src='https://upload.wikimedia.org/wikipedia/commons/2/28/White_X_in_red_background.svg'  alt='not' height=20 width=20 style='border-radius:50%; object-fit:cover' class='fc-mobileLogo'/>
        //   </p>
        // </div>`;
      }
    }

    return html;
  };

  const weekProgramEventContent = (info) => {
    if (info?.view?.type === "weekProgram") {
      let eventHtml = "";
      let sessionHtml = "";
      let activityHtml = "";
      let challengeHtml = "";
      let challengeResponseHtml = "";
      if (info.event.extendedProps?.challengeEventDetails) {
        //for response challenge
        challengeHtml += weekChallengeDetailsResponseView(info);
      }

      if (info.event.extendedProps?.responseStatusOfTheDay) {
        challengeResponseHtml += weekChallengeStatusResponse(info);
      }

      if (info.event.extendedProps?.activity) {
        activityHtml += weekActivityView(info);
      }

      if (info.event.extendedProps?.programDetails) {
        //for physical activity
        eventHtml += weekProgramDetailsView(info);
      }
      if (info.event.extendedProps?.sessionDetails) {
        //for session
        sessionHtml += weekSessionView(info);
      }
      return {
        html: info.event?.extendedProps?.sessionDetails
          ? sessionHtml
          : info.event.extendedProps?.activity
          ? activityHtml
          : info.event.extendedProps?.challengeEventDetails
          ? challengeHtml
          : info.event.extendedProps?.responseStatusOfTheDay
          ? challengeResponseHtml
          : eventHtml,
      };
    }

    if (info?.view?.type === "monthProgram") {
      let eventHtml = "";
      let sessionHtml = "";
      let activityHtml = "";
      let challengeHtml = "";
      let challengeResponseHtml = "";

      if (info.event.extendedProps?.challengeEventDetails) {
        challengeHtml += weekChallengeDetailsResponseView(info);
      }

      if (info.event.extendedProps?.responseStatusOfTheDay) {
        challengeResponseHtml += weekChallengeStatusResponse(info);
      }

      if (info.event.extendedProps?.activity) {
        activityHtml += weekActivityView(info);
      }

      if (info.event.extendedProps?.programDetails) {
        eventHtml += weekProgramDetailsView(info);
      }
      if (info.event.extendedProps?.sessionDetails) {
        sessionHtml += weekSessionView(info);
      }

      return {
        html: info.event?.extendedProps?.sessionDetails
          ? sessionHtml
          : info.event.extendedProps?.activity
          ? activityHtml
          : info.event.extendedProps?.challengeEventDetails
          ? challengeHtml
          : info.event.extendedProps?.responseStatusOfTheDay
          ? challengeResponseHtml
          : eventHtml,
      };
    }

    if (info?.view?.type === "wholeProgram") {
      let eventHtml = "";
      let sessionHtml = "";
      let activityHtml = "";
      let challengeHtml = "";
      let challengeResponseHtml = "";

      if (info.event.extendedProps?.challengeEventDetails) {
        challengeHtml += weekChallengeDetailsResponseView(info);
      }

      if (info.event.extendedProps?.responseStatusOfTheDay) {
        challengeResponseHtml += weekChallengeStatusResponse(info);
      }

      if (info.event.extendedProps?.activity) {
        activityHtml += weekActivityView(info);
      }

      if (info.event.extendedProps?.programDetails) {
        eventHtml += weekProgramDetailsView(info);
      }
      if (info.event.extendedProps?.sessionDetails) {
        sessionHtml += weekSessionView(info);
      }

      return {
        html: info.event?.extendedProps?.sessionDetails
          ? sessionHtml
          : info.event.extendedProps?.activity
          ? activityHtml
          : info.event.extendedProps?.challengeEventDetails
          ? challengeHtml
          : info.event.extendedProps?.responseStatusOfTheDay
          ? challengeResponseHtml
          : eventHtml,
      };
    }
  };

  const navigateToResults = () => {
    history.push("/programResults");
  };

  const getMonthDuration = () => {
    let wholeProgramDurationMonths = moment(programDetails.localEndDate).diff(
      moment(programDetails.localStartDate),
      "months"
    );

    const remainingDays = moment(programDetails.localEndDate).diff(
      moment(programDetails.localStartDate).add(
        wholeProgramDurationMonths,
        "months"
      ),
      "days"
    );

    // Adjust the month count if there are any remaining days
    if (remainingDays > 0) {
      wholeProgramDurationMonths += 1;
    }
    const finalMonthDuration = wholeProgramDurationMonths
      ? wholeProgramDurationMonths < 1
        ? 1
        : Math.round(wholeProgramDurationMonths)
      : Math.round(wholeProgramDurationMonths);

    return finalMonthDuration;
  };

  useEffect(() => {
    if (!ApplicationUtil.isuserLoggedIn("runner")) {
      let search = window.location.href;
      const urlParams = new URLSearchParams(window.location.search);
      let distKey = search.split("distkey=")[1];
      if (!distKey) distKey = search.split("distkey=")[1];
      let loginRoute = "/login";
      if (distKey !== undefined) {
        loginRoute = loginRoute + "?distKey=" + distKey + "&isprogram=true";
      }
      history.push(loginRoute);
    }
  }, []);

  useEffect(() => {
    let isCalendar = window.location.href.includes("calender");
    if (isCalendar) {
      document.title =
        `${programDetails?.name || ""} Calendar` || "Wellness Calendar";
    }
  }, [programDetails]);

  return (
    <>
      <AppToolBar>
        <Header
          label={
            `${programDetails?.name || ""} Calendar` || "Wellness Calendar"
          }
          {...props}
          showDrawer
          fromDate={ApplicationUtil.changeDateFormat(
            programDetails?.localStartDate
          )}
          toDate={ApplicationUtil.changeDateFormat(
            programDetails?.localEndDate
          )}
        />
      </AppToolBar>
      <div className={classes.calenderContainer}>
        <RunnerSideBreadcrumbs />
        <SubmitCancelBtn
          label={"Dashboard"}
          type={"submit"}
          onClick={() => history.push("/programDashboard")}
          style={{
            width: "160px",
          }}
        />
        {/* changed the flag of from 'summarygGenerated' to 'showLeaderboard' to show the Result button  */}
        {programDetails?.showLeaderboard && (
          <SubmitCancelBtn
            label={"Results"}
            type={"submit"}
            onClick={() => navigateToResults()}
            style={{
              width: "160px",
              marginLeft: "10px",
            }}
          />
        )}
        <FullCalenderComponent
          events={calenderEvents}
          // handleDateSelect={handleDateSelect}
          // renderEventContent={renderEventContent}
          handleEventClick={handleEventClick}
          handleEvents={handleEvents}
          calenderRef={calendarRef}
          handleCalenderViewDate={handleCalenderViewDate}
          views={{
            weekProgram: {
              type: "dayGridWeek", //'dayGridWeek',
              buttonText: "Week",
              eventContent: (info) => {
                return weekProgramEventContent(info);
              },
              dayHeaderContent: (args) => {
                return moment(args.date).format("ddd Do");
              },

              dayMaxEventRows: 2,
              // duration: { days: 7 },
            },
            monthProgram: {
              type: "dayGridMonth",
              buttonText: "Month",

              eventContent: (info) => {
                return weekProgramEventContent(info);
              },
              displayEventTime: false,
              dayMaxEventRows: 2,
              // duration: "month",
            },
            wholeProgram: {
              buttonText: "Program",
              type: "dayGridMonth",
              // validRange: {
              //   start: programDetails.localStartDate,
              //   end: programDetails.localEndDate,
              // },
              eventContent: (info) => {
                return weekProgramEventContent(info);
              },
              displayEventTime: false,
              dayMaxEventRows: 2,
              titleFormat: { month: "short", year: "numeric" },
              duration: {
                months: getMonthDuration(),
              },
            },
          }}
          handleEventMount={handleEventMount}
        />
        <Popover
          id={selectedEventDetails?.event?.id}
          open={Boolean(selectedEventDetails?.event)}
          anchorEl={selectedEventDetails?.el}
          onClose={() => setSelectedEventDetails({})}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={classes.popoverContent}>
            {selectedEventDetails?.event?.extendedProps?.activity?.category ===
            "timeShow" ? (
              <>
                {!selectedEventDetails?.event?.extendedProps?.activity?.details
                  ?.targetTime ? (
                  <span
                    style={{
                      color: "rgba(0,0,0,0.87)",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      src={restImage}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    />{" "}
                    &nbsp; Rest day
                  </span>
                ) : (
                  <>
                    <p
                      class="fc-htmlEventContent-span"
                      style={{ margin: "2px" }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Pocket_watch.svg/640px-Pocket_watch.svg.png"
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />{" "}
                      &nbsp;
                      <span
                        className="textLabel"
                        style={{
                          color: "rgba(0,0,0,0.87)",
                          fontWeight: "bold",
                        }}
                      >
                        Target Time:&nbsp;
                      </span>
                      <span>
                        {selectedEventDetails?.event?.extendedProps?.activity
                          ?.details?.targetTime + " Min." || "-"}
                      </span>
                    </p>

                    <p
                      class="fc-htmlEventContent-span"
                      style={{ margin: "2px" }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Arzwiki-medal-2019.png/640px-Arzwiki-medal-2019.png"
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />{" "}
                      &nbsp;
                      <span
                        className="textLabel"
                        style={{
                          color: "rgba(0,0,0,0.87)",
                          fontWeight: "bold",
                        }}
                      >
                        Achieved Time:&nbsp;
                      </span>
                      <span>
                        {selectedEventDetails?.event?.extendedProps?.activity
                          ?.details?.activeTime + " Min." || "-"}
                      </span>
                    </p>
                  </>
                )}
              </>
            ) : (
              selectedEventDetails?.event?.extendedProps?.activity &&
              selectedEventDetails?.event?.extendedProps?.activity?.category !==
                "timeShow" && (
                <>
                  {selectedEventDetails?.event?.extendedProps?.activity.displayColumns
                    ?.split(",")
                    .filter((x) => x)
                    .map((activity) => (
                      <Typography
                        key={activity}
                        className={classes.contentTypo}
                      >
                        {activity &&
                        activity === "target" &&
                        selectedEventDetails?.event?.extendedProps?.activity
                          ?.target > 0 ? (
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/7/75/Twemoji13_1f3af.svg"
                            alt="not"
                            style={{ width: "15px", height: "13px" }}
                          />
                        ) : activity && activity === "achieved" ? (
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Arzwiki-medal-2019.png/640px-Arzwiki-medal-2019.png"
                            alt="not"
                            style={{ width: "13px", height: "13px" }}
                          />
                        ) : selectedEventDetails?.event?.extendedProps
                            ?.eventDetails?.challengeType === "STAIR_STEPPER" &&
                          activity === "steps" ? (
                          <StairStepperIcon />
                        ) : selectedEventDetails?.event?.extendedProps
                            ?.eventDetails?.challengeType === "STAIR_STEPPER" &&
                          activity === "completionTime" ? (
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Pocket_watch.svg/640px-Pocket_watch.svg.png"
                            alt="not"
                            style={{ width: "15px", height: "16px" }}
                          />
                        ) : (
                          ""
                        )}
                        {labels[activity] === "Target" &&
                        selectedEventDetails?.event?.extendedProps?.activity
                          ?.target == null ? (
                          <span
                            style={{ display: "none" }}
                          >{`${labels[activity]}: `}</span>
                        ) : (
                          <span>{`${labels[activity]}: `}</span>
                        )}
                        {/* <span>{`${labels[activity]}: `}</span> */}
                        {`${
                          activity === "distance"
                            ? selectedEventDetails?.event?.extendedProps
                                ?.activity?.details["cooperTestDistance"]
                              ? `${
                                  parseFloat(
                                    selectedEventDetails?.event?.extendedProps
                                      ?.activity?.details[
                                      "cooperTestDistance"
                                    ] / 1000
                                  ).toFixed(2) || 0
                                } km`
                              : `${
                                  parseFloat(
                                    selectedEventDetails?.event?.extendedProps
                                      ?.activity?.details["distance"] / 1000
                                  ).toFixed(2) || 0
                                } Km`
                            : activity === "remark"
                            ? selectedEventDetails?.event?.extendedProps
                                ?.activity?.details["cooperTestRemark"]
                            : activity === "target"
                            ? selectedEventDetails?.event?.extendedProps
                                ?.activity?.target > 0
                              ? selectedEventDetails?.event?.extendedProps
                                  ?.activity?.target
                              : ""
                            : activity === "achieved"
                            ? selectedEventDetails?.event?.extendedProps
                                ?.activity?.details["steps"]
                            : selectedEventDetails?.event?.extendedProps
                                ?.activity?.details[activity]
                            ? selectedEventDetails?.event?.extendedProps
                                ?.activity?.details[activity]
                            : "00:00:00"
                          //ankit  Defect_id_485 : if completion time is not send by api set to zero
                        }`}
                      </Typography>
                    ))}

                  {!selectedEventDetails?.event?.extendedProps?.activity
                    ?.details?.valid &&
                    selectedEventDetails?.event?.extendedProps?.activity
                      ?.details?.validationReason && (
                      <Typography className={classes.contentTypo}>
                        <span>
                          {`"${selectedEventDetails?.event?.extendedProps?.activity?.details?.validationReason?.replaceAll(
                            '"',
                            ""
                          )}"`}
                        </span>
                      </Typography>
                    )}
                </>
              )
            )}

            {selectedEventDetails?.event?.extendedProps?.sessionDetails && (
              <div>
                <Typography className={classes.contentTypo}>
                  <span className="popover-session-title">
                    {selectedEventDetails?.event?.title}
                  </span>
                  <span>
                    {
                      selectedEventDetails?.event?.extendedProps?.sessionDetails
                        ?.day
                    }
                    ,{" "}
                    {`${selectedEventDetails?.event?.extendedProps?.sessionDetails?.time}`}
                  </span>
                  <br />
                  <span>
                    Faculty:{" "}
                    {
                      selectedEventDetails?.event?.extendedProps?.sessionDetails
                        ?.faculty
                    }
                  </span>
                  <br />

                  {selectedEventDetails?.event?.extendedProps?.sessionDetails
                    ?.meetingLink &&
                    moment(
                      selectedEventDetails?.event?.extendedProps?.sessionDetails
                        ?.endDate
                    ).isAfter(moment().format("L")) && (
                      <a
                        className={classes.joinLink}
                        target="_blank"
                        href={
                          selectedEventDetails?.event?.extendedProps
                            ?.sessionDetails?.meetingLink
                        }
                      >
                        Join Here
                      </a>
                    )}
                </Typography>
                <button
                  class={classes.viewDetailBtn}
                  onClick={() => {
                    navigateToProgramDetails({
                      sessionId:
                        selectedEventDetails?.event?.extendedProps
                          ?.sessionDetails?.id,
                    });
                  }}
                >
                  View Details
                </button>
              </div>
            )}
            {selectedEventDetails?.event?.extendedProps?.programDetails && (
              <div>
                <Typography className={classes.detailsContentTypo}>
                  <span className="title">
                    {
                      selectedEventDetails?.event?.extendedProps?.programDetails
                        ?.name
                    }
                  </span>
                  <span className="sub-title">
                    {`${selectedEventDetails?.event?.extendedProps?.programDetails?.dateFrom} to ${selectedEventDetails?.event?.extendedProps?.programDetails?.dateTo}`}
                  </span>
                </Typography>
                <Typography className={classes.detailsContentTypo}>
                  <span className="sub-title">
                    {selectedEventDetails?.event?.extendedProps?.programDetails
                      ?.category &&
                    selectedEventDetails?.event?.extendedProps?.programDetails
                      ?.details?.challengeType !== "STAIR_STEPPER"
                      ? `${selectedEventDetails?.event?.extendedProps?.programDetails?.category}`
                      : ""}
                  </span>
                </Typography>
                <button
                  class={classes.viewDetailBtn}
                  onClick={() => {
                    navigateToProgramDetails({
                      eventId:
                        selectedEventDetails?.event?.extendedProps
                          ?.programDetails?.eventId,
                    });
                  }}
                >
                  View Details
                </button>
              </div>
            )}
            {selectedEventDetails?.event?.extendedProps
              ?.challengeEventDetails && (
              <div>
                <Typography className={classes.detailsContentTypo}>
                  <span className="title" style={{ marginBottom: "5px" }}>
                    {
                      selectedEventDetails?.event?.extendedProps
                        ?.challengeEventDetails?.name
                    }
                  </span>

                  <span className="sub-title">
                    {`${selectedEventDetails?.event?.extendedProps?.challengeEventDetails?.dateFrom} to ${selectedEventDetails?.event?.extendedProps?.challengeEventDetails?.dateTo}`}
                  </span>
                </Typography>
                <button
                  class={classes.viewDetailBtn}
                  style={{ marginBottom: "5px" }}
                  onClick={() => {
                    navigateToProgramDetails({
                      challengeId:
                        selectedEventDetails?.event?.extendedProps
                          ?.challengeEventDetails?.id,
                    });
                  }}
                >
                  View Details
                </button>

                {!moment(
                  selectedEventDetails?.event?.extendedProps
                    ?.challengeEventDetails?.dateFrom,
                  "DD-MM-YYYY"
                )
                  .startOf()
                  .isAfter(moment().subtract(1, "day").startOf()) && (
                  <button
                    onClick={() => {
                      mobileNavigateSubmitResponse(
                        selectedEventDetails?.event?.extendedProps
                          ?.challengeEventDetails?.dateTo,
                        selectedEventDetails?.event?.extendedProps
                          ?.challengeEventDetails?.id
                      );
                    }}
                    class={classes.viewDetailBtn}
                    id="submitResponse"
                  >
                    Submit Response
                  </button>
                )}
              </div>
            )}
            {/* {selectedEventDetails?.event?.extendedProps
              ?.responseStatusOfTheDay && (
              <div>
                <span className="title" style={{ marginBottom: "5px" }}>
                  {selectedEventDetails?.event?.extendedProps
                    ?.responseStatusOfTheDay.isStartDay ? (
                    Properties.start_day_label
                  ) : selectedEventDetails?.event?.extendedProps
                      ?.responseStatusOfTheDay.isChallengePerformed === true ? (
                    <span>{`DAY - ${selectedEventDetails?.event?.extendedProps?.responseStatusOfTheDay.dayNumber}`}</span>
                  ) : (
                    <span>{`DAY - ${selectedEventDetails?.event?.extendedProps?.responseStatusOfTheDay.dayNumber}`}</span>
                  )}
                </span>
              </div>
            )} */}
          </div>
        </Popover>
      </div>
    </>
  );
}

const labels = {
  distance: "Distance",
  steps: "Steps",
  completionTime: "Duration",
  target: "Target",
  achieved: "Achieved",
  // remark: "Remark",
};
const styles = (theme) => ({
  calenderContainer: {
    margin: "20px",
    marginTop: "60px",
    position: "relative",
    "& .fc .fc-popover": {
      zIndex: 999,
    },

    "& .fc td": {
      padding: "0px 0 25px 0 !important",
    },

    "& .fc-event-title": {
      fontSize: "17px",
      fontWeight: 600,
      textWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    "& .fc .fc-daygrid-more-link": {
      whiteSpace: "normal",
    },
    "& .fc .fc-daygrid-day-top": {
      minHeight: "38px",
      position: "relative",
      "& a": {
        minHeight: "38px",
        // width: "100%",
        // padding: "3px",
        "& .presentDayEvent": {
          minHeight: "38px",
          padding: "2px",
          fontSize: "12px",
          borderRadius: "2px",
          background: "#3788d8",
          "& span": {
            display: "flex",
            alignItems: "center",
            "& .Labeltext": {
              display: "block",
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            },
          },
        },
      },
    },
    "& .fc .fc-daygrid-day-top .a": {
      width: "100%",
    },

    "& .fc .fc-h-event ": {
      border: "none !important",
    },

    "& .fc-daygrid-month-start": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6em",
        wordBreak: "break-word",
      },
    },
    "& .fc-daygrid-day-frame": {
      [theme.breakpoints.down("sm")]: {
        minHeight: "150px !important",
      },
    },
    "& .fc-toolbar": {
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-evenly",
      },
    },
    "& .fc-button-group": {
      marginTop: "10px",
    },
    "& .fc-toolbar-title": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },

    "&  .zero-sugar-challenge": {
      background:
        "linear-gradient(to right, rgb(253, 245, 238), rgb(242, 184, 136)) !important",
      color: "#112A46 !important",
    },
    "& .fc .HydrationChallenge": {
      background: " linear-gradient(to right, #ebf1f6, #759ac0) !important",
      color: "#000000 !important",
    },

    "& .fc-htmlEventContent": {
      padding: "5px",
      display: "block",
      overflowX: "hidden",
      textWrap: "wrap",

      "& p": {
        margin: "3px 0px",
      },
      "& .join-link": {
        lineHeight: "30px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#ffffff",
        // padding: "4px",
        borderRadius: "10px",
        color: "#3788d8",
        width: "fit-content",
        paddingInline: "10px",
        [theme.breakpoints.down("sm")]: {
          lineHeight: "12px",
          textAlign: "center",
          fontSize: "12px",
          padding: "4px",
        },
      },
      "& .fc-htmlEventContent-span": {
        // lineHeight: "22px",
        display: "flex",

        "& .isHideRestDayLabelMobileView": {
          color: "red",
          // display: "block",
          // [theme.breakpoints.down("sm")]: {
          //   display: "none",
          // },
        },
        alignItems: "center",
        "& span:nth-child(2)": {
          display: "block",
          fontSize: "14px",
          fontWeight: "500",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        "&  .textLabel": {
          display: "block",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        fontSize: "16px",
        "& span": {
          fontWeight: "bold",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down("sm")]: {
          wordBreak: "break-word",

          fontSize: "10px",
        },
      },
      "& .fc-htmlEventContent-div-flex": {
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(450)]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(571)]: {
          flexDirection: "column",
        },
        "&  .textLabel": {
          display: "block",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
      },
      "& .fc-htmlEventContent-div-flex_HideChild-BasedOnCond": {
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "5px",

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      "& .fc-htmlEventContent-span-title": {
        // lineHeight: "20px",
        fontSize: "16px",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
        },
      },
      "& .fc-htmlEventContent-span-title-responsiveFontSize": {
        // lineHeight: "20px",
        fontSize: "16px",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(400)]: {
          fontSize: "9px",
        },
        [theme.breakpoints.down(370)]: {
          fontSize: "8px",
        },
        [theme.breakpoints.down(308)]: {
          fontSize: "7px",
        },
      },

      "& .fc-viewDetailBtn-wrapper": {
        flexWrap:"wrap",
        [theme.breakpoints.down("sm")]: {
          gap: "5px !important",
        },
      },

      "& .fc-viewDetailBtn": {
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        justifyContent: "center",
        outline: "none",
        cursor: "pointer",
        padding: "5px",
        paddingInline: "8px",
        backgroundImage:
          "linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%)",
        borderRadius: "30px",
        border: "1px solid #8F9092",
        transition: "all 0.2s ease",
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: "12px",
        fontWeight: 600,
        color: "#606060",
        textShadow: "0 1px #fff",
        "&:hover": {
          fontSize: "13px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "8px",
          padding: "3px",
          "&:hover": {
            fontSize: "9px",
          },
        },
      },

      "& .fc-showOnMobileOnly": {
        display: "block",
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      "& .fc-mobileLogo": {
        display: "block",
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },

      "& .fc-onlyMobile": {
        display: "block",
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      "& .fc-mobileSessionLabel-span": {
        // lineHeight: "20px",

        fontSize: "8px",
        "& span": {
          fontWeight: "bold",
        },
      },
      "& .fc-mobileSessionLabel-span-title": {
        // lineHeight: "20px",
        fontSize: "10px",
        fontWeight: "bold",
      },

      "& .fc-laptopContent": {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
        // [theme.breakpoints.up("xs")]: {
        //   display: "block",
        // },
      },
    },

    "& .restDay": {
      minHeight: "38px",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
      "& p": {
        display: "block !important",

        [theme.breakpoints.down("sm")]: {
          display: "none !important",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "0px",
      padding: "1px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
      marginRight: "0px",
      padding: "1px",
    },
  },

  quizContainer: {
    maxWidth: "500px",
    marginInline: "20px",
    marginTop: "10px",
    "& .questionBox": {
      color: "black",
      fontWeight: "500",
    },
  },
  contentTypo: {
    fontSize: "15px",
    // lineHeight: "20px",
    "& span": {
      fontWeight: "bold",
    },
  },
  detailsContentTypo: {
    // marginTop: "10px",
    // lineHeight: "20px",
    "& .title": {
      display: "block",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#3788d8",
    },
    "& .sub-title": {
      fontWeight: "bold",
      fontSize: "15px",
      display: "block",
      color: "black",
    },
  },
  attendContainer: {
    marginTop: "10px",
  },
  popoverContent: {
    padding: "10px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& .popover-session-title": {
      display: "block",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#3788d8",
    },
  },
  joinLink: {
    lineHeight: "30px",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: "#3788d8",
    padding: "5px",
    borderRadius: "10px",
    color: "#ffffff",
  },
  viewDetailBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    cursor: "pointer",
    padding: "5px",
    paddingInline: "8px",
    backgroundImage:
      "linear-gradient(to top, #D8D9DB 0%, #fff 80%, #FDFDFD 100%)",
    borderRadius: "30px",
    border: "1px solid #8F9092",
    transition: "all 0.2s ease",
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: "12px",
    fontWeight: 600,
    color: "#606060",
    textShadow: "0 1px #fff",
    "&:hover": {
      fontSize: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "5px",
      "&:hover": {
        fontSize: "9px",
      },
    },
  },
});
export default compose(withStyles(styles), withRouter)(CalenderHomeView);
