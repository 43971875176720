class Properties {
  constructor() {
    if (!this.instance) {
      this.instance = this;
    }
    return this.instance;
  }
  static Label_Registration = "Registration";
  static Label_Resetpass = "Reset Password";
  static Label_Forgotpass = "Forgot Password";
  static Actual_distance_label = "Distance Covered";

  static Label_FirstName = "First Name";
  static Label_LastName = "Last Name";
  static Label_Emergency_Contact = "Emergency Contact Number";
  static Label_Gender = "Gender";
  static Label_Sex = "Sex";
  static Label_Age = "Age";
  static Label_EmployeeId = "Employee ID";
  static Label_Department = "Department";

  static Label_Medical_Condition = "Medical Condition";
  static Label_Climate = "Climate";
  static Climate_tip =
    "Climate helps us calculate your expected water intake based on your current climate.";
  static activity_level_tip =
    "Activity level helps us calculate your expected water intake.";
  static Events_label = "Events";
  static Challenges_label = "Challenges";
  static Sessions_label = "Sessions";
  static Label_DOB = "Date of Birth";
  static Label_ContatNumber = "Contact Number";
  static Label_SessionName = "Session Name";
  static Label_SessionType = "Session Type";
  static Label_FacultyName = "Faculty Name";
  static Label_MeetingLink = "Meeting Links";
  static Label_StartDate = "Start Date";
  static Label_EndDate = "End Date";
  static Label_City = "City";
  static Label_State = "State";
  static Label_Postal = "Postal(Zip/Pincode)";
  static LAbel_State = "State";
  static Label_Country = "Country";
  static Label_FitnessGroup = "Fitness Group";
  static Label_RunnerGroup = "Runner Group";
  static Label_AgeGroup = "Age Group";
  static Label_Corporate = "Corporate Name";
  static Label_Email = "Email";
  static Label_Mobile = "Mobile";
  static Label_Password = "Password";
  static Label_ConfirmPassword = "Confirm Password";
  static Label_Event = "Event";
  static Label_FreedemToFiltness = "Freedom2Fitness";
  static Label_EventCategory = "Event Category";
  static Label_EventType = "Event Type";
  static Blood_Group_Label = "Blood Group";
  static Activity_type_label = "Activity Types";
  static Secondary_Activity_type_label = "Secondary Activity Type";
  static ParticipationLabel = "Physical/Virtual";
  static Notification_Mode_label = "Communication  Type";

  static Label_PrimaryActivity = "Primary Activity";
  static Label_SecondaryActivity = "Secondary Activity";
  static Label_Desciption = "Description";
  static Label_Next = "Next";
  static Label_Register = "Register";
  static Label_Modify = "Modify";
  static Label_EventDetails = "Event Details";
  static Label_RegisterProgram = "Register Program";
  static Label_ProgramDashboard = "Program Dashboard";

  static Label_VideoGallery =
    "Largest online video album of people riding bicycles";
  static Label_WellnessPoints = "Wellness Points";
  static Label_Steps = "Steps";
  static Label_ActivityPoints = "Activity Points";
  static Label_HabitPoints = "Habit Points";
  static Label_BonusPoints = "Bonus Points";
  static Label_ParticipantDetails = "Participant Details";
  static Label_EventsList = "Events List";
  static Label_EditProfile = "Edit Profile";
  static Label_Profile_AddAppOrDevice = "Add App/Device";
  static Label_DataPermission = "Data Permission";
  static Label_Invite = "Invitations";
  static Label_IncrementDistance = "Increment daily distance by";
  static Date_label = "Date";
  static Category_label = "Category";
  static Event_Date = "Dates";
  static BIB_label = "BIB";
  static Running_Group_label = "Runner Group";
  static AveragePace_label = "Average Pace";
  static AverageSpeed_label = "Average Speed";
  static CompletionTime_label = "Time Taken";
  static Certificate_label = "Certificate";
  static FitnessReport_label = "Fitness Report";
  static NearbyRunners_label = "Nearby Runners";
  static NearbyRiders_label = "Nearby Riders";
  static NearbyRoutes_label = "Nearby Running Routes";
  static NearbyRider_routes_label = "Nearby Rider Routes";
  static RunnerCollage_label = "Runner Collage";
  static Video_label = "Video";
  static Upload_Activity_label = "Upload Activity";
  static Activity_Date_Time = "Date & Time";
  static Add_photo_label = "Add Photos";
  static Upload_photo_label = "Upload Photo";
  static Add_video_label = "Add video";
  static Upload_Activity_Validation_label =
    "Please share either Activity URL or Activity Screenshot.";
  static File_size = "Size";
  static File_type = "File Type";
  static Profile = "Profile";
  static Label_passwordvalidationText = "Minimum 6 Char"; //, 1 Capital, 1 Number, 1 Special char";
  static Label_InvalidCredentials = "Invalid Credentials ";
  static Label_Unauthorized = "User not authorized.";
  static Label_OperationFailed = " Failed to perform operation ";
  static Label_AccountNotExists = "Account does not exists";
  static Label_AccountNotActivated = "Account not activated";
  static Invalid_password_reset = "Invalid Password reset link";
  static Error_password_reset =
    "Error in password reset, please contact Necessary Devil team";
  static ResetPass_Message = "Please set new password for your account";
  static Label_NewPassword = "New Password";
  static Label_ConfirmNewPassword = "Confirm New Password";
  static Label_Change = "Change";
  static Label_NDVideo = "ND Video";

  static Aapply_btnLabel = "Apply";

  static CompletionTimeValidationLabel =
    "Please enter your activity completion time";
  static DistanceValidationLabel = "Please enter your activity distance";
  static DistanceNonZeroValidationLabel =
    "Please enter non-zero distance value";

  static ElevationValidationLabel = "Please enter your activity elevation gain";
  static CaloriesValidationLabel = "Please enter your calories";
  static StepsValidationLabel = "Please enter steps";

  static Page_Title_ResetPassword = "Reset Password | Necessary Devil";
  static Page_Title_Login = "Login | Necessary Devil";
  static Page_Title_Signup = "Sign-up | Necessary Devil";
  static Page_Title_Profile = "Profile | Necessary Devil";
  static Page_Title_Edit_Profile = "Edit Profile | Necessary Devil";
  static Page_Title_Activity_Sync = "Activity Sync | Necessary Devil";
  static Page_Title_Event_Registration = "Event-Registration | Necessary Devil";
  static Page_Title_Event_List = "Event-List | Necessary Devil";
  static Page_Title_Event_Details = "Activities | Necessary Devil";
  static Page_Title_Event_Support_list = "Support-List | Necessary Devil";
  static Page_Title_Event_Support_Form = "Support-Form | Necessary Devil";
  static Page_Title_Event_UploadRun = "Event-Upload Run | Necessary Devil";
  static Page_Title_Invitation_Form = "Invitation | Necessary Devil";
  static Page_Title_Invitation_List = "Invites | Necessary Devil";
  static Page_Title_Participant_Details =
    "Participant-Details | Necessary Devil";
  static Page_Title_VideoPlayer = "ND Video | Necessary Devil";
  static Page_Title_Summary = "Summary | Necessary Devil";
  static Session_Expired = "Session Expired. Please login";

  static Something_went_wrong =
    "Failed to perform operation. Please try again after some time.";
  //new Details

  static Label_EventDetails_Bookings_Fee = "Onwards";
  static Label_Only = "Only";
  static Label_EventDetails_Bookings_Inclu = "*Inclusive of Taxes";
  static Label_EventDetails_Bookings_BtnOpen = "Register Now";
  static Label_Go_To_Challenge = "Go to Challenge";
  static Label_Add_Challenge = "Add Challenge";
  static Label_EventDetails_Bookings_BtnClosed = "Registration Closed";

  static Label_EventDetails_EventInfo_Title = "EVENT INFORMATION";
  static Label_EventDetails_Program_challenges_title = "PROGRAM CHALLENGES";
  static Label_EventDetails_Program_events_title = "PROGRAM EVENTS";
  static Label_EventDetails_Program_sessions_title = "PROGRAM SESSIONS";
  static Label_ProgramDetails_ProgramInfo_Title = "PROGRAM INFORMATION";
  static Label_EventDetails_EventInfo_Run = "Run Categories";
  static Label_EventDetails_EventInfo_Cycle = "Cycle Categories";
  static Label_EventDetails_EventInfo_Guide = "Guidelines:";
  static Label_Program_Challenge_Id = "Program Challenge Name/Id";

  static Label_EventDetails_EventInfo_Hash = "#marathon";
  static Label_EventDetails_EventInfo_Read = "Read more";
  static Label_ReadMore = " more";

  static Label_EventDetails_Organizer = "ORGANIZER";
  static Label_EventDetails_Sponsor = "SPONSORS";
  static Label_EventDetails_Free = "Free Event";

  //view results

  static Label_Results = "Results";
  static Label_Searchbar = "Name/BIB No";
  static Label_Search = "Search";
  static Lable_Go = "Go";
  static Label_ResultTiming = "Timing";
  static Label_ResultRank = "Rank";
  static Label_ResultRaceDetails = "Race Details";
  static FinishedTime_Label = "Finished Time";
  static Interval_Label = "Interval";
  static DownloadCertificate_label = "Download Certificate";
  static DownloadVideo_label = "Download Video";
  static ShareResults_label = "Share Results";
  static MatData_label = "Timing Mat Data";

  static ImportParticipantsLabel = "Import Participants";
  static ImportFailedLabel = "Import failed for below Emails";

  static ImportResultsLabel = "Import Results";
  static ImportBibMappingLabel = "Import Bib";
  //table of support Files
  static Name_label = "Name";
  static Distance_label = "Distance";
  static Time_label = "Time";
  static Activity_label = "Activity";
  static Screenshot_label = "Screenshot";
  static Mannual_label = "Manual";
  static Consent_label = "Consent";
  static ActivityUrl_label = "Activity URL";
  static ScreenshotUrl_label = "Screenshot URL";
  static ActivityType_label = "Activity type";
  static CooperDistance_label = "Cooper Distance";
  static CooperRemark_label = "Cooper Remark";
  static StartTime_label = "Start Time";
  static PaceAvg = "Pace (Avg)";
  static SpeedAvg = "Speed (Avg)";
  static ProfilePicture_label = "Profile Picture";
  static HRAvg = "Avg HR";
  static StartLocation_label = "Start Location";
  static Elevation_label = "Elevation (Gain)";
  static ElevationLoss_label = "Elevation (Loss)";
  static ElevationHigh_label = "Elevation (High)";
  static ElevationLow_label = "Elevation (Low)";
  static Humidity = "Humidity";
  static AvgTemp = "Avg Temp";
  static ActivityScreenshot_label = "Activity";
  static PersonalData_label = "Personal Details";
  static ActivitySync_label = "Activity Sync";
  static ActivitySync_Instruction =
    "Configure Device/App which will sync activities with Necessary Devil.";
  static ActivitySync_Guideline =
    "You have not configured device/app which will sync activities with Necessary Devil. Click Continue to configure it now.";
  static Height_label = "Height";
  static Weight_label = "Weight";
  static Label_Unit_kg = "(kg)";
  static Label_unit_cm = "(cm)";
  static BMIlabel = "BMI";
  static Label_Specify_Condition = "Specify Condition";
  static Label_Select_Size = "Select Size:-";
  static Label_From = "From:";
  static Label_To = "To:";
  static Label_Total = "Total";
  static HH_Label = "HH";
  static MM_Label = "MM";
  static SS_Label = "SS";
  static Label_startDate = "Start Date";
  static Label_activityDate = "Activity Date";
  static Label_ActivityScreenshot = "Activity Screenshot";
  static Label_PostRunPicture = "Post Activity Picture";
  static Label_PostRunPictures = "Post Activity Pictures";
  static Label_PostActivityVideo = "Post Activity Video";
  static SuggestedWellnessPoints = "Suggested Points";
  static SuggestedDistance = "Suggested Distance";
  static SuggestedSteps = "Suggested Steps";
  static SuggestedPace_Label = " Suggested Pace";
  static Label_URL = " URL";
  static Label_View = "View";
  static personal_best_Label = "Please enter personal best";
  static personal_best_title_label = "Personal Best";
  static run_label = "Run";
  static cycle_label = "Cycle";
  static profilePicture_label = "Profile picture";
  static Upload_Activity_InvalidUrl_label = "Please provide valid URL";
  static Upload_Activity_Invalid_Activity_Date =
    "Activity date is not within the Event dates";
  static Activity_Duration = "Duration";
  static Upload_Activity_InvalidDate = "Please select date within event Dates";
  static Upload_Activity_Failed = "Failed to upload Activity";
  static InvalidStartTime = "Please select activity start time";

  static Total_Activity_Distance_Label = "Total Distance ";
  static Total_Activity_Time_Label = "Total Time ";
  static Total_Activity_Calories_Label = "Total Calories ";
  static Total_Activity_Steps_Label = "Total Steps ";

  static Total_Points_Label = "Total Points";
  static Total_Sustained_Label = "Total Sustained";
  static Total_Not_Sustained_Label = "Total Not Sustained";
  static Total_Not_Responded_Label = "Total Not Responded";

  static Photo_Delete_Success_Label = "Post Run Image deleted successfully ";

  static show_Public_Event_Label =
    "I would like to see future events from Necessary Devil.";
  static shipping_Address_Label = "Shipping Address";
  static age_Group = "Age Group";
  static corporate_Group = "Corporate Group";
  static address_label = "Address";
  static additional_Details_Label = "Additional Details";

  static loginWithGoogle = "Log in with Google";
  static loginWithGoogleFailed = "Failed to sign in using Google.";
  static loginUsingOTP = "Login using OTP";
  static login_label = "Login";
  static alreadyRegisteredEmail =
    "This email is already registered. please login";
  static Total_Amount = "Total Amount";

  static label_RelationWithParticipant = "Relation with participant";
  static Label_age = "Age";
  static Label_Gender = "Gender";
  static Label_TShirtSize = "T-Shirt Size";
  static Label_Medal = "Medal";
  static Label_AcknowledgementID = "Acknowledgement ID";
  static Label_PayNow = "PAY NOW";

  static Label_InvitationFormPage = "Invitation";
  static Label_InvitationListPage = "Invite People";
  static Label_Male = "Male";
  static Label_Female = "Female";
  static Label_Other = "Other";
  static Show_no_invitation_message = "No invitations.";
  static Max_invitation_message = "Max five invites are allowed.";
  static RegistrationsClosed_message = "Registrations closed";
  static Label_PaymentWarning =
    "Note: Registration will not be confirmed until payment is done.";
  static Label_EventPrice = "Event Price";
  static Label_ItemPrice = "Item Price";
  static Label_InvitePeople = "Invite People";
  static Label_ShareEvent = "Share Event";
  static Label_Feedback = "Feedback";
  static Label_Training = "Training";
  static Label_NextDayPlan = " Planned";
  static Label_Actual = "Actual";
  static NoTrainingPlanAvailable = "No Training Plan Available";
  static TrainingPlanLoading = "Training Plan is loading";

  //organizer
  static PersonalDataLabel = "Personal";
  static TeamDataLabel = "Team";
  static RegistrationDataLabel = "Participation Info";
  static EventItemDataLabel = "Event Items";
  static PaymentInformationLabel = "Payment";
  static AttachmentsDataLabel = "Attachments";
  static EmailDataLabel = "Resend Email";
  static ActivityDataLabel = "Activities";
  static ImagesLabel = "Results";
  static GoodiesLabel = "Goodies";
  static CaloriesLabel = "Calories";
  static StepsLabel = "Steps";
  static OnlineLabel = "Online";
  static OfflineLabel = "Offline";
  static paymentIdLabel = "Payment ID";
  static paymentStatusLabel = "Payment Status";
  static itemNameLabel = "Item Name";
  static itemQuantityLabel = "Item Quantity";
  static itemAmountLabel = "Amount Paid for Items";

  static RegistrationTypeLabel = "Registration Type";
  static RegistrationDateLabel = "Registration Date";
  static TeamNameLabel = "Team Name";

  static TeamLeaderName = "Team Leader";
  static TeamMembersLabel = "Team Members";
  static SelectParticipantMsgForLeaderboard =
    "Please select the participant from the list";
  static FetchFromLabel = "Source";

  //setup
  static CreateEventLabel = "+ Create Event";
  static BasicTabLabel = "Basic";
  static SettingsTabLabel = "Settings";
  static DeliverableTabLabel = "Certificates";
  static ImagesTabLabel = "Images";

  //Job Sync
  static JobHistoryLabel = "Job History";
  static JobSyncLabel = "Sync Job";

  static CreateEvent_CancelClickWarning =
    "Your unsaved changes will be lost, Do you still want to continue?";

  static ModifyChallenege_CancelClickWarning =
    "Your unsaved changes will be lost, Do you still want to continue?";
  //help texts
  static EventScopeHelpText =
    "Public events are visible to all the audience where in for Private events, user has to login using event specific link";
  static EventStartsFromHelpText =
    "Participant can perform the activity during event start time and event end time";
  static RegistrationEndDateHelpText =
    "Registrations will be closed after this date/time";
  static EventCloseDateHelpText =
    "Participant can upload the activity details before this date/time";
  static SupportedDomianHelpText =
    "Registrations will be restricted to the added email domains only, by default all email domains can register. Domains can be added like gmail.com, yahoo.com so on. Domains shall be comma separated value or simply enter after each domain";
  static ImageHelpText =
    "Image size should be within 980px*580px-1580px*1180px";
  static SecondaryActivityInfoText =
    "In case you could not complete your primary activity anyday, you can also do activity of secondary type, and we will fetch from your integrated app if primary activity data not available";

  //validation texts
  static EventStartDateValidationMsg =
    "Event start date cannot be after event end date";
  static EventEndDateValidationMsg =
    "Event end date cannot be before event start date";
  static RegistrationEndDateValidationMsg =
    " Registration end date shall be before event end date.";
  static ActivityUploadDateValidationMsg =
    "Activity upload end date cannot be before event end date";

  // challenge Registration

  static ChallengeDurationHelpText =
    "You can keep the challenge duration based on your comfort. You have to run daily for the selected time period.";
  static ChallengeStartDateHelpText =
    "Select the start date which is suitable for you. You can start Today, Tomorrow or any day suitable for you.";
  static DailyMinHelpText =
    "Min run distance for eligibility is 2 Km, you can always do more than this.";
  static TotalTargetHelpText =
    "Set any distance and try to achieve the targeted goal. The sum of the total distance of all the runs will be calculated for Total Target at the end of the challenge.";
  static ChallengeParticipantNotRegister =
    "You are not registered for this challenge.";
  static ChallengeDurationHelpTextOpenProgram =
    "You can keep the challenge duration based on your comfort.";
  // static

  static EventNameLabel = "Event Name";
  static EventScopeLabel = "Event Visibility";
  static EventDateTimeLabel = "Event Starts From";
  static EventEndDateTimeLabel = "Event Ends on"; //
  static DescriptionLabel = "Event Summary";
  static LongDescriptionLabel = "Event Guidelines";
  static RegistrationEndDateLabel = "Registration Ends On";
  static EventTypeLabel = "Event Type";
  static ChallengeTypeLabel = "Challenge Type";
  static EventParticipationLabel = "Participation Type";
  static EventCloseDateLabel = "Activity Upload Ends On"; // event end date
  static EventStateLabel = "Event State";
  static CategoriesLabel = "Categories";
  static ActivityNameLabel = "Activity Name";
  static EventCategoriesLabel = "Event Categories";
  static Label_AllEvents = "All Events";
  static EventItemsLabel = "Event Items";
  static ItemTypeLabel = "Item Type";
  static EventOrganiserLabel = "Event Organiser";
  static ToleranceDistanceLabel = "Tolerance Distance";
  static ItemsLabel = "Merchandise";

  // Challenge
  static ChallengeNameLabel = "Challenge Name";
  static challengeStartDateLabel = "Challenge Start On";
  static challengeEndDateLabel = "Challenge Ends On";
  static ChallengeSummaryLabel = "Challenge Summary";
  static ChallengeLongDescriptionLabel = "Challenge Guidelines";
  static ChallengeTypeLabel = "Challenge Type";
  static ChallengeDuration = "Challenge Duration";
  static ChallengeStartDate = "Challenge Start Date";
  // product configuration
  static productNameLabel = "Product Name";
  static selectTipLabel = "Select Tip";
  static UploadImageLabel = "Product Image";
  static VendorSiteUrlLabel = "Vendor Site URL";
  static ClickHereLabel = "Click Here";
  static productDescriptionLabel = "Product Description";
  static productAdvantageLabel = "Product Advantage";
  static productDeleteWarnLabel = "Are you sure want to delete this product?";
  static productDeleteabel = "Product deleted successfully !!";

  static productNamePlaceholder = "Enter product name";
  static selectTipPlaceholder = "Select Tip";
  static UploadImagePlaceholder = "Product Image";
  static VendorSiteUrlPlaceholder = "Enter URL";

  static SelectActivityToDeleteLabel = "Please select an activity to delete";
  static AllowActivityUploadLabel = "Allow Manual Upload";
  static SendInvitationLabel = "Send Invitation";
  static MaxInviteLabel = "Max Invites";
  static ValidateActivityDistanceLabel = "Validate Activity Distance";
  static AllowReferral = "Allow Referral";
  static AllowVolunteerParticipation = "Allow Volunteer Participation";
  static AnalyticsGenerated = "Analytics Generated";
  static AttendanceEvent = "Attendance Event";
  static AutoDeliverGoodies = "Auto Deliver Goodies";
  static computeWellnessPoint = "Compute Wellness Point";
  static enableTraining = "Enable Training";
  static hasQRCode = "Has QR Code";
  static hideTeamGraph = "Hide Team Graph";
  static registerUsingEmailOnly = "Register Using Email Only";
  static shareNDLink = "Share ND Link";
  static showActTypeOnRegistration = "Show ActType On Registration";
  static showBriefTextOnRegistration = "Show Brief Text On Registration";
  static showCategoryOnRegistration = "Show Category On Registration";
  static showCooperTestAction = "Show Cooper Test Action";
  static showDetailsOnQRScan = "Show Details On QRScan";
  static showEventName = "Show Event Name";
  static showHeightWeightOnSignup = "Show Height Weight On Signup";
  static showNDLogo = "Show ND Logo";
  static showPoweredBy = "Show Powered By";
  static showSearchBarOnResultsView = "Show Search Bar On Results View";
  static showSearchResultOnResults = "Show Search Result On Results";
  static showSuggestionOnRegisterEvent = "Show Suggestion On Register Event";
  static skipManadatorySignupFields = "Skip Manadatory Signup Fields";
  static stopActivityEmailNotiication = "Stop Activity Email Notiication";
  static stopActivitySMSNotiication = "Stop Activity SMS Notiication";
  static stopBIBEmailNotiication = "Stop BIB Email Notiication";
  static stopBIBSMSNotiication = "Stop BIB SMS Notiication";
  static stopCertificateEmailNotiication = "Stop Certificate SMS Notiication";
  static stopCertificateSMSNotiication = "Stop Certificate SMS Notiication";
  static stopRegistrationEmailNotiication =
    "Stop Registration Email Notiication";
  static stopRegistrationSMSNotiication = "Stop Registration SMS Notiication";
  static stopVideoEmailNotiication = "Stop Video Email Notiication";
  static uploadRegisteredActType = "Upload Registered ActType";
  static TimeValidationLabel = "Time Validation";
  static ActivityAutoApproveLabel = "Activity Auto Approve";
  static CustomSectionLabel = "Custom Section Label";

  static DeliverableItemLabel = "Deliverable Items";
  static signAuthorityNameLabel = "Signature Authority Name";
  static signAuthorityDesignationLabel = "Signature Authority Designation";
  static CertificateBackgroundLabel = "Certificate Background";

  static DeleteActivityLabel = "Delete Activity";
  static UpdateActivityLabel = "Update Activity";
  static DeleteActivityWarning = "Are you sure to delete this activity?";
  static DeleteParticipantWarning = "Are you sure to delete this participant?";

  static DailyMinDistance = "Daily Minimum Distance: ";
  static TotalTargetDistance = "Total Target Distance: ";
  static DailyMinSteps = "Daily Minimum Steps: ";
  static TotalTargetSteps = "Total Target Steps: ";

  static DownloadLabel = "Download";
  static ResendLabel = "Resend";
  static DeleteLabel = "Delete";
  static CancelLabel = "Cancel";
  static SubmitLabel = "Submit";
  static CreateSessionLabel = "Add Session";

  static ActiveLabel = " (Active)";
  // Analytics
  static CompanyAnalyticsLabel = "Company Analytics";
  static TeamAnalyticsLabel = "Team Analytics";

  // Map
  static MapNotAvailable = "Map Not Available";
  static ParticipantLocationLabel = "Participant Locations";
  static MapRouteLabel = "Map Route";
  static program_dashboard = {
    title: { personal: "Personal Dashboard", corporate: "Corporate Dashboard" },
    chart_titles: {
      cooper_test: "Cooper's Test",
      knowledge_sessions: "Knowledge Sessions",
      engagement_meter: "Engagement Meter",
      no_sugar_challenge: "No Sugar Challenge",
    },
    legends: {
      cooper_test: "CT Score",
      sessions: {
        attended: "Attended Sessions",
        total: "Total Sessions",
      },
    },
  };

  static select_response_label = "Please select your response.";
  static video_finish_label =
    "Let the intro video finish before starting the challenge!";

  static start_day_label = "The Data can be updated by end of day.";
  static conversion_helper_text = "1 Glass = 240ml.";

  // Create program
  static ProgramNameLabel = "Program Name";
  static Program_description_label = "Program Description";
  static Program_long_description_label = "Program Guidelines";
  static Program_start_date_label = "Program Start Date";
  static Program_end_date_label = "Program End Date";
  static Registration_end_date_label = "Registration End Date";
  static Program_visibility_label = "Program Visibility";
  static Program_link_label = "Program Link(Dist Key)";
  static Departments_label = "Departments";
  static Bib_start_number_label = "BIB Start Number";

  static program_banner_label = "Program Banner";
  static Default_banner = "Default Banner";

  // email reminders section label
  static email_content_title = "Email Content";
  static email_reminder_section_title = "Email Reminder Section";
  static subject_label = "Subject";
  static Subject_placeholder = "Enter Subject";
  static content_label = "Content";

  static email_reminder_section_list = "Reminder content list";
  static program_challenge_type = "Challenge Type";

  //Record Response ImageComponent 
  static no_image_uploaded_url = "https://cdn.cdnparenting.com/articles/2024/01/18161637/Question-Mark-Uses-Examples-and-Rules-for-Kids.webp"
  static zero_sugar_image_info =
    "We’d love to see how you’re doing on the zero-sugar challenge! Share pictures of your sugar-free meals, new lifestyle habits, or any other progress you’ve made. Your journey can inspire others and help us all stay motivated. Let’s celebrate every small victory together and make this zero-sugar challenge a journey we all remember!";
  static hydration_image_info  = 'Testing version'
}
export default Properties;
