import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { isBrowser } from "react-device-detect";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";

import { BiSolidEditAlt } from "react-icons/bi";

import { CustomTextField } from "../../../../../../common/CustomTextField";
import MultiSelect from "../../../../../../common/MultiSelect";
import NewTextEditor from "../../../../../../common/NewTextEditor";
import SubmitCancelBtn from "../../../../../../common/SubmitCancelBtn";
import DialogBox from "../../../../../../common/DialogBox";
import Properties from "../../../../../../../properties/Properties";
import { FileUploader } from "../../../../../../common/FileUploader";
import ApplicationConstants from "../../../../../../../constants/ApplicationConstants";
import Snackbar from "../../../../../../common/Snackbar";
import parse from "html-react-parser";
import {
  createProduct,
  deleteProduct,
  getAllProducts,
  getAllTipsUnderChallenge,
  updateProduct,
} from "../../../../../../../apis/eventService";
import InlineDivider from "../../../../../../common/InlineDivider";
import ClearIcon from "@material-ui/icons/Clear";
import httpService from "../../../../../../../apis/httpService";
import QuestionCreationComponent from "./QuestionCreationComponent";
import TipCreationComponent from "./TipCreationComponent";
import zIndex from "@material-ui/core/styles/zIndex";
import TipsSection from "./TipsSection";

import EmailContent from "./EmailContent/EmailContent";
const API_URL_FILE = httpService.API_URL();

const ProductConfigurationView = ({ classes }) => {
  const myRef = useRef(null);

  const emailContentRef = useRef()
  const [isPastChallenge, setIsPastChallenge] = useState(false);
  const [productState, setProductState] = useState({
    name: "",
    nameError: false,
    tipsData: [],
    productImageFile: "",
    file: "",
    productImageFileError: false,
    tipValue: "",
    VendorSiteURL: "",
    vendorSiteUrlError: false,
    description: "",
    advantage: "",
    advantageError: false,
    descriptionError: false,
    longDescription: "",
    htmlDescription: "",
    descriptionDialog: false,
    longDescriptionDialog: false,
    htmlLongDescription: "",
    showCancelDialog: false,
    updatedRecord: {},
    isRender: false,
    availableProducts: [],
    anchorEl: null,
    deleteProductWarningOpen: false,
    inEditMode: false,
  });

  let {
    anchorEl,
    name,
    nameError,
    tipsData,
    VendorSiteURL,
    file,
    productImageFileError,
    vendorSiteUrlError,
    description,
    tipValue,
    descriptionError,
    advantage,
    advantageError,
    productImageFile,
    longDescription,
    htmlDescription,
    descriptionDialog,
    longDescriptionDialog,
    htmlLongDescription,
    showCancelDialog,
    availableProducts,
    updatedRecord,
    isRender,
    deleteProductWarningOpen,
    inEditMode,
  } = productState;

  const getDialogMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.CreateEvent_CancelClickWarning}</span>
        </Typography>
      </div>
    );
  };

  const closeDialogBox = () => {
    setProductState((prev) => ({
      ...prev,

      //reset the previous errors on cancel
      nameError: false,
      productImageFileError: false,
      descriptionError: false,
      advantageError: false,
      vendorSiteUrlError: false,

      isRender: true,
      updatedRecord: {},
      name: "",
      description: "",
      htmlDescription: "",
      htmlLongDescription: "",
      advantage: "",
      VendorSiteURL: "",
      productImageFile: "",
      tipValue: {},
      showCancelDialog: false,
      inEditMode: false,
    }));
  };

  const editDescription = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={description ? description : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            setProductState((prev) => ({
              ...prev,
              description: formattedValue,
              descriptionError: false,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              setProductState((prev) => ({
                ...prev,
                descriptionDialog: false,
                htmlDescription: description,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const editAdvantage = () => {
    return (
      <div
        style={{
          width: "80%",
          height: "auto",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        <NewTextEditor
          htmlText={advantage ? advantage : ""}
          getEditedValue={(value) => {
            let formattedValue = value === "<p></p>" ? null : value.toString();

            setProductState((prev) => ({
              ...prev,
              advantage: formattedValue,
              advantageError: false,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SubmitCancelBtn
            type="submit"
            label={"Submit"}
            onClick={() => {
              setProductState((prev) => ({
                ...prev,
                longDescriptionDialog: false,
                htmlLongDescription: advantage,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const handleUploadedFiles = (arg) => {
    let files = arg.selectedFiles?.[0]; //?.[0].src;
    let row = arg.selectedRow;
    let imgsrc = URL.createObjectURL(files);

    setProductState((prev) => ({
      ...prev,
      productImageFile: imgsrc,
      productImageFileError: false,
      file: files,
    }));
    // setProductState(())
  };

  const validateFields = () => {
    if (name === "" || nameError === true) {
      setProductState((prev) => ({ ...prev, nameError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter product name.",
      });
      myRef.current.scrollIntoView();
    } else if (productImageFile === "" || productImageFileError === true) {
      setProductState((prev) => ({ ...prev, productImageFileError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please set the product image",
      });
      myRef.current.scrollIntoView();
    } else if (
      description === "" ||
      description === "<p></p>\n" ||
      descriptionError === true
    ) {
      setProductState((prev) => ({ ...prev, descriptionError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter product description",
      });
      myRef.current.scrollIntoView();
    } else if (
      advantage === "" ||
      advantage === "<p></p>\n" ||
      advantageError === true
    ) {
      setProductState((prev) => ({ ...prev, advantageError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter product advantage.",
      });
      myRef.current.scrollIntoView();
    } else if (VendorSiteURL === "" || vendorSiteUrlError === true) {
      setProductState((prev) => ({ ...prev, vendorSiteUrlError: true }));
      Snackbar.show({
        variant: "error",
        isAutoHide: true,
        preventDuplicate: true,
        message: "Please enter vendor site URL",
      });
      myRef.current.scrollIntoView();
    } else {
      if (inEditMode) {
        UpdateRecordHandler();
      } else {
        let paramsData = {
          productName: name,
          description: description || "",
          advantages: advantage || "",
          vendorSiteLink: VendorSiteURL || "",
          tipId: tipValue?.id,
        };

        createProduct({ data: paramsData, productImage: file }).then((Res) => {
          setProductState((prev) => ({
            ...prev,
            isRender: true,
            updatedRecord: {},
            name: "",
            description: "",
            htmlDescription: "",
            htmlLongDescription: "",
            advantage: "",
            VendorSiteURL: "",
            productImageFile: "",
            tipValue: {},
            inEditMode: false,
          }));
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Product created successfully",
          });
        });
      }
    }
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  /**
   * vishal-
   * Final API call for delete the product
   */
  const handleDeleteProduct = () => {
    const { id } = updatedRecord;
    if (id) {
      deleteProduct(id).then((res) => {
        if (res?.data?.code === "200") {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.productDeleteabel,
          });

          setProductState((prev) => ({
            ...prev,
            isRender: true,
            updatedRecord: {},
            inEditMode: false,
          }));
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
      });
    }
    closeDeleteProductWarning();
  };

  /**
   * header message of product delete dialog
   * @returns
   */
  const getDeleteProductWarnMsg = () => {
    return (
      <div>
        <Typography style={{ padding: "10px" }}>
          <span>{Properties.productDeleteWarnLabel}</span>
        </Typography>
      </div>
    );
  };

  /**
   * vishal
   * for closing the Product Delete warning
   */
  const closeDeleteProductWarning = () => {
    setProductState((prev) => ({
      ...prev,
      anchorEl: null,
      deleteProductWarningOpen: false,
    }));
  };

  useEffect(() => {
    let setPermissions = JSON.parse(localStorage.getItem("supportPermissions"));
    setIsPastChallenge(!setPermissions?.canModifyProgram);
    getAllTipsUnderChallenge().then((res) => {
      if (res?.data && res?.data?.length) {
        setProductState((prev) => ({
          ...prev,
          tipsData: res?.data?.map((item) => {
            return { id: item.id, label: item.tip, tip: item.tip };
          }),
        }));
      }
    });
    getAllProducts().then((res) => {
      setProductState((prev) => ({
        ...prev,
        availableProducts: res?.data,
        isRender: false,
      }));
    });

   
  }, [isRender]);

  const editRecord = (dataItem) => {
    myRef.current.scrollIntoView();

    fetch(`${API_URL_FILE.replace(/\/$/, "")}${dataItem?.imageUrl}`)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "Product_Image_4.jpeg", {
          contentType: "image/jpeg",
        });
      })
      .catch((error) => console.error(error));

    if (dataItem) {
      // setting all predefined values in state
      setProductState((prev) => ({
        ...prev,

        //reset the previous errors on edit mode
        nameError: false,
        productImageFileError: false,
        descriptionError: false,
        advantageError: false,
        vendorSiteUrlError: false,

        updatedRecord: dataItem,
        name: dataItem.productName,
        description: dataItem.description,
        htmlDescription: dataItem.description,
        htmlLongDescription: dataItem.advantages,
        advantage: dataItem.advantages,
        VendorSiteURL: dataItem.vendorSiteLink,
        productImageFile: `${API_URL_FILE.replace(/\/$/, "")}${
          dataItem?.imageUrl
        }`,
        tipValue: {
          id: dataItem?.tipDtos?.[0]?.id,
          label: dataItem?.tipDtos?.[0]?.tip,
          tip: dataItem?.tipDtos?.[0]?.tip,
        },
      }));
    }
  };

  const UpdateRecordHandler = () => {
    if (updatedRecord) {
      let paramsData = {
        id: updatedRecord?.id,
        productName: name,
        description: description || "",
        advantages: advantage || "",
        vendorSiteLink: VendorSiteURL || "",
        tipId: tipValue?.id,
      };
      updateProduct({ data: paramsData, productImage: file }).then((res) => {
        if (res?.data?.code === "200") {
          setProductState({
            ...productState,
            isRender: true,
            updatedRecord: {},
            name: "",
            description: "",
            htmlDescription: "",
            htmlLongDescription: "",
            advantage: "",
            VendorSiteURL: "",
            productImageFile: "",
            tipValue: {},
            inEditMode: false,
          });
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Product updated successfully",
          });
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
      });
    }
  };
  return (
    <div>
      <QuestionCreationComponent isPastChallenge={isPastChallenge} />
      <div style={{ height: "1px", backgroundColor: "#9cb5c4" }}></div>

      <TipsSection isPastChallenge={isPastChallenge} />
      <div
        style={{ height: "1px", backgroundColor: "#9cb5c4", marginTop: "15px" }}
      ></div>

      <div
        style={{ height: "1px", backgroundColor: "#9cb5c4" }}
        ref={myRef} // user click on edit product then user scroll into this view
      ></div>

      <h3 className={classes.title} style={{ marginBottom: "8px" }}>
        Product Add:
      </h3>
      <div className="formWrapper">
        <Grid container spacing={1} style={{}}>
          {/* Name */}
          <Grid item xs={6}>
            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties?.productNameLabel}
                  <span className={classes.mandatoryCss}>*</span> : &nbsp;
                </span>
              </Typography>
              <CustomTextField
                className={
                  nameError ? classes?.fieldErrorCss : classes.fieldCss
                }
                type="text"
                placeholder={Properties?.productNamePlaceholder}
                value={name}
                handleChange={(prop) => (e) => {
                  setProductState((prev) => ({
                    ...prev,
                    name: e.target.value,
                    nameError: false,
                  }));
                }}
                readOnly={isPastChallenge}
              />
            </div>
            {/* Description */}
            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.productDescriptionLabel}
                  <span className={classes.mandatoryCss}>*</span> :
                </span>
              </Typography>

              <div
                style={{
                  width: "440px",
                  height: "115px",
                  borderRadius: "3px",
                  padding: "0 5px",
                  border: descriptionError
                    ? "1px solid red"
                    : "1px solid #08456C",
                  overflowY: "auto",
                }}
                onClick={() => {
                  setProductState((prev) => ({
                    ...prev,
                    descriptionDialog: isPastChallenge ? false : true,
                    htmlDescription: isPastChallenge ? htmlDescription : "",
                  }));
                }}
              >
                {htmlDescription && (
                  <NewTextEditor
                    readOnly
                    htmlText={htmlDescription}
                    propsText={htmlDescription}
                  />
                )}
              </div>
            </div>
            {/* Advantage */}
            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties.productAdvantageLabel}
                  <span className={classes.mandatoryCss}>*</span> :
                </span>
              </Typography>

              <div
                style={{
                  width: "440px",
                  height: "115px",
                  borderRadius: "3px",
                  padding: "0 5px",
                  border: advantageError
                    ? "1px solid red"
                    : "1px solid #08456C",
                  overflowY: "auto",
                }}
                onClick={() => {
                  setProductState((prev) => ({
                    ...prev,
                    longDescriptionDialog: isPastChallenge ? false : true,
                    htmlLongDescription: isPastChallenge
                      ? htmlLongDescription
                      : "",
                  }));
                }}
              >
                {htmlLongDescription && (
                  <NewTextEditor
                    readOnly
                    htmlText={htmlLongDescription}
                    propsText={htmlLongDescription}
                  />
                )}
              </div>
            </div>
          </Grid>

          {/* upload Images */}
          <Grid item xs={6}>
            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties?.UploadImageLabel}
                  <span className={classes.mandatoryCss}>*</span> : &nbsp;
                </span>
              </Typography>

              {productImageFile ? (
                <div className={classes.smallImgContainer}>
                  <div className={classes.clearImgContainer}>
                    <ClearIcon
                      style={{ color: "red", fontSize: "20px" }}
                      onClick={() =>
                        setProductState((prev) => ({
                          ...prev,
                          productImageFile: "",
                        }))
                      }
                    />
                  </div>

                  <div className={classes.smallProfileImgContainer}>
                    <img
                      className={classes.signImgCss}
                      src={productImageFile}
                      alt={"productfile"}
                      onClick={() => {
                        // this.setState({
                        //   dialogTitle: "Signature",
                        //   showImage: true,
                        //   imagePath: certificateSign_1.imageURL,
                        //   dialogTitle: certificateSign_1.label,
                        // });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <FileUploader
                  selectedRow={{
                    keyName: "Product_image",
                    imageURL: null,
                    label: "Product Banner",
                    width: "300",
                    height: "300", //w*h
                    exactSize: false,
                    acceptAllSizes: true,
                  }}
                  maxFiles={1}
                  multiple={false}
                  filesUploaded={(e) => handleUploadedFiles(e)}
                  isReadOnly={isPastChallenge}
                />
              )}
            </div>

            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {" "}
                  {Properties?.VendorSiteUrlLabel}
                  <span className={classes.mandatoryCss}>*</span> : &nbsp;
                </span>
              </Typography>
              <CustomTextField
                className={
                  vendorSiteUrlError ? classes.fieldErrorCss : classes.fieldCss
                }
                type="text"
                placeholder={Properties?.VendorSiteUrlPlaceholder}
                value={VendorSiteURL}
                handleChange={(prop) => (e) => {
                  setProductState((prev) => ({
                    ...prev,
                    VendorSiteURL: e.target.value,
                    vendorSiteUrlError: false,
                  }));
                }}
                readOnly={isPastChallenge}
              />
            </div>

            <div style={{ paddingBottom: "10px" }}>
              <Typography variant="body2" gutterBottom>
                <span className={classes.labelCss}>
                  {Properties?.selectTipLabel} :
                </span>
              </Typography>
              <div className={classes.dropdownCss}>
                {" "}
                <MultiSelect
                  value={tipValue}
                  placeholder={Properties?.selectTipPlaceholder}
                  // error={categoryTypeError}
                  isMenuMaxHeight
                  height={"60px"}
                  options={tipsData}
                  onChange={(e) => {
                    setProductState((prev) => ({
                      ...prev,
                      tipValue: e,
                    }));
                  }}
                  isReadOnly={isPastChallenge}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {!isPastChallenge && (
          <Grid
            container
            style={{
              padding: "30px 0 40px 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={4}>
              <SubmitCancelBtn
                label={"Cancel"}
                onClick={() => {
                  setProductState((prev) => ({
                    ...prev,

                    showCancelDialog: true,
                  }));
                }}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>

            <Grid item xs={4}>
              <SubmitCancelBtn
                onClick={() => validateFields()}
                // disabled={currentChallenge ? true : false}
                label={inEditMode ? "Update" : "Submit"}
                type="submit"
                style={{ width: "200px" }}
              />
            </Grid>
          </Grid>
        )}

        <div>
          <InlineDivider style={{ height: "5px", backgroundColor: "#08456C" }}>
            Product List
          </InlineDivider>

          <div>
            <h3 className={classes.title} style={{ marginBottom: "8px" }}>
              Products List:
            </h3>
            <div
              style={{
                maxHeight: "300px",

                overflowY: "auto",
                // position: "relative",
              }}
            >
              <Table stickyHeader style={{ minHeight: "300px" }}>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {!isPastChallenge && (
                      <TableCell component="th" textAlign="center"></TableCell>
                    )}
                    <TableCell component="th" textAlign="center">
                      Name
                    </TableCell>
                    <TableCell component="th" textAlign="center">
                      Description
                    </TableCell>
                    <TableCell component="th" textAlign="center">
                      Advantage
                    </TableCell>{" "}
                    <TableCell component="th">
                      {Properties.VendorSiteUrlLabel}
                    </TableCell>
                    <TableCell component="th">Assigned Tip</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {availableProducts &&
                    availableProducts?.length > 0 &&
                    availableProducts?.map((product, index) => {
                      let {
                        id,
                        productName,
                        imageUrl,
                        description,
                        advantages,
                        vendorSiteLink,
                      } = product;

                      return (
                        <TableRow key={id}>
                          {!isPastChallenge && (
                            <TableCell
                              textAlign="center"
                              style={{ verticalAlign: "baseline" }}
                            >
                              <IconButton>
                                <ArrowDropDownCircleOutlinedIcon
                                  className={classes.editIcon}
                                  onClick={(e) => {
                                    setProductState((prev) => ({
                                      ...prev,
                                      anchorEl: e.currentTarget,
                                      updatedRecord: product,
                                    }));
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          )}
                          <TableCell
                            textAlign="center"
                            style={{ verticalAlign: "baseline" }}
                          >
                            {productName}
                          </TableCell>
                          <TableCell
                            component="td"
                            textAlign="center"
                            style={{ verticalAlign: "baseline" }}
                          >
                            {description ? parse(description) : ""}
                          </TableCell>
                          <TableCell
                            component="td"
                            textAlign="center"
                            style={{ verticalAlign: "baseline" }}
                          >
                            {advantages ? parse(advantages) : ""}
                          </TableCell>
                          <TableCell
                            textAlign="center"
                            style={{ verticalAlign: "baseline" }}
                          >
                            {vendorSiteLink ? (
                              <span>
                                <a href={vendorSiteLink} target="_blank">
                                  {Properties.ClickHereLabel}
                                </a>
                              </span>
                            ) : (
                              "--"
                            )}
                          </TableCell>
                          <TableCell
                            textAlign="center"
                            style={{ verticalAlign: "baseline" }}
                          >
                            { product?.tipDtos ? parse(`${product?.tipDtos?.[0]?.tip || "--"}`) : ""}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>


        <div style={{marginBottom:"20px"}} ref={emailContentRef}>
          <InlineDivider style={{ height: "5px", backgroundColor: "#08456C" }}>
            {Properties.email_content_title}
          </InlineDivider>

        <EmailContent emailContentRef={emailContentRef} />

        </div>

        {/* cancel dialog */}
        <DialogBox
          signup={true}
          open={showCancelDialog}
          message={getDialogMsg()}
          header={""}
          label={"No"}
          onClick={() => {
            setProductState((prev) => ({
              ...prev,

              showCancelDialog: false,
            }));
          }}
          labelTwo={"Yes"}
          onClickTwo={() => {
            closeDialogBox();
          }}
          closeDisloag={() => {}}
        />

        {/* description dialog */}
        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={descriptionDialog}
          message={editDescription()}
          header={"Product Description"}
          onClick={() =>
            setProductState((prev) => ({
              ...prev,
              descriptionDialog: false,
              htmlDescription: description,
            }))
          }
          closeDisloag={() =>
            setProductState((prev) => ({
              ...prev,
              descriptionDialog: false,
              htmlDescription: description,
            }))
          }
        />

        {/* delete product warning */}
        <DialogBox
          signup={true}
          open={deleteProductWarningOpen}
          message={getDeleteProductWarnMsg()}
          // header={Properties.productDeleteWarnLabel}
          label={"No"}
          isSmall={true}
          onClick={closeDeleteProductWarning}
          labelTwo={"Yes"}
          onClickTwo={() => {
            handleDeleteProduct();
          }}
          closeDisloag={closeDeleteProductWarning}
        />

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() =>
            setProductState((prev) => ({ ...prev, anchorEl: null }))
          }
          // className="header__user--menu"
          style={{ marginTop: "40px" }}
        >
          <MenuItem
            onClick={() => {
              setProductState((prev) => ({
                ...prev,
                anchorEl: null,
                inEditMode: true,
              }));
              editRecord(updatedRecord);
            }}
          >
            Modify Product
          </MenuItem>
          <MenuItem
            onClick={() => {
              setProductState((prev) => ({
                ...prev,
                anchorEl: null,
                deleteProductWarningOpen: true,
              }));
            }}
          >
            Delete Product
          </MenuItem>
        </Menu>

        {/* advantage */}
        <DialogBox
          fullWidth
          maxWidth={"md"}
          open={longDescriptionDialog}
          message={editAdvantage()}
          header={"Product Advantage"}
          onClick={() =>
            setProductState((prev) => ({
              ...prev,
              longDescriptionDialog: false,
              htmlLongDescription: advantage,
            }))
          }
          closeDisloag={() =>
            setProductState((prev) => ({
              ...prev,
              longDescriptionDialog: false,
              htmlLongDescription: advantage,
            }))
          }
        />
      </div>
    </div>
  );
};

const styles = {
  title: {
    color: "rgb(8, 69, 108)",
  },
  labelCss: {
    color: "#727272",
  },
  dropdownCss: {
    width: isBrowser ? "360px" : "160px",
    zIndex: 1,
  },
  mandatoryCss: { color: "red" },
  fieldCss: {
    border: "1px solid #08456C",
    height: "40px", //"45%",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  fieldErrorCss: {
    border: "1px solid red",
    height: "40px",
    maxHeight: "40px",
    minHeight: "40px",
    width: isBrowser ? "360px" : "160px",
  },
  tableHead: {
    backgroundColor: "#ced4da !important",
    "& th:first-child": {
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "30px",
    },
    "& th": {
      backgroundColor: "#ced4da",
      // color: "#495057",
      color: "#6c757d",
      fontFamily: "Rubik, sans-serif",
      fontSize: "16px",
      fontWeight: "bolder",
      textAlign: "center",
      borderRight: "1px solid #adb5bd",
      borderRadius: "2px",
      width: "100px",
    },
  },
  tableBody: {
    height: "300px",
    overflowY: "auto",
    "& tr td": {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // textAlign: "center",
    },
  },

  smallImgContainer: {
    position: "relative",
    width: "230px",
    height: "160px",
    textAlign: "center",
    padding: "10px 5px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  clearImgContainer: {
    position: "absolute",
    top: "1px",
    right: "1px",
  },
  smallProfileImgContainer: {
    width: "130px",
    height: "150px",
    margin: "auto",
  },
  signImgCss: {
    width: "100%",
    height: "inherit",
    objectFit: "contain",
    margin: "auto",
    cursor: "pointer",
    paddingTop: "10px",
  },
  editButton: {
    color: "#09456C",
    border: "none",
    fontSize: "20px",
    padding: "3px 7px",
    background: "#cedae2",
    border: "none",
    borderRadius: "6px",
    fontWeight: "600",
  },
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(ProductConfigurationView);
