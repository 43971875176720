import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { compose } from "recompose";
import { Button, Card, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import SubmitCancelBtn from "../../../../common/SubmitCancelBtn";

import { FaMedal } from "react-icons/fa";
import { CiMedal } from "react-icons/ci";
import { IoMedalSharp } from "react-icons/io5";

import {
  EmojiEvents,
  ErrorOutline,
  ThumbDown,
  ThumbUp,
} from "@material-ui/icons";
import {
  BronzeMedalIcon,
  GoldMedalIcon,
  SilverMedalIcon,
} from "../../../../common/SvgIcon";
import {
  ZerGoldBadge1stWeek,
  ZeroSilverBadge1stWeek,
  ZerBronzeBadge1stWeek,
} from "../../../../../images/Badges";
import DialogBox from "../../../../common/DialogBox";
import Snackbar from "../../../../common/Snackbar";
import { loading } from "../../../../common/Spinner";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const QuestionCardsCarousel = ({
  challengeType,
  QuestionData,
  classes,
  onClickAndFetchData,
  startDate,
  viewDate,
  ResponseSubmissionEndDate,
  perDayViewData,
}) => {
  const [selectedItem, setSelectedItem] = useState(); // Default to item index 2
  const [weekWiseData, setWeekWiseData] = useState([]);
  const [showMedal, setShowMedal] = useState([]); // Updated to an array
  const carouselRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [openDialogImage, setOpenDialogImage] = useState();

  const handleClick = (index) => {
    console.log(index);
  };

  useEffect(() => {
    // get index for set default index item
    let getIndex = QuestionData?.findIndex(
      (item) =>
        moment(
          item?.programChallengeQuestionDto?.viewDate,
          "DD-MM-YYYY HH:mm:ss"
        ).format("YYYY-MM-DD") === viewDate
    );

    getWeekWiseData();

    if (carouselRef.current && getIndex) {
      setSelectedItem(getIndex);
    }
  }, []);

  useEffect(() => {
    if (carouselRef.current && selectedItem > 1) {
      carouselRef.current.goToSlide(selectedItem);
    }
  }, [selectedItem]);

  const getWeekWiseData = () => {
    const sortedData = QuestionData?.sort((a, b) => {
      const dateA = moment(
        a.programChallengeQuestionDto?.viewDate,
        "DD-MM-YYYY HH:mm:ss"
      );
      const dateB = moment(
        b.programChallengeQuestionDto?.viewDate,
        "DD-MM-YYYY HH:mm:ss"
      );

      return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    });

    const weekWise = [];
    for (let i = 0; i < sortedData?.length; i += 7) {
      weekWise.push(sortedData?.slice(i, i + 7));
    }
    setWeekWiseData(weekWise);

    getWeeklyCountOfResponse(weekWise);
  };

  const badge = [
    {
      name: "Gold Badge",
      valueIcon: <ZerGoldBadge1stWeek />,
    },
    {
      name: "Silver Badge",
      valueIcon: <ZeroSilverBadge1stWeek />,
    },
    {
      name: "Bronze Badge",
      valueIcon: <ZerBronzeBadge1stWeek />,
    },
  ];

  const getWeeklyCountOfResponse = (weekWise) => {
    const weeklyCounts = weekWise.map((weekData) => {
      return weekData.reduce((count, item) => {
        if (item.responseStatus === "Positive" && item.photoURL) {
          count++;
        }
        return count;
      }, 0);
    });

    // gold medal (https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Arzwiki-medal-2019.png/640px-Arzwiki-medal-2019.png)
    // silver medal (https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Bertil_Norman%27s_silver_medal_from_Swedish_National_Orienteering_Championship_for_veterans_2014.jpg/640px-Bertil_Norman%27s_silver_medal_from_Swedish_National_Orienteering_Championship_for_veterans_2014.jpg)
    // bronze Medal (https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/NDSM_medal_with_Bronze_service_star.png/640px-NDSM_medal_with_Bronze_service_star.png)
    // let badge;

    const medalIcons = weeklyCounts.map((count) => {
      if (count >= 6 && count <= 7) {
        return badge[0]; // Gold Medal
      } else if (count >= 3 && count <= 5) {
        return badge[1]; // Silver Medal
      } else if (count > 0 && count < 3) {
        return badge[2]; // Bronze Medal
      } else {
        return null; // No Medal
      }
    });

    setShowMedal(medalIcons); // Set the medals for all weeks
  };

  const getDialogComponent = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          id="banner"
          className={classes.media}
          src={openDialogImage?.valueIcon?.type}
          height={200}
          alt={"Banner"}
          style={{ borderRadius: "20px" }}
        />
        {/* <GoldMedalIcon height="120px" width="auto" /> */}
     
        <Typography style={{ color: "#27272a" }}>
        Congratulations, You have received{" "}
          <span style={{ color: "#18181b", fontWeight: "600" }}>
            {openDialogImage?.name}.
          </span>{" "}
          
        </Typography>
      </div>
    );
  };

  const closeWindow = () => {
    setShowDialog(false);
  };

  const shareImage = async () => {
    // Set loading to true before starting the sharing process
    loading(true);

    try {
      if (!showMedal) {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: "Medal not received",
        });
      } else {
        // Fetch the image as a Blob
        const response = await fetch(openDialogImage?.valueIcon?.type);
        const imageBlob = await response.blob();

        // Prepare the file array
        const filesArray = [
          new File([imageBlob], "Badge.png", {
            type: "image/png",
            lastModified: new Date().getTime(),
          }),
        ];

        const shareData = {
          title: "Achieved Badge",
          text: "Exciting to share the badge and achieve more",
          // url: "https://khadakwaslaultra.com",
          files: filesArray,
        };

        // Attempt to share the image
        await navigator.share(shareData);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      loading(false);
    }
  };

  console.log(showMedal, "showmoda;");

  return (
    <>
      <Carousel
        responsive={responsive}
        centerMode={true}
        // draggable={true}
        onClickItem={handleClick}
        className={classes.thumbswrapper}
        // showStatus={false}
        initialSlide={selectedItem}
        rewind
        // arrows={true}
        // infinite={true}
        ref={carouselRef}
      >
        {QuestionData?.length > 0 &&
          QuestionData?.sort((a, b) =>
            moment(
              a.programChallengeQuestionDto?.viewDate,
              "DD-MM-YYYY HH:mm:ss"
            ).isBefore(
              moment(
                b.programChallengeQuestionDto?.viewDate,
                "DD-MM-YYYY HH:mm:ss"
              )
            )
              ? -1
              : 1
          ).map((item, index) => {
            let date = moment(
              item?.programChallengeQuestionDto?.viewDate,
              "DD-MM-YYYY HH:mm:ss"
            ).startOf();

            let isPerformed = item?.isChallengePerformed;
            let isAllowToSubmitResponse = moment().isSameOrBefore(
              ResponseSubmissionEndDate
            );

            let PositiveResponse = item.responseStatus === "Positive";
            let isLastDayOfWeek = (index + 1) % 7 === 0;

            return (
              <div
                className={`${classes.cardElement} ${
                  isPerformed && PositiveResponse
                    ? "performedWithResponse"
                    : isPerformed && !PositiveResponse
                    ? "PerformedNegative"
                    : "default-case"
                } `}
                key={item?.id}
                style={{ margin: "5px" }}
              >
                <Card style={{ padding: "10px" }}>
                  <div className={classes.dayAndMedalContainer}>
                    <Typography className={classes.DayHeading}>
                      {" "}
                      Day {date.diff(startDate, "days") + 1}{" "}
                    </Typography>
                    {isLastDayOfWeek && showMedal[Math.floor(index / 7)] && (
                      <div className={classes.medalContainer}>
                        {/* {showMedal[Math.floor(index / 7)]}
                         */}

                        <img
                          id="banner"
                          className={classes.media}
                          // src={showMedal}
                          src={showMedal[Math.floor(index / 7)]?.valueIcon.type}
                          height={50}
                          alt={"Banner"}
                          style={{ borderRadius: "20px" }}
                          onClick={() => {
                            setOpenDialogImage(
                              showMedal[Math.floor(index / 7)]
                            );
                            setShowDialog(true);
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {/* <Tooltip title={item.question} enterTouchDelay={0}>
                  <Typography className={classes.DayQuestion}>
                    {item.question}
                  </Typography>
                </Tooltip> */}

                  <div className={classes.flagAndStatusContainer}>
                    {isPerformed && PositiveResponse ? (
                      <ThumbUp style={{ color: "#fbbf24", fontSize: "20px" }} />
                    ) : isPerformed && !PositiveResponse ? (
                      <ThumbDown
                        style={{ color: "#f87171", fontSize: "20px" }}
                      />
                    ) : (
                      <ErrorOutline
                        style={{ color: "#9ca3af", fontSize: "20px" }}
                      />
                    )}

                    <Tooltip
                      enterTouchDelay={0}
                      title={
                        item?.hydrationStatus?.[0]?.toUpperCase() +
                          item?.hydrationStatus?.slice(1) ||
                        (item?.responseStatus === "Positive" && "Sustained") ||
                        (item?.responseStatus === "Negative" &&
                          "Not Sustained") ||
                        "Not Responded"
                      }
                    >
                      <Typography
                        className={classes.statusFlag}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.hydrationStatus ||
                          (item?.responseStatus === "Positive" &&
                            "Sustained") ||
                          (item?.responseStatus === "Negative" &&
                            "Not Sustained") ||
                          "Not Responded"}
                      </Typography>
                    </Tooltip>
                  </div>

                  {/* && item?.hydrationStatus || item?.hydrationStatus :"Not Sustained" */}

                  <SubmitCancelBtn
                    onClick={() => {
                      onClickAndFetchData(item);
                      // setSelectedItem(index);
                    }}
                    label={
                      !isAllowToSubmitResponse
                        ? "View Response"
                        : !isPerformed
                        ? "Submit Response"
                        : "View Response"
                    }
                    type="submit"
                    style={{
                      width: "fit-content",
                      fontSize: "12px",
                      borderRadius: "10px",
                      padding: "6px 0px !important",
                    }}
                  />
                </Card>
              </div>
            );
          })}
      </Carousel>
      <DialogBox
        fullWidth
        open={showDialog}
        header={"Badge Achieved"}
        message={getDialogComponent()}
        share
        onAddPhoto={shareImage}
        onClick={() => closeWindow()}
        closeDisloag={() => closeWindow()}
      />
    </>
  );
};

// {isPerformed ? <FaCircleCheck  color="#43A047" size={20} /> : <IoMdCloseCircle color="#CC3333" size={20} />}

const styles = (theme) => ({
  cardElement: {
    // cursor: "pointer",
  },
  thumbswrapper: {
    height: "100%",

    "& .PerformedNegative": {
      borderRadius: "6px",
      padding: "3px",
      backgroundColor: "#ff7878",
      backgroundImage: "linear-gradient(315deg, #ff7878 0%, #ff0000 74%)",
    },
    "& .performedWithResponse": {
      borderRadius: "6px",
      padding: "3px",
      backgroundImage: "linear-gradient(315deg, #378b29 0%, #74d680 74%)",
    },

    "& .default-case": {
      borderRadius: "6px",
      padding: "3px",
      backgroundColor: "#d7d7d7",
      backgroundImage: "linear-gradient(147deg, #d7d7d7 0%, #353535 74%)",
    },

    "& .carousel  .slide": {
      [theme.breakpoints.down("md")]: {
        minWidth: "50% !important",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "100% !important",
      },
      textAlign: "start",
    },
  },
  highlighted: {
    // border:"1px solid red",
    borderRadius: "6px",
    padding: "1.5px",
    backgroundImage: "linear-gradient(315deg, #378b29 0%, #74d680 74%)",
  },

  statusFlag: {
    fontWeight: "600",
    // padding: "0 0 5px 0",
    color: "gray",
    fontSize: "15px",
    minidth: "100%",
    whiteSpace: "nowrap" /* Prevents text from wrapping to the next line */,
    overflow: "hidden" /* Hides the overflowed text */,
    textOverflow: "ellipsis",
  },

  flagAndStatusContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "0 0 5px 0",
  },

  DayHeading: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  dayAndMedalContainer: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },

  medalContainer: {
    position: "absolute",
    right: "-15px",
    top: "-15px",
    cursor: "pointer",
    "& img": {
      [theme.breakpoints.up("md")]: {
        height: "80px !important",
      },
    },
  },
  DayQuestion: {
    color: "gray",
    width: "100%",
    whiteSpace: "nowrap" /* Prevents text from wrapping to the next line */,
    overflow: "hidden" /* Hides the overflowed text */,
    textOverflow: "ellipsis",
    padding: "0 0 3px 0 ",
  },
  BtnView: {},
  arrowStyles: {
    // position: "absolute",
    zIndex: 2,
    top: "calc(45% - 15px)",
    backgroundColor: "white",
    width: "30px !important",
    height: "30px !important",
    fontSize: 40,
    cursor: "pointer",
    borderRadius: "50%",
    border: "2px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
      height: 0,
      //   display: "none",
      //   top: "calc(12% - 15px)",
    },
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter
)(QuestionCardsCarousel);
