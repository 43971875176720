import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Divider,
  Box,
  Table,
  TableRow,
  TableBody,
  CardActionArea,
  CardMedia,
  Card,
  Tabs,
  Tab,
} from "@material-ui/core";
import Properties from "../../../../properties/Properties";
import AppToolBar from "../../../common/AppToolBar";
import ApplicationUtil from "../../../../utils/ApplicationUtil";
import { getAllEvents } from "../../../../apis/eventService";
import ListPageLoader from "./../../../common/ListPageLoader";
//import MenuBar from "../../../common/SimpleMenu";
import CommonMenu from "./../../../common/SimpleMenu";
import Header from "../../../common/Header";
import EventListTiles from "../../../common/EventListTiles";
import ChallengesEventList from "../challenges/ChallengesEventList";
import {
  openEventChallenges,
  openEventChallengeUserCheck,
} from "../../../../apis/challengeService";
import { loading } from "../../../common/Spinner";
import { connect } from "react-redux";
import {
  updateUserSelectedEvent,
  updateUserPreferences,
} from "../../../../redux/user";
import { withRouter } from "react-router-dom";
import InlineDivider from "../../../common/InlineDivider";
import { getPublicProgram } from "../../../../apis/programService";
var moment = require("moment");

const styles = (theme) => ({
  boxCss: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    flexGrow: 1,
    marginLeft: "6.7%",
    marginRight: "6.7%",
    boxShadow: "none !important",
    marginTop: "10px",
  },
  mainContainer: {
    overflow: "hidden",
    // display: "grid",
  },
  rowContainer: {
    paddingBottom: "10px",
    borderRadius: "10px",

    // border: "solid 1px #ccc",
    // boxShadow:
    //   "0 4px 8px 0 rgb(0 0 0 / 0.2%), 0 6px 20px 0 rgba(0, 0, 0, 0.10)",
  },
  // rowContainer: hover{
  //   color:"yellow",
  // },
  dataContainer: {
    padding: "10px",
  },
  imageCont: {
    // height: "248px",
    position: "relative",
    margin: "0 auto",
    // marginTop: "15px",
    // marginBottom: "15px",
    // alignItems: "center",
    justifyContent: "center",
    display: "flex",
    position: "relative",
  },
  reviewImgSmall: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "100%",
  },
  reviewImgFull: {
    width: "60%",
    maxWidth: "60%",
    minWidth: "70%",
  },
  boxPaddingCss: {
    paddingRight: "0px !important",
  },
  selectedTab: {
    backgroundColor: "#4791db",
    color: "#fff",
    borderRadius: "10px",
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 600,
  },
  tab: {
    // color: "#fff",
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontWeight: 600,
  },
  bodyContainer: {
    marginTop: "68px",
    margin: "20px",
  },
  eventBlock: {
    background: "rgba( 58, 110, 165, 0.25 )",
    boxShadow: "0 4px 4px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: " blur( 4px )",
    // -webkit-backdrop-filter: blur( 4px );
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // width: "50%",
    padding: "10px",
    position: "relative",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  eventsContainer: {
    marginTop: "20px",
    width: "90%",
    margin: "auto",
    // display: "flex",
    // justifyContent: "space-between",
    // flexWrap: "wrap",
    marginBottom: "10px",
  },
  title: {
    fontWeight: 600,
    fontSize: "20px",
  },
  desc: {
    fontWeight: 600,
    fontSize: "14px",
  },
  bottomText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  status: {
    background: "rgba( 255, 255, 255 )",
    boxShadow: "0 2px 2px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    // -webkit-backdrop-filter: blur( 4px );
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    color: "#06d6a0",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontWeight: 600,
    textAlign: "right",
  },
  trial: {
    backgroundColor: "#f3722c",
    borderRadius: "10px",
    color: "#fff",
    padding: "10px",
    width: "40%",
    fontWeight: 600,
  },
  labels: {
    fontWeight: 600,
    textDecoration: "underline",
  },
});

class CompleteEventListView extends React.Component {
  state = {
    anchorEl: null,
    userName: "",
    value: 0,
    data: null,
    totalRecords: 0,
    challengesData: null,
    currentProg: null,
    pastProg: null,
  };

  componentDidMount() {
    document.title = Properties.Page_Title_Event_List;

    let index = localStorage.getItem("cEventTabID");
    this.setState({
      userName: localStorage.getItem("userName"),
      value: index ? (index === "0" ? 0 : 1) : 1,
    });

    let organizer = JSON.parse(localStorage.getItem("organizer"));

    openEventChallenges(organizer?.id ? organizer?.id : null)
      .then((res) => {
        if (res.data?.length > 0) {
          this.setState({
            isEventChallengesAvailable: true,
            challengesData: res.data,
          });
        } else if (res.data?.success.code === "404") {
          this.setState({
            isEventChallengesAvailable: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isEventChallengesAvailable: false,
        });
      });

    let generateOTP = false;
    openEventChallengeUserCheck(generateOTP).then((res) => {
      this.setState({ trial: res.data.trialApplicable });
    });

    let data = [],
      totalRecords = 0,
      pastData = [],
      pastTotalRecords = 0;

    const getData = async () => {
      let customParams = {
        pageCount: 8,
        pageNumber: 0,
        bool: true,
        eventState: "CURRENT",
      };
      await getAllEvents(customParams).then((res) => {
        let d = res.data.eventData;
        if (d) {
          d.map((row, index) => {
            row.endDate = moment(row.eventLocalEndDate).format("DD-MM-YYYY");

            let arr = row.eventRunCategories,
              uniqueTypes = new Set();
            if (arr) {
              arr.forEach((element) => {
                uniqueTypes.add(element.activityType.type);
              });
            }
            row.activityTypes = Array.from(uniqueTypes.values());
          });
        }
        data = d;
        totalRecords = res.data.totalRecords;
      });

      // past events
      let customPastParams = {
        pageCount: 8,
        pageNumber: 0,
        bool: true,
        eventState: "PAST",
      };
      await getAllEvents(customPastParams).then((res) => {
        let d = res.data.eventData;
        if (d) {
          d.map((row, index) => {
            row.endDate = moment(row.eventLocalEndDate).format("DD-MM-YYYY");

            let arr = row.eventRunCategories,
              uniqueTypes = new Set();
            if (arr) {
              arr.forEach((element) => {
                uniqueTypes.add(element.activityType.type);
              });
            }
            row.activityTypes = Array.from(uniqueTypes.values());
          });
        }
        pastData = d;
        pastTotalRecords = res.data.totalRecords;
      });
    };

    //Current Program
    this.getProgramCurrentDetails();

    //Past Program
    this.getProgramDetailsPast();
    

    getData().then(() => {
      this.setState({
        data: data,
        totalRecords: totalRecords,
        pastData: pastData,
        pastTotalRecords: pastTotalRecords,
        value: data?.length > 0 ? 0 : 1,
      });
    });
  }

  // current program
  getProgramCurrentDetails = () => {
   let  requestedParams = {
    pageCount: 8,
    pageNumber: 0,
    eventState:"CURRENT",
    eventScope: "PUBLIC"
   }
   getPublicProgram(requestedParams).then((res) => {
      this.setState({
        currentProg: res?.data?.programDto
      });
    })
  }
// past program
  getProgramDetailsPast = () => {
    let requestedProgramParamsPast = {
      pageCount: 8,
      pageNumber: 0,
      eventState:"PAST",
      eventScope: "PUBLIC"
     }

     getPublicProgram(requestedProgramParamsPast).then((res) => {
      this.setState({
        pastProg: res?.data?.programDto
      });
    });
  }
  
  // current events
  getList = (isFristPage, page) => {
    const { appliedFilter, appliedSort } = this.state;
    let customParams = {
      // filterString: appliedFilter.sortColumn,
      pageCount: 8,
      pageNumber: page ? page : 0,
      bool: false,
      eventState: "CURRENT",
      eventScope: "PUBLIC",
      //sortKey: appliedSort.sortColumn,
      //sortOrder: appliedSort.sortDirection === "ASC" ? true : false,
    };

    getAllEvents(customParams).then((res) => {
      let newdata = page
        ? this.state.data.concat(res.data.eventData)
        : res.data.eventData;

      newdata.map((row, index) => {
        if (row && row.eventLocalEndDate)
          row.endDate = moment(row.eventLocalEndDate).format("DD-MM-YYYY");

        if (row && row.eventRunCategories) {
          let arr = row.eventRunCategories,
            uniqueTypes = new Set();
          arr.forEach((element) => {
            uniqueTypes.add(element.activityType.type);
          });
          row.activityTypes = Array.from(uniqueTypes.values());
        }
      });

      this.setState({
        data: newdata,
        totalRecords: res.data.totalRecords,
      });
    });

    getPublicProgram(customParams).then((res) => {
      let newdata = page
        ? this.state.currentProg.concat(res?.data?.programDto)
        : res?.data?.programDto;
      this.setState({
        currentProg: newdata
      });
    });
  };

  // past
  getPastList = (isFristPage, page) => {
    const { appliedFilter, appliedSort } = this.state;
    let customParams = {
      pageCount: 8,
      pageNumber: page ? page : 0,
      bool: false,
      eventState: "PAST",
      eventScope: "PUBLIC",
    };

    getAllEvents(customParams).then((res) => {
      let newdata = page
        ? this.state.pastData.concat(res.data.eventData)
        : res.data.eventData;

      newdata.map((row, index) => {
        if (row && row.eventLocalEndDate)
          row.endDate = moment(row.eventLocalEndDate).format("DD-MM-YYYY");

        if (row && row.eventRunCategories) {
          let arr = row.eventRunCategories,
            uniqueTypes = new Set();
          arr.forEach((element) => {
            uniqueTypes.add(element.activityType.type);
          });
          row.activityTypes = Array.from(uniqueTypes.values());
        }
      });

      getPublicProgram(customParams).then((res) => {
        let newdata = page
        ? this.state.pastProg.concat(res?.data?.programDto)
        : res?.data?.programDto;
        this.setState({
          pastProg: newdata
        });
      });

      this.setState({
        pastData: newdata,
        pastTotalRecords: res.data.totalRecords,
      });
    });
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
      // resetParams: newValue, //fix for reseting page since it was carrying between states
    });
    localStorage.setItem("cEventTabID", newValue);
  };

  render() {
    const { classes, history } = this.props;
    const { value, challengesData, trial } = this.state;

    return (
      <React.Fragment>
        <AppToolBar
          backNavigate={() => this.props.history.goBack()}
          // showBack={true}
        >
          <Header
            {...this.props}
            homeScreen
            label={Properties.Label_EventsList}
          />
        </AppToolBar>
        <div className={classes.bodyContainer}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={this.handleChange}
            centered
          >
            <Tab
              value={0}
              fullWidth
              className={value === 0 ? classes.selectedTab : classes.tab}
              label="Current" //"Events For You"
            />
            <Tab
              value={1}
              fullWidth
              className={value === 1 ? classes.selectedTab : classes.tab}
              label="Past" //"Challenges For You"
            />
          </Tabs>

          {value === 0 && (
            <Grid
              style={{
                marginTop: "10px",
                paddingBottom: "10px",
                // margin: "10px 5%",
                // paddingTop: "20px",
                overflow: "hidden",
              }}

              // justifyContent="center"
              // className={classes.mainContainer}
            >
              <Typography variant="h6">
                <span className={classes.labels}>Challenges</span>
              </Typography>

              <ChallengesEventList
                challengesData={challengesData}
                trial={trial}
              />

              <Typography variant="h6" style={{ paddingTop: "15px" }}>
                <span className={classes.labels}>Events</span>
              </Typography>

              <ListPageLoader
                pageParams={{
                  page: 0,
                  start: 0,
                  limit: 8,
                  TotalRecords: this.state.totalRecords,
                }}
                totalLoadedLength={this.state.data ? this.state.data.length : 0}
                loadNextData={(params) => {
                  this.getList(false, params.page);
                }}
              >
                <Grid
                  style={{
                    padding: challengesData
                      ? "5px 5px 10px 5px"
                      : "5px 5px 10px 5px",
                    marginTop: challengesData ? "0px" : "10px",
                  }}
                  container
                  spacing={4}
                >
                  {/* <Table aria-label="customized table"> */}
                  {this.state.data && this.state.data.length > 0 ? (
                    this.state.data.map((row, index) => (
                      <>
                        {row && (
                          <EventListTiles
                            {...this.props}
                            row={row}
                            index={index}
                            navigate={(e) => {
                              if (row?.openSubscriptionEvents) {
                                this.props.updateUserSelectedEvent({
                                  id: row.id,
                                });
                                this.props.history.push("/eventDetails");
                              } else {
                                this.props.history.push(
                                  `/event?distKey=${row.distKey}`
                                );
                              }
                            }}
                            displayRegistration
                          />
                        )}
                      </>
                    ))
                  ) : this.state.data === null ? (
                    <div style={{ padding: "15px 8px 15px 8px" }}>
                      {loading(true)}
                    </div>
                  ) : (
                    <>
                      {!challengesData && (
                        <div style={{ margin: "20px" }}>
                          {"No events available"}
                        </div>
                      )}
                    </>
                  )}
                  {/* </Table> */}
                </Grid>
              </ListPageLoader>

             
              <div style={{ marginBottom: "10px" }}>
                <InlineDivider
                  style={{ height: "2px", backgroundColor: "#444444" }}
                  childrenStyle={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Programs
                </InlineDivider>
              </div>

          {/* Current Program */}
          <Grid container spacing={4} style={{ marginBottom: "10px" }}>
            {this.state.currentProg && this.state.currentProg?.length > 0 ? (
              this.state.currentProg?.map((row, index) => (
                <>
                  <EventListTiles
                    {...this.props}
                    row={row}
                    index={index}
                    key={index}
                    navigateToDetails={(e) => {
                      // this.props.updateUserSelectedProgram(row);
                      this.props.history.push(
                        `/program?distKey=${row.distKey}`
                      );
                    }}
                    displayRegistration={false}
                    hideResultsForAdmin
                  />
                </>
              ))
            ) : this.state.currentProg === null ? (
              <div style={{ padding: "15px 8px 15px 8px" }}></div>
            ) : (
              <div style={{ margin: "20px" }}>
                {"No Current programs available"}
              </div>
            )}
          </Grid>
            </Grid>
          )}

          {value === 1 && (
            <Grid
              style={{
                padding: "5px",
                marginTop: "10px",
                overflow: "hidden",
              }}
              // justifyContent="center"
              // className={classes.mainContainer}
            >
              <ListPageLoader
                pageParams={{
                  page: 0,
                  start: 0,
                  limit: 8,
                  TotalRecords: this.state.pastTotalRecords,
                }}
                totalLoadedLength={
                  this.state.pastData ? this.state.pastData.length : 0
                }
                loadNextData={(params) => {
                  this.getPastList(false, params.page);
                }}
              >
                <Grid container spacing={4} style={{ padding: "0px 1px" }}>
                  {/* <Table aria-label="customized table"> */}
                  {this.state.pastData && this.state.pastData.length > 0 ? (
                    this.state.pastData.map((row, index) => (
                      <>
                        {row && (
                          <EventListTiles
                            {...this.props}
                            row={row}
                            index={index}
                            navigate={(e) => {
                              if (row?.openSubscriptionEvents) {
                                this.props.updateUserSelectedEvent({
                                  id: row.id,
                                });
                                this.props.history.push("/eventDetails");
                              } else {
                                this.props.history.push(
                                  `/event?distKey=${row.distKey}`
                                );
                              }
                            }}
                            displayRegistration
                          />
                        )}
                      </>
                    ))
                  ) : this.state.pastData === null ? (
                    <div style={{ padding: "15px 8px 15px 8px" }}>
                      {loading(true)}
                    </div>
                  ) : (
                    <div style={{ margin: "20px" }}>
                      {"No events available"}
                    </div>
                  )}
                  {/* </Table> */}
                </Grid>
              </ListPageLoader>

              <div style={{ marginBottom: "10px" }}>
                <InlineDivider
                  style={{ height: "2px", backgroundColor: "#444444" }}
                  childrenStyle={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Programs
                </InlineDivider>
              </div>

          {/* Past Program */}
          <Grid container spacing={4} style={{ marginBottom: "10px" }}>
            {this.state.pastProg && this.state.pastProg?.length > 0 ? (
              this.state.pastProg?.map((row, index) => (
                <>
                  <EventListTiles
                    {...this.props}
                    row={row}
                    index={index}
                    key={index}
                    navigateToDetails={(e) => {
                      // this.props.updateUserSelectedProgram(row);
                      this.props.history.push(
                        `/program?distKey=${row.distKey}`
                      );
                    }}
                    displayRegistration={false}
                    hideResultsForAdmin
                  />
                </>
              ))
            ) : this.state.currentProg === null ? (
              <div style={{ padding: "15px 8px 15px 8px" }}></div>
            ) : (
              <div style={{ margin: "20px" }}>
                {"No Current programs available"}
              </div>
            )}
          </Grid>

            </Grid>
          )}
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    userSelectedEvent: state.user.userSelectedEvent,
    updateUserPreferences: state.user.updateUserPreferences,
  };
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedEvent,
    updateUserPreferences,
  })
)(CompleteEventListView);
