import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";
import moment from "moment";
import ApplicationUtil from "../utils/ApplicationUtil";
var momentTimeZone = require("moment-timezone");

const API_URL_FILE = http.API_URL();

export function getEventDetails(requestparam, eventId, customLoading = true) {
  customLoading && loading(customLoading);

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  //QA

  let url = `${API_URL_FILE}api/v1/event/${eventId}`;
  if (requestparam) {
    url = `${API_URL_FILE}api/v1/event/${eventId}?requestView=${requestparam}`;
  }
  //prod
  //let url = API_URL_FILE + "api/v1/event/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          customLoading && loading(false);
          resolve(res);
        } else {
          customLoading && loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        customLoading && loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//activity List
export function getEventActivityDetails(
  requestparam,
  eventId,
  customLoading = true
) {
  customLoading && loading(customLoading);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/event/activity/${eventId}`;
  if (requestparam) {
    url = `${API_URL_FILE}api/v1/event/activity/${eventId}?requestView=${requestparam?.requestView}&runnerId=${requestparam?.runnerId}`;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          customLoading && loading(false);
          resolve(res);
        } else {
          customLoading && loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        customLoading && loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//activity details summary
export function getActivityDetailsSummary(eventId, runnerId, activityId) {
  loading(true);
  let token = ApplicationUtil.getRunnerToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/runner/activity/${eventId}/${runnerId}/${activityId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//activity List
export function getSecondaryActivity(eventId, activityId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE + "api/v1/nd/secondaryActivity/" + eventId + "/" + activityId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//for preRegister
export function preRegisteration(key) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      regKey: key,
    },
  };

  let url = API_URL_FILE + "api/v1/preRegister"; // + "/" + key;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//for getting data from distKey
export function getEventDetailsWithDistkey(distkey) {
  loading(true);

  //timezone
  const timeZone = momentTimeZone.tz.guess();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      distKey: encodeURIComponent(distkey),
      timeZone: timeZone,
    },
  };

  let url = API_URL_FILE + "api/v1/event";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getProgramDetailsWithDistkey(distkey) {
  loading(true);
  //timezone
  const timeZone = momentTimeZone.tz.guess();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      distKey: encodeURIComponent(distkey),
      timeZone: timeZone,
    },
  };
  let url = API_URL_FILE + "api/v1/program";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//for sign up
export function registerEvent(requestParams, profilePicture) {
  // var key=new Date().getTime().toString();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let url = API_URL_FILE + "api/v1/signup";
  var formData = new FormData();

  formData.set("userRequest", JSON.stringify(requestParams));

  formData.append("profilePicture", profilePicture);

  //var data=requestParams.password;
  //console.log(data);
  // Encrypt
  //var ciphertext = CryptoJS.AES.encrypt(data,key).toString();
  //requestParams.password=ciphertext;
  //log encrypted data
  //console.log("Encrypt Data -",ciphertext);
  // Decrypt
  //var bytes = CryptoJS.AES.decrypt(ciphertext,key);
  //var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  // console.log(decryptedData);

  //console.log(requestParams);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function registerProgram(requestParams) {
  let runner_token = ApplicationUtil.getRunnerToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${runner_token}`,
    },
  };
  let url = API_URL_FILE + "api/v1/program/register";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else if (res?.data?.success?.code === "601") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function registerProgramSession() {
  let runner_token = ApplicationUtil.getRunnerToken();
  let runner = ApplicationUtil.getRunnerDetails();
  let program = ApplicationUtil.getRunnersProgramDetails();
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${runner_token}`,
    },
  };
  let url = `${API_URL_FILE}api/v1/register/programSession/participants/${program?.id}/${runner?.runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else if (res?.data?.success?.code === "601") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function addProgramSession(requestParams) {
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let url = API_URL_FILE + "api/v1/create/programSession";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        // if (res?.data?.success?.code === "409") {
        if (res?.data?.success?.code !== "200") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getProgramSessionType(programId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let url = API_URL_FILE + `api/v1/program/session/type/${programId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function registerMultipleEventCategory(endpoints) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return Promise.allSettled(
    endpoints?.map((endpoint) => {
      axiosConfig.headers.id = endpoint.id;
      return http
        .post(`${endpoint.url}`, endpoint.params, axiosConfig)
        .catch(() => null);
    })
  ).then((res) => {
    loading(false);

    return res;
  });

  // return new Promise.all(function (resolve, reject) {
  //   http
  //     .all(endpoints?.map((endpoint)=>{
  //       axiosConfig.headers.id = endpoint.id;
  //       return http
  //       .post(`${endpoint.url}`, endpoint.params, axiosConfig)
  //       .catch(() => null);
  //     }))
  //     .then((res) => {
  //         loading(false);
  //         resolve(res);

  //     })
  //     .catch((error) => {
  //       loading(false);
  //       if (error !== "") {
  //         Snackbar.show({
  //           variant: "error",
  //           isAutoHide: true,
  //           preventDuplicate: true,
  //           message: Properties.Something_went_wrong,
  //         });
  //       }
  //       reject(error);
  //     });
  // });
}
export function registerEventCategory(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/register";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function activateUserEmail() {
  loading(true);

  let userEMail = localStorage.getItem("userEMail");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  let url = API_URL_FILE + "api/v1/account/activate/" + userEMail;
  return new Promise(function (resolve, reject) {
    http
      .put(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getRegisteredEventDetails(runnerId, eventId) {
  loading(true);

  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let supportOrganizer_token = ApplicationUtil.getSupportToken();

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  // let eventId = requestParams.eventId

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/eventDetails/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200 && res.data) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_OperationFailed,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function downloadBIBFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url = API_URL_FILE + "api/v1/download/bib/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            // "content-length":res.headers[0],
            type: res.data.type, //"application/pdf"//"image/jpeg" //res.data.type
          });

          var url = window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            var a = document.createElement("a");
            a.href = url;
            if (res.data.type === "application/pdf") a.download = filename;
            else a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function downloadCertificateFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    // API_URL_FILE + "api/v1/download/certificate/" + eventId + "/" + runnerId; //eventId;
    API_URL_FILE + "api/v1/download/certificate/" + eventId + "/" + runnerId; //eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            type: res.data.type, //type: "image/jpeg"
          });

          var url = window.URL.createObjectURL(blob);

          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            // var a = document.createElement("a");
            // a.href = url;
            // if (res.data.type == "application/pdf")
            //   a.download = "Certificate" + ".pdf";
            // else a.download = "Certificate" + ".png";
            // //a.download = "Certificate" + ".pdf";
            // document.body.appendChild(a);
            // a.click();
            // a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function downloadFitnessReportFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/fitnessReport/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            type: res.data.type, //type: "image/jpeg"
          });

          var url = window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            var a = document.createElement("a");
            a.href = url;
            if (res.data.type === "application/pdf") a.download = filename;
            else a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function downloadRunnersFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/runnerMap/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            type: res.data.type, //"image/png"
          });

          var url = window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            var a = document.createElement("a");
            a.href = url;
            if (res.data.type === "application/pdf") a.download = filename;
            else a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function downloadRoutesFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/runnerRoute/" + eventId + "/" + runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            type: res.data.type, //"image/png"
          });

          var url = window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            var a = document.createElement("a");
            a.href = url;
            if (res.data.type === "application/pdf") a.download = filename;
            else a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//RunnerCollage
export function downloadRunnerCollageFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/runnerCollage/" + eventId + "/" + runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          var url = "data:image/png;base64," + res.data.imageData;
          //window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var image = new Image();
            image.src = url;
            var w = window.open("");
            w.document.write(image.outerHTML);
            //var pop = window.open(`<html><body><img src=url /></body></html>`);
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            var a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function viewCollageFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/runnerCollage/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            // "content-length":res.headers[0],
            type: res.data.type, //"application/pdf"//"image/jpeg" //res.data.type
          });

          var url = window.URL.createObjectURL(blob);
          resolve(url);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getSupportList(requestParams, runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = "";
  if (eventId === undefined || eventId === null) {
    url =
      API_URL_FILE +
      "api/v1/runner/activities" +
      "?" +
      "status=" +
      requestParams.filterString +
      "&pageCount=" +
      requestParams.pageCount +
      "&pageNumber=" +
      requestParams.pageNumber +
      "&sortKey=" +
      requestParams.sortKey +
      "&sortOrder=" +
      requestParams.sortOrder;
  } else {
    url =
      API_URL_FILE +
      "api/v1/runner/activities" +
      "?" +
      "eventId=" +
      eventId +
      "&status=" +
      requestParams.filterString +
      "&pageCount=" +
      requestParams.pageCount +
      "&pageNumber=" +
      requestParams.pageNumber +
      "&sortKey=" +
      requestParams.sortKey +
      "&sortOrder=" +
      requestParams.sortOrder;
  }
  if (requestParams.bib) {
    url = url + "&bib=" + requestParams.bib;
  }
  if (requestParams.participantIds) {
    url = url + "&participantIds=" + requestParams.participantIds;
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getActivityDetails(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let eventId = requestParams.eventId;
  let runnerId = requestParams.runnerId;
  let activityId = requestParams.activityId;

  let url =
    API_URL_FILE +
    "api/v1/runner/activity/" +
    eventId +
    "/" +
    runnerId +
    "/" +
    activityId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function updateActivity(requestParams, activityId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/runner/activity/" + activityId;
  var formData = new FormData();

  formData.set("uploadRequest", JSON.stringify(requestParams.uploadRequest));
  if (requestParams.file && requestParams.file.length > 0) {
    requestParams.file.forEach((element) => {
      formData.append("files", element);
    });
  }
  if (requestParams.postRunImages) {
    formData.append("postRunImages", requestParams.postRunImages);
  }
  // formData.append("deletedFiles", requestParams.deletedFiles);
  return new Promise(function (resolve, reject) {
    http
      .put(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            message: "Activity Updated successfully",
          });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 600) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data
                ? error?.response?.data
                : Properties.Something_went_wrong,
            });
          } else if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data?.verbose
                ? error?.response?.data?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
export function markInvalidActivity(requestParams, activityId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/runner/activity/invalidate/" + activityId;
  var formData = new FormData();

  formData.set("reason", JSON.stringify(requestParams.message));

  // formData.append("deletedFiles", requestParams.deletedFiles);
  return new Promise(function (resolve, reject) {
    http
      .put(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            message: "Marked as Invalid",
          });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 600) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data
                ? error?.response?.data
                : Properties.Something_went_wrong,
            });
          } else if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data?.verbose
                ? error?.response?.data?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
//delete activity
export function deleteActivity(eventId, runnerId, activityIds) {
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/runner/activity/" + eventId + "/" + runnerId;
  let arr = activityIds;
  arr = arr.toString();
  var formData = new FormData();
  formData.append("runnerActivityId", arr);

  return new Promise(function (resolve, reject) {
    http
      .delete(url, {
        headers: { Authorization: "Bearer " + token },
        data: formData,
      })
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          if (res?.data?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Activity Deleted",
            });
          } else if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          }
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//add activity feedback
export function addActivityFeedback(params, activityId) {
  loading(true);

  let supportData = ApplicationUtil.getSupportDetails();
  let token = supportData?.token;
  let supportRunnerId = supportData?.runnerId;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/activity/message/add";
  let userMessage;
  if (params && params.programChallengeId) {
    userMessage = {
      activityId: params.activityId,
      recipientId: params.recipientId,
      programChallengeId: params.programChallengeId,
      senderId: supportRunnerId,
      message: params.message,
      recipientType: params.recipientType,
      objectId: params.objectId,
      messageType: params.messageType,
    };
  } else {
    userMessage = {
      activityId: params.activityId,
      recipientId: params.recipientId,
      eventId: params.eventId,
      senderId: supportRunnerId,
      message: params.message,
      recipientType: params.recipientType,
      objectId: params.objectId,
      messageType: params.messageType,
      openEventId: params.openEventId,
    };
  }
  var formData = new FormData();

  formData.set("userMessage", JSON.stringify(userMessage));

  // let requestParams = {userMessage:userMessage};
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            message: "Message uploaded successfully",
          });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 600) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data
                ? error?.response?.data
                : Properties.Something_went_wrong,
            });
          } else if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data?.verbose
                ? error?.response?.data?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function downloadImage(id, eventId, activityId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let runnerId = id;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    // responseType: "blob",
  };

  let url = `${API_URL_FILE}api/v1/download/activityScreenshot/${eventId}/${runnerId}${
    activityId ? "?activityId=" + activityId : ""
  }`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res.data.images);
        } else {
          loading(false);

          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
      });
  });
}

//past events
export function getEventList(params) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let runnerId = runnerData?.runner?.id;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/${runnerId}/events?${
    params?.pageCount ? "pageCount=" + params.pageCount : ""
  }${params?.pageNumber ? "&pageNumber=" + params.pageNumber : ""}${
    params?.eventState ? "&eventState=" + params.eventState : ""
  }${params?.fromDate ? "fromDate=" + params.fromDate : ""}${
    params?.toDate ? "&toDate=" + params.toDate : ""
  }`;
  //   API_URL_FILE +
  //   "api/v1/" +
  //   runnerId +
  //   "/events?" +
  //   "pageCount=" +
  //   params.pageCount +
  //   "&pageNumber=" +
  //   params.pageNumber;
  // if (params?.eventState) {
  //   url =
  //     API_URL_FILE +
  //     "api/v1/" +
  //     runnerId +
  //     "/events?" +
  //     "pageCount=" +
  //     params.pageCount +
  //     "&pageNumber=" +
  //     params.pageNumber +
  //     "&eventState=" +
  //     params.eventState;
  // }
  // +
  //   "&sortKey=" +
  //   params.sortKey +
  //   "&sortOrder=" +
  //   true;

  //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getRunnerProgramList(params) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let runnerId = runnerData?.runner?.id;
  let programId = params?.programId;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/program/${programId}/${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//upcoming events
export function getUpcomingEventList(params) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();

  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let runnerId = runnerData?.runner?.id;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/upcomingEvents/" +
    runnerId +
    "?pageCount=" +
    params.pageCount +
    "&pageNumber=" +
    params.pageNumber;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getProgramList(params) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let token = runnerData?.token;
  let runnerId = runnerData?.runner?.id;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/program/info?runnerId=${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getProgramEventList(params) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/program/${params.programId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function isProgramRegistered(params) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/program/registered/${params.programId}/${runnerData?.runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//organizers

//details of participants

export function getParticipantsDetails(runnerId, eventId) {
  loading(true);

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/registration/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// get details of program challenge participant
export function getProgramChallengeParticipantDetails() {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let programChallengeId = localStorage.getItem("programChallengeId");
  let supportRunnerId = localStorage.getItem("supportRunnerId");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/program/challenge/list?" +
    "programChallengeId=" +
    programChallengeId +
    "&runnerId=" +
    supportRunnerId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//execution details
export function getExecutionsDetails() {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let runnerId = localStorage.getItem("supportRunnerId");
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url =
    API_URL_FILE + "api/v1/event/excecution/detail/" + eventId + "/" + runnerId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//resend emails
export function resendEmails(requestParams) {
  let eventId = ApplicationUtil.getSupportsEventID();
  let runnerId = parseInt(localStorage.getItem("supportRunnerId"));

  let token = ApplicationUtil.getSupportToken();
  var formData = new FormData();
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/nd/emailJobService/" +
    eventId +
    "/" +
    runnerId +
    "?emailTypeId=" +
    requestParams;

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          if (res.data.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.verbose,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data?.verbose
                ? res?.data?.verbose
                : Properties.Something_went_wrong,
            });
          }
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//regenerate mails
export function regenerateEmails(emailType) {
  let eventId = ApplicationUtil.getSupportsEventID();
  let runnerId = parseInt(localStorage.getItem("supportRunnerId"));

  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/event/regenerate/" +
    emailType +
    "/" +
    eventId +
    "/" +
    runnerId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose,
          });
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//download csv

export function downloadCSV(requestParams) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let eventId = ApplicationUtil.getSupportsEventID();
  let eventDetails = ApplicationUtil.getSupportsEventsDetails();

  // defect_id_287 - Laxman
  let url;
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/download/event/participants?eventId=${eventId}${
      requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""
    }${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.startDate
        ? "&startDate=" +
          requestParams.startDate +
          "&endDate=" +
          requestParams.endDate
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${requestParams.runnerIds ? "&runnerIds=" + requestParams.runnerIds : ""}${
      requestParams.bib ? "&bib=" + requestParams.bib : ""
    }${requestParams.name ? "&runnerName=" + requestParams.name : ""}${
      requestParams.email ? "&email=" + requestParams.email : ""
    }${
      requestParams.onlineRegistration === true
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : requestParams.onlineRegistration === false
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : ""
    }
    ${
      requestParams.bibGenerated === true
        ? "&bibGenerated=" + requestParams.bibGenerated
        : requestParams.bibGenerated === false
        ? "&bibGenerated=" + requestParams.bibGenerated
        : ""
    }
    ${
      requestParams.consentPresent === true
        ? "&consentPresent=" + requestParams.consentPresent
        : requestParams.consentPresent === false
        ? "&consentPresent=" + requestParams.consentPresent
        : ""
    }`;
  } else {
    url =
      API_URL_FILE +
      "api/v1/download/event/participants?" +
      "eventId=" +
      eventId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          //filename
          let eventName = eventDetails.name;
          eventName = eventName + moment().format("DD-MM-YYYY hh:mm:ss");
          const blob = new Blob([res?.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          var contentDisposition = res.headers["Content-Disposition"];
          var filename =
            ApplicationUtil.getFileNameFromHeaders(contentDisposition);
          const a = document.createElement("a");
          a.setAttribute("hidden", "");
          a.setAttribute("href", url);
          a.setAttribute("download", `${eventName}.csv`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function downloadProgramCSV(requestParams) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();
  let program = ApplicationUtil.getSupportsProgramDetails();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  // let url = `${API_URL_FILE}api/v1/download/program/participants?programId=${program?.id}`;

  let url;
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/download/program/participants?programId=${
      program?.id
    }${requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""}${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.startDate
        ? "&startDate=" +
          requestParams.startDate +
          "&endDate=" +
          requestParams.endDate
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${requestParams.runnerIds ? "&runnerIds=" + requestParams.runnerIds : ""}${
      requestParams.bib ? "&bib=" + requestParams.bib : ""
    }${requestParams.name ? "&runnerName=" + requestParams.name : ""}${
      requestParams.email ? "&email=" + requestParams.email : ""
    }${
      requestParams.onlineRegistration === true
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : requestParams.onlineRegistration === false
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : ""
    }
    ${
      requestParams.bibGenerated === true
        ? "&bibGenerated=" + requestParams.bibGenerated
        : requestParams.bibGenerated === false
        ? "&bibGenerated=" + requestParams.bibGenerated
        : ""
    }
    ${
      requestParams.consentPresent === true
        ? "&consentPresent=" + requestParams.consentPresent
        : requestParams.consentPresent === false
        ? "&consentPresent=" + requestParams.consentPresent
        : ""
    }`;
  } else {
    url =
      API_URL_FILE +
      "api/v1/download/program/participants?" +
      "programId=" +
      program?.id;
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          //filename
          let eventName = program?.name;
          eventName = eventName + moment().format("DD-MM-YYYY hh:mm:ss");
          const blob = new Blob([res?.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          var contentDisposition = res.headers["Content-Disposition"];
          var filename =
            ApplicationUtil.getFileNameFromHeaders(contentDisposition);
          const a = document.createElement("a");
          a.setAttribute("hidden", "");
          a.setAttribute("href", url);
          a.setAttribute("download", `${eventName}.csv`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//download video
export function downloadVideoFile(runnerId, eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };

  let url = API_URL_FILE + "api/v1/download/video/" + eventId + "/" + runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          const blob = new Blob([res?.data], {
            type:
              // res.data.type,
              "video/mp4",
          });
          var url = window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            const a = document.createElement("a");
            a.setAttribute("hidden", "");
            a.setAttribute("href", url);
            a.setAttribute("download", filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            resolve(res);

            //   var a = document.createElement("a");
            //   a.href = url;
            //   if (res.data.type === "application/pdf")
            //     a.download = "Certificate.pdf";
            //   else a.download = "Certificate.mp4";
            //   //a.download = "Certificate" + ".pdf";
            //   document.body.appendChild(a);
            //   a.click();
            //   a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//list of events on support
export function getSupportEventList(requestParams) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/events" +
    "?" +
    "pageCount=" +
    requestParams.pageCount +
    "&pageNumber=" +
    requestParams.pageNumber +
    "&sortKey=" +
    requestParams.sortKey +
    "&sortOrder=" +
    requestParams.sortOrder;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getParticipantsList() {
  loading(true);
  let eventId = ApplicationUtil.getSupportsEventID();
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  //let eventId = 15;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = "";

  url = API_URL_FILE + "api/v1/eventParticipants/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//postRunImage Upload
export default function uploadPhoto(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/runner/activity/" +
    requestParams.id +
    "/upload/postRunImages";
  var formData = new FormData();

  formData.append("postRunImages", requestParams.file);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Photo added successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

//postRunImage Upload without activityId
export function uploadPostRunImages(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let runnerId = runnerData?.runner?.id;

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getRunnersEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/runner/" +
    "upload/postRunImages/" +
    eventId +
    "/" +
    runnerId;
  var formData = new FormData();
  requestParams.file.forEach((element) => {
    formData.append("postRunImages", element);
  });
  // formData.append("postRunImages", requestParams.file);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Photo added successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function uploadPostRunVideo(requestParams) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runnerId = runnerData?.runner?.id;
  let token = runnerData?.token;
  let eventId = ApplicationUtil.getRunnersEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/uploadVideo/" + eventId + "/" + runnerId;
  var formData = new FormData();
  requestParams.file.forEach((element) => {
    formData.append("file", element);
  });
  // formData.append("postRunImages", requestParams.file);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Video added successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function deletePostRunVideo(requestParams) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runnerId = runnerData?.runner?.id;
  let token = runnerData?.token;
  let eventId = ApplicationUtil.getRunnersEventID();
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/deleteVideo/${eventId}/${runnerId}?fileName=${requestParams?.fileName}`;
  return new Promise(function (resolve, reject) {
    http
      .delete(url, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Video deleted successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getDiscountCoupon(coupon) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let token = runnerData?.token;
  let eventId = ApplicationUtil.getRunnersEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = "";

  url = `${API_URL_FILE}api/v1/validate/coupon?eventId=${eventId}&couponCode=${coupon}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getVideoGallery(params) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      distKey: encodeURIComponent(params?.distKey),
    },
  };
  let url = "";

  url = `${API_URL_FILE}api/v1/video/gallery?pageCount=${
    params?.pageCount
  }&pageNumber=${params?.pageNumber}${
    params?.fileId ? "&fileId=" + params.fileId : ""
  }`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getVideoGalleryRunnersList(params) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // distKey: params?.distKey,
    },
  };
  let url = "";

  url = `${API_URL_FILE}api/v1/search/video/gallery?eventId=${params?.eventId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get postRunImagesList
export function getPostRunImage(runnerId, eventId) {
  loading(true);

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/postRunImages/" + eventId + "/" + runnerId; //+"/"+activityId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
      });
  });
}

//delete postRunImages
export function deletePostRunImage(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let url = API_URL_FILE + "api/v1/delete/file/" + requestParams.fileId;

  var formData = new FormData();
  formData.append("fileType", requestParams.fileType);
  formData.append("fileName", requestParams.fileName);
  if (requestParams.fileId) formData.append("fileId", requestParams.fileId);

  return new Promise(function (resolve, reject) {
    http
      .delete(url, {
        headers: { Authorization: "Bearer " + token },
        data: formData,
      })
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Photo_Delete_Success_Label,
          });
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//to get postRegistration Fields
export function getPostRegistrationFields(eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      // Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/field" + "/" + eventId; //eventId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//save postRegistration Fields
export function savePostRegistrationFields(requestParams) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runnerId = runnerData?.runner?.id;
  let eventId = ApplicationUtil.getRunnersEventID();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  requestParams.runnerId = runnerId;
  requestParams.eventId = eventId;
  let url = API_URL_FILE + "api/v1/field/fieldValue/save";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            message: res.data.success.verbose,
          });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 401) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_InvalidCredentials,
            });
          } else if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_OperationFailed,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: error.response
                ? error.response.data
                  ? error.response.data.error.verbose
                  : Properties.Something_went_wrong
                : Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

//check if user is already registered for event
export function userRegisteredForEvent(eventId) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let runnerId = runnerData?.runner?.id;
  let supportOrganizer_token = ApplicationUtil.getSupportToken();

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE + "api/v1/event/registered/" + eventId + "/" + runnerId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function userRegisteredForProgram(programId) {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let token = runnerData?.token;
  let runnerId = runnerData?.runner?.id;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE + "api/v1/program/registered/" + programId + "/" + runnerId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//to check if email already registered with app
export function checkEmailExists(email, byEmail, byMobile) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/" + email;

  //temporary

  var formData = new FormData();
  if (byEmail) {
    formData.set("byEmail", true);
  }
  if (byMobile) {
    formData.set("byMobile", true);
  }

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          if (res?.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          }
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//generate OTP for signup
export function signupGenerateOTP(requestParams) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/signup/otp";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          if (res?.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            resolve(res);
          } else if (res?.data?.success?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          } else if (res?.data?.success?.code === "401") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//verify otp at signup
export function signupVerifyOTP(requestParams) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/user/verify/otp";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          if (res?.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            resolve(res);
          } else if (res?.data?.success?.code === "401") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            reject(res);
          } else if (res?.data?.success?.code === "600") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//invite Registration
export function invitationRegistration(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/invite/save";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            message: "Invited Successfully!",
          });
          resolve(res);
        } else if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data?.success?.verbose,
          });
          reject(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response?.data
              ? error?.response?.data
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//invite getList
export function getInvitationList() {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let runnerId = runnerData?.runner?.id;

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getRunnersEventID();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/invite/" + eventId + "/" + runnerId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//delete registered invite
export function deleteEventParticipants(runnerId) {
  let userEventId = ApplicationUtil.getRunnersEventID();
  let supportEventId = ApplicationUtil.getSupportsEventID();
  let eventId = window.location.href.includes("/organizer")
    ? supportEventId
    : userEventId;

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/event/eventParticipants/" +
    eventId +
    "?runnerIds=" +
    runnerId;

  return new Promise(function (resolve, reject) {
    http
      .delete(
        url,
        // {
        // headers: { Authorization: "Bearer " + token },
        // data: requestparam,
        // },
        // requestparam,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          if (res?.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Registration Cancelled.", //"Photo added successfully.",
            });
          } else if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function referSave(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/referrer/add/contact";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            message: "Invited Successfully!",
          });
          resolve(res);
        } else if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data?.success?.verbose,
          });
          reject(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response?.data
              ? error?.response?.data
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//public event list
export function getAllEvents(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token ? "Bearer " + token : null,
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/eventList" +
    "?pageCount=" +
    requestParams.pageCount +
    "&pageNumber=" +
    requestParams.pageNumber +
    "&sortKey=event_date&sortOrder=false" +
    "&eventState=" +
    requestParams.eventState;
  //sortKey = event_date";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// list of subscribedservices
export function getSubscribedServices(eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      // Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/nd/subscribedServices";
  if (eventId) {
    url = API_URL_FILE + "api/v1/nd/subscribedServices?eventId=" + eventId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        reject(error);
      });
  });
}

//*************************************************************************//
export function getorderDetails(requestParams) {
  loading(true);
  let eventId = ApplicationUtil.getRunnersEventID();
  let token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  // requestParams.runnerId = runnerId;
  if (requestParams?.openSubscriptionDurationId) {
    requestParams.currency = "INR";
  } else {
    requestParams.eventId = Number(eventId);
    requestParams.currency = "INR";
  }
  let url = API_URL_FILE + "api/v1/payment/add";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          let runnerData = ApplicationUtil.getRunnerDetails();
          let runnerId = runnerData?.runner?.id;
          if (error?.response?.status === 400) {
            deleteEventParticipants(runnerId);
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Payment failed please try again after some time.",
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function getCaptureDetails(requestParams) {
  loading(true);
  let eventId = ApplicationUtil.getRunnersEventID();
  let token = ApplicationUtil.getRunnerToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  // requestParams.runnerId = runnerId;
  if (requestParams?.openSubscriptionDurationId) {
    requestParams.currency = "INR";
  } else {
    requestParams.eventId = Number(eventId);
    requestParams.currency = "INR";
  }

  let url = API_URL_FILE + "api/v1/payment/capture";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        reject(error);
      });
  });
}

//for view results
export function getSearchResults(requestParams) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let eventId = requestParams.eventId;
  let searchString = requestParams.searchString;
  let url =
    API_URL_FILE + `api/v1/search/${eventId}?searchString=${searchString}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        reject(error);
      });
  });
}
/**
 * programChallengeRunnerListForJobSync - get the list of runner registered for the particular challenge of program for job sync
 * @param {*} challengeId
 * @returns
 */
export function programChallengeRunnerListForJobSync({ challengeId }) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let url =
    API_URL_FILE +
    `api/v1/search/program/challenge/participants/${challengeId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        reject(error);
      });
  });
}

export function getViewResults(requestParams) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let eventId = requestParams.eventId;
  let runnerId = requestParams.runnerId;

  let url = API_URL_FILE + `api/v1/public/activity/${eventId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function downloadViewResultsCertificateFile(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let runnerId = requestParams.runnerId;
  let eventId = requestParams.eventId;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    // API_URL_FILE + "api/v1/download/certificate/" + eventId + "/" + runnerId; //eventId;
    API_URL_FILE + "api/v1/download/certificate/" + eventId + "/" + runnerId; //eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          var blob = new Blob([res.data], {
            type: res.data.type, //type: "image/jpeg"
          });

          var url = window.URL.createObjectURL(blob);
          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            var a = document.createElement("a");
            a.href = url;
            if (res.data.type === "application/pdf") a.download = filename;
            else a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getLeaderboardFilter(eventId) {
  //token
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  //param
  let leaderboard = "LEADERBOARD";

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url = API_URL_FILE + `api/v1/filter/${eventId}?view=${leaderboard}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        reject(error);
      });
  });
}
export function getTeamAndGroupNames(eventId, view) {
  let axiosConfig = {
    headers: {
      // Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url =
    API_URL_FILE +
    `api/v1/public/runner/groupAndTeam/searchStrings/${eventId}?view=${view}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        reject(error);
      });
  });
}
export function getEventDetailsForResultView(eventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getTeamLeaderboard(eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = API_URL_FILE + "api/v1/public/team/leaderboard/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error?.response?.data?.error?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Teams not found",
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
export function getRunnerLeaderboard(eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url =
    API_URL_FILE + "api/v1/public/runner/groupDetails/leaderboard/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error?.response?.data?.error?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Teams not found",
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
export function getRunnerList(requestParams) {
  loading(true);
  let runnerGroupId = requestParams.runnerGroupId;
  let eventId = requestParams.eventId;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/public/groupRunner/leaderboard/" +
    eventId +
    "/" +
    runnerGroupId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error?.response?.data?.error?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Teams not found",
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
export function getTeamList(requestParams) {
  loading(true);
  let teamId = requestParams.teamId;
  let eventId = requestParams.eventId;
  let isOrg = window.location.href.includes("/organizer");
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/public/leaderboard/teamDetails/" +
    eventId +
    "/" +
    teamId;
  if (!isOrg) {
    url = url + "?leaderboard=true";
  }

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error?.response?.data?.error?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Teams not found",
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}
export function getIndividualLeaderboard(params, eventId) {
  loading(true);
  let token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/public/leaderboard/${eventId}?activity=${
    params.activity
  }${params.categoryId ? "&categoryId=" + params.categoryId : ""}${
    params.activityPriority
      ? "&activityPriority=" + params.activityPriority
      : ""
  }${
    params.fromDate
      ? "&fromDate=" + params.fromDate + "&toDate=" + params.toDate
      : ""
  }`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getChallengeLeaderboard(programChallengeId) {
  loading(true);
  let token;
  if (window.location.href.includes("/organizer")) {
    token = ApplicationUtil.getSupportToken();
  } else {
    token = ApplicationUtil.getRunnerToken();
  }

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/program/challenge/leaderboard/details/" +
    programChallengeId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        console.log(error);
        loading(false);
        if (error !== "") {
          if (error?.response?.data?.error?.code === "404") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Participants not found",
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

//organizer
export function getOrganizerEventList(requestParams) {
  loading(true);

  let supportData = ApplicationUtil.getSupportDetails();
  let token = supportData?.token;
  let userId = supportData?.id;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/events/organizer/" +
    userId +
    "?" +
    "pageCount=" +
    requestParams.pageCount +
    "&pageNumber=" +
    requestParams.pageNumber +
    "&sortKey=" +
    requestParams.sortKey +
    "&sortOrder=" +
    requestParams.sortOrder +
    "&eventState=" +
    requestParams.eventState +
    "&programType=" +
    requestParams.programType;
  // http://localhost:81/api/v1/events/organizer/576?pageCount=8&pageNumber=0&
  // sortKey = event_date & sortOrder=false & eventState=DRAFT & eventName=new
  if (requestParams.eventName) {
    url =
      API_URL_FILE +
      "api/v1/events/organizer/" +
      userId +
      "?" +
      "pageCount=" +
      requestParams.pageCount +
      "&pageNumber=" +
      requestParams.pageNumber +
      "&sortKey=" +
      requestParams.sortKey +
      "&sortOrder=" +
      requestParams.sortOrder +
      "&eventState=" +
      requestParams.eventState +
      "&eventName=" +
      requestParams.eventName +
      "&programType=" +
      requestParams.programType;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getOrganizerProgramList(requestParams) {
  loading(true);

  let supportData = ApplicationUtil.getSupportDetails();
  let token = supportData?.token;
  let userId = supportData?.id;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/programs/organizer/" +
    userId +
    "?" +
    "pageCount=" +
    requestParams.pageCount +
    "&pageNumber=" +
    requestParams.pageNumber +
    "&sortKey=" +
    requestParams.sortKey +
    "&sortOrder=" +
    requestParams.sortOrder +
    "&eventState=" +
    requestParams.eventState;
  if (requestParams.eventName) {
    url =
      API_URL_FILE +
      "api/v1/programs/organizer/" +
      userId +
      "?" +
      "pageCount=" +
      requestParams.pageCount +
      "&pageNumber=" +
      requestParams.pageNumber +
      "&sortKey=" +
      requestParams.sortKey +
      "&sortOrder=" +
      requestParams.sortOrder +
      "&eventState=" +
      requestParams.eventState +
      "&eventName=" +
      requestParams.eventName;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getOrganizerExecutionList(requestParams) {
  loading(true);
  let supportData = ApplicationUtil.getSupportDetails();
  let token = supportData?.token;
  let userId = supportData?.id;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let eventId = ApplicationUtil.getSupportsEventID();
  let url;
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/event/excecution/eventParticipants?eventId=${eventId}${
      requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""
    }${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${
      requestParams.isValid !== null ? "&isValid=" + requestParams.isValid : ""
    }${
      requestParams.eventActivityTypeId
        ? "&eventActivityTypeId=" + requestParams.eventActivityTypeId
        : ""
    }${
      requestParams.certificateGenerated === true
        ? "&certificateGenerated=" + requestParams.certificateGenerated
        : requestParams.certificateGenerated === false
        ? "&certificateGenerated=" + requestParams.certificateGenerated
        : ""
    }${
      requestParams.activityCollageGenerated === true
        ? "&activityCollageGenerated=" + requestParams.activityCollageGenerated
        : requestParams.activityCollageGenerated === false
        ? "&activityCollageGenerated=" + requestParams.activityCollageGenerated
        : ""
    }${
      requestParams.videoGenerated === true
        ? "&videoGenerated=" + requestParams.videoGenerated
        : requestParams.videoGenerated === false
        ? "&videoGenerated=" + requestParams.videoGenerated
        : ""
    }${
      requestParams.isAttended === true
        ? "&isAttended=" + requestParams.isAttended
        : requestParams.isAttended === false
        ? "&isAttended=" + requestParams.isAttended
        : ""
    }${
      requestParams.bibGenerated === true
        ? "&bibGenerated=" + requestParams.bibGenerated
        : requestParams.bibGenerated === false
        ? "&bibGenerated=" + requestParams.bibGenerated
        : ""
    }${
      requestParams.activityReceived === true
        ? "&activityReceived=" + requestParams.activityReceived
        : requestParams.activityReceived === false
        ? "&activityReceived=" + requestParams.activityReceived
        : requestParams.activityReceived === null
        ? ""
        : "&activityReceived=" + requestParams.activityReceived
    }${requestParams.bib ? "&bib=" + requestParams.bib : ""}${
      requestParams.name ? "&runnerName=" + requestParams.name : ""
    }${requestParams.email ? "&email=" + requestParams.email : ""}${
      requestParams.contact ? "&contactNumber=" + requestParams.contact : ""
    }`;
  } else {
    url =
      API_URL_FILE +
      "api/v1/event/excecution/eventParticipants?" +
      "eventId=" +
      eventId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
// results csv api
export function downloadResultCSV(requestParams) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let eventId = ApplicationUtil.getSupportsEventID();

  let url;
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/download/event/participant/result?eventId=${eventId}${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.isValid !== null ? "&isValid=" + requestParams.isValid : ""
    }${requestParams.runnerIds ? "&runnerIds=" + requestParams.runnerIds : ""}${
      requestParams.certificateGenerated
        ? "&certificateGenerated=" + requestParams.certificateGenerated
        : ""
    }${
      requestParams.activityCollageGenerated
        ? "&activityCollageGenerated=" + requestParams.activityCollageGenerated
        : ""
    }${
      requestParams.activityReceived === true
        ? "&activityReceived=" + requestParams.activityReceived
        : requestParams.activityReceived === false
        ? "&activityReceived=" + requestParams.activityReceived
        : ""
    }${
      requestParams.videoGenerated
        ? "&videoGenerated=" + requestParams.videoGenerated
        : ""
    }${requestParams.bib ? "&bib=" + requestParams.bib : ""}${
      requestParams.name ? "&runnerName=" + requestParams.name : ""
    }${requestParams.email ? "&email=" + requestParams.email : ""} `;
  } else {
    url =
      API_URL_FILE +
      "api/v1/download/event/participant/result?" +
      "eventId=" +
      eventId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          const blob = new Blob([res?.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("hidden", "");
          a.setAttribute("href", url);
          a.setAttribute("download", "download.csv");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getOrganizerRegistrationList(requestParams) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let eventId = ApplicationUtil.getSupportsEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url;
  if (requestParams) {
    url = `${API_URL_FILE}api/v1/eventParticipants?eventId=${eventId}${
      requestParams.pageCount ? "&pageCount=" + requestParams.pageCount : ""
    }${
      requestParams.pageNumber >= 0
        ? "&pageNumber=" + requestParams.pageNumber
        : ""
    }${
      requestParams.startDate
        ? "&startDate=" +
          requestParams.startDate +
          "&endDate=" +
          requestParams.endDate
        : ""
    }${
      requestParams.activityTypeId
        ? "&activityTypeId=" + requestParams.activityTypeId
        : ""
    }${
      requestParams.categoryId
        ? "&eventCategoryId=" + requestParams.categoryId
        : ""
    }${
      requestParams.eventActivityTypeId
        ? "&eventActivityTypeId=" + requestParams.eventActivityTypeId
        : ""
    }${requestParams.bib ? "&bib=" + requestParams.bib : ""}${
      requestParams.name ? "&runnerName=" + requestParams.name : ""
    }${requestParams.email ? "&email=" + requestParams.email : ""}${
      requestParams.contact ? "&contactNumber=" + requestParams.contact : ""
    }${
      requestParams.consentPresent === true
        ? "&consentPresent=" + requestParams.consentPresent
        : requestParams.consentPresent === false
        ? "&consentPresent=" + requestParams.consentPresent
        : ""
    }${
      requestParams.bibGenerated === true
        ? "&bibGenerated=" + requestParams.bibGenerated
        : requestParams.bibGenerated === false
        ? "&bibGenerated=" + requestParams.bibGenerated
        : ""
    }${
      requestParams.onlineRegistration === true
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : requestParams.onlineRegistration === false
        ? "&onlineRegistration=" + requestParams.onlineRegistration
        : ""
    }${
      requestParams.includeVolunteer === true
        ? "&includeVolunteer=" + requestParams.includeVolunteer
        : requestParams.includeVolunteer === false
        ? "&includeVolunteer=" + requestParams.includeVolunteer
        : ""
    } `;
  } else {
    url = API_URL_FILE + "api/v1/eventParticipants?" + "eventId=" + eventId;
  }
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function displayCertificate(id, cEventId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let runnerId = id;
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    // responseType: "blob",
  };
  let url =
    // API_URL_FILE + "api/v1/download/certificate/" + eventId + "/" + runnerId; //eventId;
    API_URL_FILE +
    "api/v1/download/certificateImage/" +
    eventId +
    "/" +
    runnerId; //eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function displayRunnerCollageFile(id) {
  loading(true);

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let runnerId = id;
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/runnerCollage/" + eventId + "/" + runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function displayBIBFile(id) {
  loading(true);

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let runnerId = id;
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url =
    API_URL_FILE + "api/v1/download/bibImage/" + eventId + "/" + runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//organizer update form
export function OrganizerUpdateForm(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/update/register";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function clearConsent(requestParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/user/removeConsent/" +
    requestParams.userId +
    "/" +
    requestParams.serviceId;

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

//get Category
export function getCategoryList(id, customLoading = true) {
  customLoading && loading(customLoading);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/getCategory/" + id;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          customLoading && loading(false);
          // Snackbar.show({
          //   variant: "success",
          //   message: res.data.success.verbose,
          // });
          resolve(res);
        } else {
          customLoading && loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        customLoading && loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getRunnerActivityList(eventId) {
  loading(true);

  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/event/activity/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          // Snackbar.show({
          //   variant: "success",
          //   message: res.data.success.verbose,
          // });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//create event
export function createEvent(requestParams, ChartFile) {
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/event/create";

  var formData = new FormData();

  formData.set("eventRequest", JSON.stringify(requestParams));
  // ChartFile.forEach((element) => {
  formData.append("eventItemImage", ChartFile);
  // });

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          // if (res.status.code ===200) {
          loading(false);
          // Snackbar.show({
          //   variant: "success",
          //   isAutoHide: true,
          //   preventDuplicate: true,
          //   message: "Event created succefully",
          // });
          resolve(res);
          // } else {
          //   loading(false);
          // Snackbar.show({
          //   variant: "error",
          //   isAutoHide: true,
          //   preventDuplicate: true,
          //   message: Properties.Something_went_wrong,
          // });
          // reject(res);
          // }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function updateEventDetails(requestParams, ChartFile) {
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/update/" + eventId;

  var formData = new FormData();

  formData.set("eventRequest", JSON.stringify(requestParams));
  // ChartFile.forEach((element) => {
  formData.append("eventItemImage", ChartFile);
  // });

  return new Promise(function (resolve, reject) {
    http
      .put(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          if (res.data.code === "200") {
            // Snackbar.show({
            //   variant: "success",
            //   isAutoHide: true,
            //   preventDuplicate: true,
            //   message: "Event created succefully",
            // });
            resolve(res);
          } else if (res.data.code === "600") {
            Snackbar.show({
              variant: "error",
              message: res?.data?.verbose,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function publishEvent(id) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/publish/" + id;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          // Snackbar.show({
          //   variant: "success",
          //   message: res.data.success.verbose,
          // });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function deleteEvent(eventId) {
  let token = ApplicationUtil.getSupportToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/remove/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .delete(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          // Snackbar.show({
          //   variant: "success",
          //   message: res.data.success.verbose,
          // });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get organiser list
export function getEventOrganiserList({ id, customLoading = true }) {
  customLoading && loading(customLoading);

  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/getOrganizerList";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          customLoading && loading(false);
          // Snackbar.show({
          //   variant: "success",
          //   message: res.data.success.verbose,
          // });
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        customLoading && loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getActivityTypeList(id) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/nd/activity";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//get event settings
export function getEventSettingsDetails() {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/event/getEventSettings/` + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//update event settings
export function updateEventSettingsDetails(requestParams) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/settings/update/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .put(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//get InlineImages
export function getEventImagesDetails() {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/getInLineImages/` + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//post InlineImage
export function uploadInlineImages(imageKey, imageURL, hideMsg) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getSupportsEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/event/inlineImages/" + eventId;
  var formData = new FormData();
  formData.set("imageKey", imageKey);
  formData.append("files", imageURL);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        if (res.status === 200) {
          if (res?.data?.success?.code === "200") {
            if (!hideMsg) {
              Snackbar.show({
                variant: "success",
                isAutoHide: true,
                preventDuplicate: true,
                message: res.data.success.verbose, //"Photo added successfully.",
              });
            }
            resolve(res);
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

//get Templates
export function getDeliverableTemplate(eventType, activityType, templateType) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    `${API_URL_FILE}api/v1/getDeliverableAttachmentTemplate/` +
    eventId +
    "/" +
    eventType +
    "/" +
    activityType +
    "/" +
    templateType;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        if (error !== "") {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getTemplatePreview(requestParams) {
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/eventTemplates/preview/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.status === 200) {
            loading(false);

            resolve(res);
          } else {
            loading(false);
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function saveDeliverableTemplate(requestParams) {
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/saveDeliverableTemplate/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.status === 200) {
            loading(false);
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose,
            });
            resolve(res);
          } else {
            loading(false);
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//organizer ---> setup ---> custom field

export function customFieldSave(requestParams) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/field/save/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.status === 200) {
            loading(false);
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose,
            });
            resolve(res);
          } else {
            loading(false);
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(res);
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message:
              // error.response
              // ? error.response.data
              //   ? error.response.data.error.verbose
              //   : Properties.Something_went_wrong
              //   :
              Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function customFieldUpdate(requestParams) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/field/update/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        if (error !== "") {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message:
              // error.response
              // ? error.response.data
              //   ? error.response.data.error.verbose
              //   : Properties.Something_went_wrong
              //   :
              Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//which were uploaded prev
export function getUploadedDeliverableTemplate(requestParams) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/getSavedDeliverableTemplates/" + eventId;
  // v1/getSavedDeliverableTemplates/
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });

          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,

            message: error?.response?.data
              ? error?.response?.data?.error
                ? error?.response?.data?.error?.verbose
                : error?.response?.data
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function deleteInlineImage(key) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/event/" + eventId + "/" + key;

  return new Promise(function (resolve, reject) {
    http
      .delete(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Deleted Successfully",
          });
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//generate certificates, bib's, etc, by selecting checkboxes on list
export function generateDeliverables(deliverableType, requestParams) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/event/generate/" +
    deliverableType +
    "/" +
    eventId +
    "?regenerate=" +
    requestParams.regenerate;
  let arr = requestParams.runnerIds;
  arr = arr.toString();
  var formData = new FormData();
  formData.append("runnerIds", arr);
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          if (res.data.success.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          } else if (res.data.success.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          } else if (res.data.success.code === "500") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.success.verbose,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function uploadEventCSVFile(file, eventId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/participant/import/preRegister";
  var formData = new FormData();
  formData.set("eventId", eventId);
  formData.set("file", file);
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Imported successfully.",
          });
          resolve(res);
        } else if (res.status === 500) {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res.data.success.verbose,
          });
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function downloadCSVFormat() {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/download/event/participantsTemplate";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        let headers = res.headers;
        var contentDisposition = headers["content-disposition"];
        var filename =
          ApplicationUtil.getFileNameFromHeaders(contentDisposition);

        loading(false);
        const blob = new Blob([res?.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve(res);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

// import results CSV
export function uploadResultsCSVFile(file, eventId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/participant/import/allResults";
  var formData = new FormData();
  formData.set("eventId", eventId);
  formData.set("file", file);
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Imported successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// import activity CSV
export function uploadActivityCSVFile(file, eventId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/activity/import/mat";
  var formData = new FormData();
  formData.set("eventId", eventId);
  formData.set("file", file);
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Imported successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

// import results template download
export function downloadResultFormat(eventId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/download/event/result/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        let headers = res.headers;
        var contentDisposition = headers["content-disposition"];
        var filename =
          ApplicationUtil.getFileNameFromHeaders(contentDisposition);

        if (filename === undefined) {
          filename = "download";
        }
        loading(false);
        const blob = new Blob([res?.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve(res);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

//Syncing Job
export function jobSyncActivity(params) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/job/schedule";
  return new Promise(function (resolve, reject) {
    http
      .post(url, params, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Job is synced successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//Get job Types
export function getJobTypes() {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = `${API_URL_FILE}api/v1/job/types`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * getJobSyncedHistoryOf ProgramChallenge or event
 * @param {*} param0
 * @returns
 */
export function getJobSyncedHistoryOfProgramChallengeOrEvent(params) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = `${API_URL_FILE}api/v1/job/history${
    params?.programChallengeId
      ? `?programChallengeId=${params?.programChallengeId}`
      : `?eventId=${params?.eventId}`
  }`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
//history of synced job
export function getJobSyncedActivity(eventId) {
  loading(true);

  let token = ApplicationUtil.getSupportToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = `${API_URL_FILE}api/v1/job/history/` + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//delete history of synced job
export function deleteJobSyncedJob(jobIds) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let url = API_URL_FILE + "api/v1/job/delete/jobHistory/Job";
  let arr = jobIds;
  arr = arr.toString();
  var formData = new FormData();
  formData.append("jobHistoryIds", arr);

  return new Promise(function (resolve, reject) {
    http
      .delete(url, {
        headers: { Authorization: "Bearer " + token },
        data: formData,
      })
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Deleted Succesfully",
          });
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//upload mat to bib mapping file
export function uploadMatToBibMappingFile(file, eventId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/activity/import/matBib";
  var formData = new FormData();
  formData.set("eventId", eventId);
  formData.set("file", file);
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Imported successfully.",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

// download bibmapping template
export function downloadBIBMappingTemplate(eventId) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + "api/v1/download/event/bibTemplate/" + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        let headers = res.headers;
        var contentDisposition = headers["content-disposition"];
        var filename =
          ApplicationUtil.getFileNameFromHeaders(contentDisposition);

        if (filename === undefined) {
          filename = "download";
        }
        loading(false);
        const blob = new Blob([res?.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve(res);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getMapData(eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  //QA
  let url = `${API_URL_FILE}api/v1/relay/mapData/` + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        console.log("maperror", error?.response);
        if (error !== "") {
          if (error?.response?.status === 600) {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: error?.response?.data,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

// not used
export function getSlotData(requestparam, eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let url = `${API_URL_FILE}api/v1/relay/slotData/` + 635 + "/" + 6;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getAllSlotData(eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };
  let url = `${API_URL_FILE}api/v1/relay/slotData/` + eventId;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getMapRoute(params) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  //QA
  let url;
  // `${API_URL_FILE}api/v1/runner/routePosition?eventId=${params.eventId}&runnerId=1273`;
  if (params.bibNumber) {
    url = `${API_URL_FILE}api/v1/runner/routePosition?eventId=${params.eventId}&bibNumber=${params.bibNumber}`;
  } else if (params.categoryId) {
    url = `${API_URL_FILE}api/v1/runner/routePosition?eventId=${params.eventId}&eventCategoryId=${params.categoryId}`;
  } else {
    url = `${API_URL_FILE}api/v1/runner/routePosition?eventId=${params.eventId}`;
  }
  // 813 & runnerId=1273";
  // else if (params.runnerId) {
  //   url = `${API_URL_FILE}api/v1/runner/routePosition?eventId=${params.eventId}&runnerId=${params.runnerId}`;
  // }
  return new Promise(function (resolve, reject) {
    http
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response.status === 404 || error.response.status === 600) {
            loading(false);
            resolve(null);
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
            reject(error);
          }
        }
      });
  });
}
// decode url api
export function getDecodedUrl(encodedKey) {
  loading(true);
  let token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let encodedKeyFinal = encodedKey;
  if (encodedKey && encodedKey.startsWith("/")) {
    encodedKeyFinal = encodedKeyFinal.substring(1);
  }
  let url = `${API_URL_FILE}api/v1/redirectedUrl/` + encodedKeyFinal;
  // let url = `${API_URL_FILE}api/v1/redirectedUrl/GetFit#Register`

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response?.data
              ? error?.response?.data
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// runner summary api
export function runnerSummary(runnerId, eventId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = `${API_URL_FILE}api/v1/event/participant/statitics?eventId=${eventId}&runnerId=${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getSummaryImpressions(eventId, runnerId) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
    },
  };

  let url = `${API_URL_FILE}api/v1/summaryShareDetails/${eventId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .post(url, {}, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function logoutUser() {
  loading(true);
  let runnerData = ApplicationUtil.getRunnerDetails();
  let token = runnerData?.token;
  let userId = runnerData?.id;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/logout/${userId}`;
  let data = {
    userId: Number(userId),
  };
  return new Promise(function (resolve, reject) {
    http
      .post(url, data, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getTrainingPlan(token, eventId, runnerId) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",

      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/training/plan/${eventId}/${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getParticipantsActivityList(params) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",

      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/participant/activities?eventId=${eventId}&pageNumber=${
    params.pageNumber
  }&pageCount=${params.pageCount}
  ${
    params.startDate
      ? "&startDate=" + params.startDate + "&endDate=" + params.endDate
      : ""
  }${params?.approved !== null ? `&approved=${params.approved}` : ""}${
    params.isValid !== null ? "&valid=" + params.isValid : ""
  }${params?.name ? `&runnerName=${params.name}` : ""}${
    params.bib ? `&bib=${params.bib}` : ""
  }${params.email ? `&email=${params.email}` : ""}${
    params.contact ? `&contactNumber=${params.contact}` : ""
  }${
    params.activityTypeDropdown
      ? `&activityTypes=${params.activityTypeDropdown}`
      : ""
  }${
    params.approvedStatus === true
      ? "&approved=" + params.approvedStatus
      : params.approvedStatus === false
      ? "&approved=" + params.approvedStatus
      : ""
  }${
    params.validStatus === true
      ? "&valid=" + params.validStatus
      : params.validStatus === false
      ? "&valid=" + params.validStatus
      : ""
  }
  
  `;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function getSummaryImage(runnerId, eventId) {
  loading(true);
  let axiosConfig = {
    headers: {
      // Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url = `${API_URL_FILE}api/v1/summary/share/image/${eventId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}

export function setSummaryBgImage(runnerId, eventId, requestParams) {
  loading(true);
  let axiosConfig = {
    headers: {
      // Authorization: "Bearer " + token,
      "Content-Type": requestParams
        ? "multipart/form-data"
        : "application/json",
      // "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  var formData = new FormData();
  if (requestParams) {
    formData.append("customImageFile", requestParams.file);
  }
  let url = `${API_URL_FILE}api/v3/summary/share/image/${eventId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams ? formData : {}, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}

export function getGoodiesImage(runnerId, eventId, type, requestParams) {
  loading(true);
  let axiosConfig = {
    headers: {
      // Authorization: "Bearer " + token,
      "Content-Type": requestParams
        ? "multipart/form-data"
        : "application/json",
      // "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  var formData = new FormData();
  if (requestParams) {
    if (requestParams?.defaultBackground) {
      formData.append("defaultBackground", requestParams.defaultBackground);
    }
    if (requestParams?.file) {
      formData.append("customImageFile", requestParams.file);
    }
  }
  let url = `${API_URL_FILE}api/v1/share/image/${eventId}/${runnerId}/${type}`;
  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams ? formData : {}, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}

export function postBarScannerData(token, requestParams) {
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/participant/track";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        loading(false);
        if (res?.data === "SUCCESS") {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: `${requestParams.name || requestParams.bibNumber} Added`,
          });
        }
        resolve(res);
      })
      .catch((error) => {
        loading(false);
        // Snackbar.show({
        //   variant: "error",
        //   isAutoHide: true,
        //   preventDuplicate: true,
        //   message: JSON.stringify(error.response.status),
        // });
        reject(error);
      });
  });
}
export function getDetailsScanned(eventId, runnerId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };

  let url = `${API_URL_FILE}api/v1/participant/details/${eventId}/${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}
export function getRewardPoints(eventId, runnerId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url = `${API_URL_FILE}api/v1/rewardPoint/summary/${eventId}/${runnerId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}
export function getScanPointsList(eventId) {
  // loading(true);
  let token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    //responseType: "blob",
  };
  let url = `${API_URL_FILE}api/v1/matpoint/list/${eventId}`;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        // loading(false);
        resolve(res);
      })
      .catch((error) => {
        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}

export function postSelectedScanPoint(eventId, runnerId, id) {
  loading(true);
  let token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = `${API_URL_FILE}api/v1/volunteer/location/update/${eventId}/${runnerId}/${id}`;
  return new Promise(function (resolve, reject) {
    http
      .post(url, {}, axiosConfig)
      .then((res) => {
        loading(false);
        resolve(res);
      })
      .catch((error) => {
        loading(false);

        Snackbar.show({
          variant: "error",
          isAutoHide: true,
          preventDuplicate: true,
          message: Properties.Something_went_wrong,
        });
        reject(error);
      });
  });
}

export function getProgramParticipantsList(params) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let programId = params?.programId;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/participants/program?programId=${programId}${
    params.pageCount ? "&pageCount=" + params.pageCount : ""
  }${params.pageNumber >= 0 ? "&pageNumber=" + params.pageNumber : ""}${
    params.startDate
      ? "&startDate=" + params.startDate + "&endDate=" + params.endDate
      : ""
  }${params.bib ? "&bib=" + params.bib : ""}${
    params.name ? "&runnerName=" + params.name : ""
  }${params.email ? "&email=" + params.email : ""}${
    params.contact ? "&contactNumber=" + params.contact : ""
  }${
    params.consentPresent === true
      ? "&consentPresent=" + params.consentPresent
      : params.consentPresent === false
      ? "&consentPresent=" + params.consentPresent
      : ""
  }${
    params.bibGenerated === true
      ? "&bibGenerated=" + params.bibGenerated
      : params.bibGenerated === false
      ? "&bibGenerated=" + params.bibGenerated
      : ""
  }${
    params.onlineRegistration === true
      ? "&onlineRegistration=" + params.onlineRegistration
      : params.onlineRegistration === false
      ? "&onlineRegistration=" + params.onlineRegistration
      : ""
  }${params.eventId ? "&eventId=" + params.eventId : ""}${
    params.programSessionId
      ? "&programSessionId=" + params.programSessionId
      : ""
  }${
    params?.programChallengeId
      ? "&programChallengeId=" + params?.programChallengeId
      : ""
  }${
    params?.activeParticipantRequired === true
      ? "&activeParticipantRequired=" + params?.activeParticipantRequired
      : ""
  }`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getProgramParticipantDashboardData(runnerId, programId) {
  loading(true);

  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let supportOrganizer_token = ApplicationUtil.getSupportToken();

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/program/participant/dashboard/" +
    programId +
    "/" +
    runnerId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200 && res.data) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_OperationFailed,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function getProgramCompanyDashboardData(programId) {
  loading(true);

  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let supportOrganizer_token = ApplicationUtil.getSupportToken();

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/company/dashboard/" + programId;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200 && res.data) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          if (error.response && error.response.status === 500) {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Label_OperationFailed,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: Properties.Something_went_wrong,
            });
          }
        }
        reject(error);
      });
  });
}

export function modifyProgramParticipant(requestParams) {
  let token = ApplicationUtil.getSupportToken();
  let eventId = ApplicationUtil.getSupportsEventID();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/update/program/participants";

  return new Promise(function (resolve, reject) {
    http
      .put(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function deleteProgramParticipants(runnerId) {
  let programDetails = ApplicationUtil.getSupportsProgramDetails();
  let programId = programDetails?.id;

  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/delete/programParticipants/" +
    programId +
    "?runnerIds=" +
    runnerId;

  return new Promise(function (resolve, reject) {
    http
      .delete(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          if (res?.data?.success?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Deleted Participant.", //"Photo added successfully.",
            });
          } else if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * get program participant details based on program id and runner id
 * @param {*} params
 * @returns
 */
export function getProgramParticipantInfo(programId, runnerId) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = `${API_URL_FILE}api/v1/participants/program?programId=${programId}&runnerIds=${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);

        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
/**
 * get data (imgPath, filename,etc) for showing the program participant certificate
 * @param {*} programId
 * @param {*} runnerId
 * @returns
 */
export function getImgPathForProgramParticipantCertificate(
  programId,
  runnerId
) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    // responseType: "blob",
  };
  let url =
    API_URL_FILE + `api/v1/program/certificate/${programId}/${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res.data);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * for downloading the program participant certificate based on program id and runner id
 * @param {*} programId
 * @param {*} runnerId
 * @returns
 */
export function downloadProgramCertificateFile({ programId, runnerId }) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    responseType: "blob",
  };
  let url =
    API_URL_FILE +
    `api/v1/download/program/certificate/${programId}/${runnerId}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          var blob = new Blob([res.data], {
            type: res.data.type, //type: "image/jpeg"
          });

          var url = window.URL.createObjectURL(blob);

          if (
            window?.clientInformation?.userAgent.includes("iPhone OS") ||
            window?.clientInformation?.userAgent.includes("iPad")
          ) {
            var pop = window.open(url);
            return pop;
          } else {
            let headers = res.headers;
            var contentDisposition = headers["content-disposition"];
            var filename =
              ApplicationUtil.getFileNameFromHeaders(contentDisposition);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            // var a = document.createElement("a");
            // a.href = url;
            // if (res.data.type == "application/pdf")
            //   a.download = "Certificate" + ".pdf";
            // else a.download = "Certificate" + ".png";
            // //a.download = "Certificate" + ".pdf";
            // document.body.appendChild(a);
            // a.click();
            // a.remove();
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })

      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * Resend program certificate email based on program id, runner id and type
 * @param {*} param
 * @returns
 */
export function resendProgramCertificateEmail({
  programId,
  runnerId,
  emailTypeId,
}) {
  let token = ApplicationUtil.getSupportToken();
  var formData = new FormData();
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    `api/v1/nd/program/emailJobService/${programId}/${runnerId}?emailTypeId=${emailTypeId}`;
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          if (res.data.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data.verbose,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res.data?.verbose
                ? res?.data?.verbose
                : Properties.Something_went_wrong,
            });
          }
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * for generating the certificates of program participant
 * @param {*} _body
 * @returns
 */
export function generateProgrmParticipantCertificates(_body) {
  loading(true);
  let programDetails = ApplicationUtil.getSupportsProgramDetails();
  let programId = programDetails?.id;
  let token = ApplicationUtil.getSupportToken();

  let arr = _body?.runnerIds;
  arr = arr?.toString();
  var formData = new FormData();
  formData.append("runnerIds", arr);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  };

  let url = API_URL_FILE + `api/v1/generate/program/certificate/${programId}`;
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);

        if (res.status === 200) {
          Snackbar.show({
            variant: "success",
            isAutoHide: true,
            preventDuplicate: true,
            message: "Certificate Generated successfully !!",
          });
          resolve(res);
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * For Modify / update Program challenge Participant user details
 * @param {*} requestParams
 * @returns
 */
export function modifyProgramChallengeParticipant(requestParams) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/program/challenge/participant/update";

  return new Promise(function (resolve, reject) {
    http
      .put(url, requestParams, axiosConfig)
      .then((res) => {
        if (res?.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * for delete participant from program challenge
 * @param {*} programChallengeId
 * @param {*} runnerId
 * @returns
 */
export function deleteProgramChallengeParticipants(
  programChallengeId,
  runnerId
) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url =
    API_URL_FILE +
    "api/v1/delete/program/challenge/participants/" +
    programChallengeId +
    "?runnerIds=" +
    runnerId;

  return new Promise(function (resolve, reject) {
    http
      .delete(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
          if (res?.data?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Deleted Participant.", //"Photo added successfully.",
            });
          } else if (res?.data?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.verbose
                ? res?.data?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.verbose
                ? res?.data?.verbose
                : Properties.Something_went_wrong,
            });
          }
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// --------------------------------------------------
//             CHALLENGE UNDER THE PROGRAM
// --------------------------------------------------

export function createProgramChallenge(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/create";

  return new Promise(function (resolve, reject) {
    http
      .post(url, data, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function publicProgramChallenge(id) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/challenge/publish/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getProgramChallenge(id) {
  let runnerData = ApplicationUtil.getRunnerDetails();
  let runner_token = runnerData?.token;
  let supportOrganizer_token = ApplicationUtil.getSupportToken();

  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/challenge/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function submitViewDateTipsTable(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/question/mapping";

  return new Promise(function (resolve, reject) {
    http
      .post(url, data, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function clearParentAndChildQsAndTips(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let url = API_URL_FILE + "api/v1/delete/parent/child/questionnaire";

  return new Promise(function (resolve, reject) {
    http
      .delete(url, {
        headers: { Authorization: "Bearer " + token },
        data: data,
      })
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function updateProgramChallenge({ id, data }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + `api/v1/program/challenge/update/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .put(url, data, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getProgramChallengeType() {
  let token = ApplicationUtil.getSupportToken();

  // loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/types";
  // get categories for challenge creation
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getQuestionCategoryType() {
  let token = ApplicationUtil.getSupportToken();

  // loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/question/type";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getAllQuestions(params) {
  let token = ApplicationUtil.getSupportToken();

  // loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: params,
  };
  let url = API_URL_FILE + "api/v1/program/challenge/Questionnaire";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function createQuestionInChallenge(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/create/program/challenge/Questionnaire";

  return new Promise(function (resolve, reject) {
    http
      .post(url, data, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function createTipsUnderChallenge(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/create/program/challenge/tip";

  const formData = new FormData();

  formData.append("tip", data?.tip);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 * for updating the tip
 */
export function editTipsUnderChallenge({ id, tip }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/update/program/challenge/tip/${id}`;

  const formData = new FormData();

  formData.append("tip", tip);

  return new Promise(function (resolve, reject) {
    http
      .put(url, formData, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getAllTipsUnderChallenge() {
  let token = ApplicationUtil.getSupportToken();

  // loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/tip";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getChallengeQuestionAccordingViewDate(id) {
  let token = ApplicationUtil.getSupportToken();

  // loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/challenge/question/details/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// Link option to Question
export function linkOptionToQuestionCall(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/parent/link/child/questionnaire";

  return new Promise(function (resolve, reject) {
    http
      .post(url, data, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// Get all Linked Options
export function getLinkedQuestionToOptions(id) {
  let token = ApplicationUtil.getSupportToken();

  // loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/parent/child/questionnaire/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// fetch product detail
export function fetchProductDetail() {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/challenge/product/details`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// ----------------- Product under the challenge  ----------------------

// create/api/v1/create/product/details

export function createProduct({ data, productImage }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/create/product/details";

  let formData = new FormData();

  formData.append("productDetailRequest ", JSON.stringify(data));
  formData.append("productImage ", productImage);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
// update api/v1/update/product/details

export function updateProduct({ data, productImage, id }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",

      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/update/product/details";
  let formData = new FormData();
  formData.append("productDetailRequest ", JSON.stringify(data));
  formData.append("productImage ", productImage);
  return new Promise(function (resolve, reject) {
    http
      .put(url, formData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// delete /v1/delete/program/challenge/product/{productId}
export function deleteProduct(productId) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url =
    API_URL_FILE + `api/v1/delete/program/challenge/product/${productId}`;
  return new Promise(function (resolve, reject) {
    http
      .delete(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

// get product detail

export function getAllProducts() {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",

      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/product/details";

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/** program challenge setting */

export function getAllAdditionsSettingsFlags(id) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",

      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/challenge/setting/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function updateProgramChallengeFlags({ data, id }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/update/program/challenge/settings/${id}`;

  let paramsData = { programSettings: data };

  return new Promise(function (resolve, reject) {
    http
      .put(url, paramsData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

/**
 *
 * Create Program Api
 *
 */

export function createProgram(data) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/create";

  return new Promise(function (resolve, reject) {
    http
      .post(url, data, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function updateProgram({ id, data }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + `api/v1/program/update/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .put(url, data, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function getProgramDetail(id) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",

      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function publishDraftedProgram(id) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/publish/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .post(url, {}, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
  // /v1/program/publish/{programId}
}

export function getAllProgramSettingsFlags(id) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",

      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + `api/v1/program/setting/${id}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function updateProgramFlags({ data, id }) {
  let token = ApplicationUtil.getSupportToken();

  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + `api/v1/program/settings/update/${id}`;

  let paramsData = { programSettings: data };

  return new Promise(function (resolve, reject) {
    http
      .put(url, paramsData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//Save user upload photo for frame
export function saveUserPhotoOfFrame(requestParams) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      // Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/save/user/frame";
  var formData = new FormData();

  formData.append("file", requestParams?.file);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
      })
      .catch((error) => {
        loading(false);
        reject(error);
      });
  });
}

// Get Subscription for open program

export function getIsOpenSubscriptionProgramActivities(params) {
  loading(true);
  let token = ApplicationUtil.getSupportToken();
  let programId = ApplicationUtil.getSupportsProgramDetails();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",

      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/open/subscription/challenge/athelet/list?programId=${
    programId?.id
  }&pageNumber=${params.pageNumber}&pageCount=${params.pageCount}${
    params?.name ? `&runnerName=${params.name}` : ""
  }
  ${
    params.startDate
      ? "&startDate=" + params.startDate + "&endDate=" + params.endDate
      : ""
  }${params?.approved !== null ? `&approved=${params.approved}` : ""}${
    params.isValid !== null ? "&valid=" + params.isValid : ""
  }${params.bib ? `&bib=${params.bib}` : ""}${
    params.email ? `&email=${params.email}` : ""
  }${params.contact ? `&contactNumber=${params.contact}` : ""}${
    params.activityTypeDropdown
      ? `&activityTypes=${params.activityTypeDropdown}`
      : ""
  }${
    params.approvedStatus === true
      ? "&approved=" + params.approvedStatus
      : params.approvedStatus === false
      ? "&approved=" + params.approvedStatus
      : ""
  }${
    params.validStatus === true
      ? "&valid=" + params.validStatus
      : params.validStatus === false
      ? "&valid=" + params.validStatus
      : ""
  }
  ${
    params?.programChallengeType &&
    "&programChallengeType=" + params?.programChallengeType
  }
  
  `;
  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
